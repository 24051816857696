import { SET_PERMISSIONS, RESET_PERMISSIONS } from './actionTypes';

const initialState = {
  permissions: [],
};

const permissionsReducer = (state = initialState, action) => {

  switch (action.type) {
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };
    case RESET_PERMISSIONS:
      return initialState;
    default:
      return state;
  }
};

export default permissionsReducer;