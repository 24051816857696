import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Table, Pagination, Modal } from 'antd';
import { apiUrls } from '../apiUrls.js';
import { toast } from 'react-toastify';
import AmountFormat from "../components/AmountFormat.js";
import InputNumberFormat from "../components/InputNumberFormat.js";

const { Option } = Select;
const baseURL = apiUrls.baseURL;

const EndOfProgram = React.memo(({ data, onRefresh, isShowOtherProgramCosts, isShowAssistantLeaderAmount }) => {

    const [isShowModalVisible, setIsShowModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [model, setModel] = useState({});
    const [informationEndOfProgram, setInformationEndOfProgram] = useState({});

    useEffect(() => {
        setModel({ ...model, programId: data.id })
    }, [])

    const submit = async (id) => {
        setLoading(true);
        try {
            const token = localStorage.getItem('damavandclub-token')
            await axios.post(`${apiUrls.baseURL}/program/endofprogram`, model, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            onRefresh();

            toast.success("با موفقیت ثبت شد", {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setIsShowModalVisible(false)
            setModel({ ...model, customeLeaderAmount: null })
            setLoading(false);
        } catch (error) {
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setLoading(false);
            setIsShowModalVisible(false)
        } finally {
            setLoading(false);
            setIsShowModalVisible(false)
        }
    };

    const check = async () => {
        setLoading(true)
        try {
            const token = localStorage.getItem('damavandclub-token')
            var response = await axios.get(`${apiUrls.baseURL}/program/endofprogram?id=${data.id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setIsShowModalVisible(true);
            setLoading(false)
            var result = response.data.data;
            setInformationEndOfProgram(result);
        } catch (error) {
            setLoading(false)
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } finally {
        }
    }

    return (
        <>
            <Button loading={loading} size='small' onClick={() => { check(); setModel({ ...model, userId: null, amount: null }) }}
                style={{ backgroundColor: 'white', color: 'black', marginTop: 5, width: '100%' }}
                type="primary"><label>ثبت پایان برنامه</label></Button>

            <Modal
                title={`ثبت پایان برنامه:  ${data.nameFa}`}
                open={isShowModalVisible}
                closable={false}
                okButtonProps={null}
                footer={<div className='row-container'>
                    <Button onClick={() => setIsShowModalVisible(false)} style={{ width: '100%' }}>بستن</Button>
                    <Button type="primary" loading={loading}
                        onClick={() => submit(data.id)}
                        style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(10, 23, 43)' }}>ثبت پابان برنامه</Button>
                </div>}
                onCancel={() => setIsShowModalVisible(false)}
            >
                مجموع مبلغ پرداخت شده توسط اعضای برنامه: <AmountFormat amount={informationEndOfProgram.totalPaidAmount} /> ریال
                <br></br>
                <br></br>
                دستمزد محاسبه شده برای سرپرست برنامه: <AmountFormat amount={informationEndOfProgram.leaderAmount} /> ریال
                <br></br>

                <label className='margintop-18'> در صورتیکه میخواهید دستمزد محاسبه شده برای سرپرست اعمال کنید مبلغ را بصورت ریال و با اعداد انگلیسی در کادر زیر وارد نمایید در غیر اینصورت آنرا خالی بگذارید این مبلغ نباید بیشتر یا مساوی مجموع هزینه های واریزی برنامه باشد</label>
                <InputNumberFormat value={model.amount} onValueChange={(value) => setModel({ ...model, customeLeaderAmount: value })} />


                {isShowAssistantLeaderAmount && <><label className='margintop-18'>دستمزد مربیان یا کمک سرپرستان برنامه(به ریال وارد شود و درصورتی که هزینه ای ندارد یا برنامه مربی ندارد خالی بگذارید)</label>
                    <InputNumberFormat value={model.assistantLeaderAmount} onValueChange={(value) => setModel({ ...model, assistantLeaderAmount: value })} /></>}

                {true && <><label className='margintop-18'>هزینه بیمه برنامه(به ریال وارد شود و درصورتی که هزینه ای ندارد خالی بگذارید)</label>
                    <InputNumberFormat value={model.insuranceAmount} onValueChange={(value) => setModel({ ...model, insuranceAmount: value })} /></>}

                {isShowOtherProgramCosts && <><label className='margintop-18'>سایز هزینه های برنامه(به ریال وارد شود و درصورتی که هزینه ای ندارد خالی بگذارید)</label>
                    <InputNumberFormat value={model.otherProgramCosts} onValueChange={(value) => setModel({ ...model, otherProgramCosts: value })} />

                    <label className='margintop-18'>توضیحات سایر هزینه های برنامه</label>
                    <textarea value={model.otherProgramCostsDescription} onChange={(event) => setModel({ ...model, otherProgramCostsDescription: event.target.value })}
                        style={{
                            marginTop: 5,
                            resize: 'none',
                            width: '100%',
                            minHeight: 100
                        }} /></>}
            </Modal>
        </>
    );
});

EndOfProgram.propTypes = {
    data: PropTypes.any,
    onRefresh: PropTypes.any,
    isShowOtherProgramCosts: PropTypes.bool,
    isShowAssistantLeaderAmount: PropTypes.bool
};

EndOfProgram.displayName = 'EndOfProgram';

export default EndOfProgram;
