
import { SET_PERMISSIONS, RESET_PERMISSIONS } from './actionTypes'

export const setPermissions = (permissions) => ({
  type: SET_PERMISSIONS,
  payload: permissions,
});

export const resetPermissions = () => ({
  type: RESET_PERMISSIONS,
});