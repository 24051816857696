import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrls } from '../../apiUrls.js';

const baseURL = apiUrls.baseURL;

const Permission = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const { id } = useParams();

    useEffect(() => {
        if (id !== undefined)
            fetchData(id)
    }, [])

    const getSelectedItems = () => {
        const selectedItems = data.flatMap((item) =>
            item.childs.filter((child) => child.isPermission)
        );
        return selectedItems.map(x => x.id);
    };

    const fetchData = async (id) => {
        setLoading(true);
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/permission/getfromrole?roleid=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;
            setData(result);
            setLoading(false);
        } catch (error) {
            //alert(error)
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const save = async () => {
        setLoadingSave(true);
        try {
            const token = localStorage.getItem('damavandclub-token')
            let selectedIds = getSelectedItems()
            await axios.post(`${baseURL}/permission/setpermissions?roleid=${id}`, selectedIds, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })
            toast.success("تغییرات با موفقیت ذخیره شد", {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } catch (error) {
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } finally {
            setLoadingSave(false);
        }
    }

    if (loading) {
        return <Spin size="large" />
    }

    return (
        <div className='row' style={{
            backgroundColor: 'white',
            height: '100%',
            padding: 10
        }}>
            <Button onClick={() => save()} loading={loadingSave} style={{ backgroundColor: 'rgb(0 151 46)', marginTop: 10, width: '100%', marginBottom: 10 }}
                type="primary"><label>ذخیره</label></Button>

            {data?.map((item, index) => {
                return <div className='col-md-2 col-sm-12 container-box' key={index}>
                    <span className='blue'>{item.groupTitle}</span>
                    <div className="horizontal-line-program"></div>
                    {item.childs.map((child, childIndex) => {
                        return <div className='row-container' key={childIndex}>
                            {child.title}
                            <input type='checkbox'
                                onChange={(value) => {
                                    setData((prevItems) => {
                                        const updatedItems = [...prevItems];
                                        const parentIndex = updatedItems.findIndex((x) => x.id === item.id);
                                        if (parentIndex !== -1 && childIndex >= 0 && childIndex < updatedItems[parentIndex].childs.length) {
                                            updatedItems[parentIndex].childs[childIndex] = {
                                                ...updatedItems[parentIndex].childs[childIndex],
                                                isPermission: value.target.checked,
                                            };
                                        }
                                        return updatedItems;
                                    });
                                }}
                                checked={child.isPermission} />
                        </div>
                    })}
                </div>
            })}
        </div>
    )
}

export default Permission
