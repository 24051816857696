/* eslint-disable react/react-in-jsx-scope */
import { Input } from "antd"



const Sample = () => {




    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <div style={{ width: '100%', height: 500, backgroundColor: 'yellow' }}>
           <button onClick={()=>alert('hi hojat im from javascript function!')} style={{color:'red'}}>click me</button> <input type="text"></input>
        </div>
    )
}

export default Sample
