import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Table, Pagination, Modal } from 'antd';
import { apiUrls } from '../apiUrls.js';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import InputNumberFormat from "../components/InputNumberFormat.js";

const { Option } = Select;
const baseURL = apiUrls.baseURL;

const AddMemberProgram = React.memo(({ data, onRefresh }) => {

    const navigate = useNavigate();
    const [isShowModalVisible, setIsShowModalVisible] = useState(false);
    const [dropDownUser, setDropDownUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [model, setModel] = useState({});

    useEffect(() => {
        setModel({ ...model, programId: data.id })
        fetchUsers()
    }, [])

    const fetchUsers = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })
            var result = response.data.data;
            setDropDownUsers(result);
        } catch (error) {
        } finally {
        }
    };

    const submit = async () => {
        
        setLoading(true)

        if (model.amount == undefined || model.amount == null) {
            setLoading(false)
            return toast.error("مبلغ وارد شده صحیح نیست", {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }

        const token = localStorage.getItem('damavandclub-token')
        try {
            await axios.post(`${baseURL}/userprogram/addmember`, model, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })
            onRefresh()
            setIsShowModalVisible(false)
            setLoading(false)
            return toast.success("با موفقیت ثبت شد", {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } catch (error) {
            setIsShowModalVisible(false)
            setLoading(false)
            if (error.response.status == 401)
                navigate('/');
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } finally {
            setIsShowModalVisible(false)
            setLoading(false)
        }
    };

    return (
        <>
            <Button onClick={() => { setIsShowModalVisible(true); setModel({ ...model, userId: null, amount: null }) }}
                style={{ marginTop: 10, width: '100%' }}>اضافه کردن عضو به برنامه</Button>

            <Modal
                title={`اضافه کردن عضو به برنامه :  ${data.nameFa}`}
                open={isShowModalVisible}
                closable={false}
                okButtonProps={null}
                bodyStyle={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
                footer={<div className='row-container'>
                    <Button onClick={() => setIsShowModalVisible(false)} style={{ width: '100%' }}>بستن</Button>
                    <Button type="primary" loading={loading}
                        onClick={() => submit()}
                        style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(10, 23, 43)' }}>تایید</Button>
                </div>}
                onCancel={() => setIsShowModalVisible(false)}
            >
                <span style={{ color: 'red' }}>سرپرست یا مدیر کارگروه گرامی</span>
                این پنجره برای اضافه کردن دوست شما با مبلغی کمتر از هزینه برنامه طراحی شده است. شما میتوانید عضو مورد نظر را از لیست زیر جستجو و سپس مبلغ مورد نظر را در کادر زیر آن وارد نمایید. بدیهی است هر گونه مسئولیت این کار پس از اتمام برنامه و در هنگام محاسبات دستمزد شما، بر عهده خودتان خواهد بود. پس از اتمام برنامه دستمزد شما بر اساس واریزی های اعضای برنامه محاسبه خواهد شد!
                <Select
                    style={{ width: '100%', marginTop: 10 }}
                    placeholder="انتخاب عضو"
                    allowClear={true}
                    showSearch
                    optionFilterProp="label"
                    onChange={(value) => {
                        setModel({ ...model, userId: value })
                    }}
                    value={model.userId}
                >
                    {dropDownUser?.map((item) => (
                        <Option key={item.id} label={item.displayName} value={item.id}>
                            {item.displayName}
                        </Option>
                    ))}
                </Select>

                <label className='margintop-18'>هزینه برنامه(ریال) با اعداد انگلیسی وارد کنید</label>
                <InputNumberFormat value={model.amount} onValueChange={(value) => setModel({ ...model, amount: value })} />

            </Modal>
        </>
    );
});

AddMemberProgram.propTypes = {
    data: PropTypes.any,
    onRefresh: PropTypes.any
};

AddMemberProgram.displayName = 'AddMemberProgram';

export default AddMemberProgram;
