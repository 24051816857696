import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { FilePdfOutlined, PictureOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Tabs, Upload, Select, Spin } from 'antd';

const InputNumberFormat = ({ value, onValueChange, placeholder }) => {

  useEffect(() => {
    if (value == null)
      setNumber('')
    else {
      let englishValue = PersianToEnglishNumbers(value);
      englishValue = removeLeadingZeros(englishValue);
      englishValue = formatNumberWithCommas(englishValue);
      setNumber(englishValue);
    }
    // else
    //   setNumber(new Intl.NumberFormat().format(parseInt(value.toString().replace(/[^0-9]/g, ''), 10)))
  }, [value])

  const [number, setNumber] = useState('');

  const removeLeadingZeros = (number) => {
    return number.replace(/^0+/, '') || '0';
  };

  const PersianToEnglishNumbers = (input) => {
    return input.toString().replace(/[۰-۹]/g, (char) => {
      return String.fromCharCode(char.charCodeAt(0) - 1728);
    });
  };

  const formatNumberWithCommas = (number) => {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleInputChange = (e) => {
    // Remove existing commas and non-digit characters
    const input = e.target.value.replace(/[^0-9]/g, '');

    // Format the number with commas
    const formattedNumber = input === '' ? '' : new Intl.NumberFormat().format(parseInt(input, 10));

    // Update the state
    setNumber(formattedNumber);
    onValueChange(input);
  };
  return (
    <Input placeholder={placeholder} value={number} onChange={handleInputChange} style={{ marginTop: 5 }} size="large" />
  );
};


InputNumberFormat.propTypes = {
  onValueChange: PropTypes.any,
  placeholder: PropTypes.any,
  value: PropTypes.any
};

export default InputNumberFormat;
