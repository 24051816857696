import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react'
import {
    CChartBar,
    CChartDoughnut,
    CChartLine,
    CChartPie,
    CChartPolarArea,
    CChartRadar,
} from '@coreui/react-chartjs'
import { DocsCallout } from 'src/components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox, Pagination, Table, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { apiUrls } from '../../apiUrls.js';
import Program from "../../components/Program.js";
import { useNavigate } from 'react-router-dom';
import { UnlockOutlined, LockOutlined, EditOutlined, SafetyCertificateOutlined, SearchOutlined } from '@ant-design/icons';
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";
const baseURL = apiUrls.baseURL;

const Guest = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [filter, setFilter] = useState({ skip: 0, limit: 20, search: "" });
    const [reload, setReload] = useState(false);
    const navigate = useNavigate();
    const [isShowChangeToTrialModalVisible, setIsShowChangeToTrialModalVisible] = useState(false);
    const [loadingChangeToTrial, setLoadingChangeToTrial] = useState(false);
    const [memberData, setMemberData] = useState({});

    const columns = [
        {
            title: 'ردیف',
            dataIndex: 'index',
            key: 'index',
            responsive: ['xl', 'lg'],
            width: 50,
            render: (_, record, index) => (
                <>{index + 1 + filter?.limit * filter?.skip}</>
            ),
        },
        {
            title: 'نام',
            key: 'firstName',
            dataIndex: 'firstName',
            width: 100,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                <div>{record.firstName} <br /> {record.lastName}</div>
            ),
        },
        {
            title: 'تلفن/کدملی',
            key: 'phoneNumber',
            dataIndex: 'phoneNumber',
            width: 10,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                <div>{record.phoneNumber} <br /> {record.nationalCode}</div>
            ),
        },
        {
            title: 'تصویر',
            key: 'profilePhotoUrl',
            dataIndex: 'profilePhotoUrl',
            width: 100,
            responsive: ['xl', 'lg', 'md'],
            render: (text, record) => (
                record.profilePhotoUrl !== null ? <a rel="noreferrer" href={record.profilePhotoUrl} target='_blank'>
                    <img style={{ borderRadius: 50 }} width={100} height={100} src={record.profilePhotoUrl}>
                    </img></a> : 'بدون تصویر'
            ),
        },
        {
            title: 'تبدیل به آزمایشی',
            key: 'id',
            dataIndex: 'id',
            width: 100,
            responsive: ['xl', 'lg', 'md'],
            render: (text, record) => (
                <SafetyCertificateOutlined onClick={() => {
                    setIsShowChangeToTrialModalVisible(true)
                    setMemberData({ memberName: record.firstName + " " + record.lastName, memberId: record.id })
                }} style={{ color: 'green', fontSize: 30 }} />
            ),
        },
        {
            title: 'جزئیات',
            key: 'id',
            dataIndex: 'id',
            width: 100,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                <a rel='noreferrer' href={'/members/detail/' + record.id}><EditOutlined style={{ color: 'blue', fontSize: 30 }} /></a>
            ),
        },
    ];

    useEffect(() => {
        setLoading(true);
        fetchData()
    }, [reload])

    const changeToTrial = async () => {
        setLoadingChangeToTrial(true)
        const token = localStorage.getItem('damavandclub-token')
        try {
            await axios.get(`${baseURL}/user/SetTrialMember?id=${memberData.memberId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            toast.success("با موفقیت ثبت شد", {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            fetchData()
            setIsShowChangeToTrialModalVisible(false)
            setLoadingChangeToTrial(false)
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoadingChangeToTrial(false);
        } finally {
            setLoadingChangeToTrial(false);
        }
    };

    const fetchData = async () => {

        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getGuestMembers?skip=${filter.skip}&limit=${filter.limit}&search=${filter.search}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;
            setCount(result.count)
            setData(result.items);
            setLoading(false);
            setReload(false)
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
            setReload(false)
        } finally {
            setLoading(false);
            setReload(false)
        }
    };

    return (
        <div>
            <HideSidebarInMobileMode />
            <div className="row-container margintop-5">

                <div style={{ border: '1px solid lightgray', marginTop: 5 }}><SearchOutlined style={{ color: 'blue', fontSize: 35 }} /></div>
                <Input placeholder='جستجو با نام نام خانوادگی کد ملی عنوان شغلی و شماره عضویت' onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        setFilter({ ...filter, skip: 0, limit: 5 })
                        setReload(true)
                    }

                }} onChange={(event) => setFilter({ ...filter, search: event.target.value })} style={{ marginTop: 5 }} size="large" />
            </div>
            <Table
                size='small'
                style={{
                    textAlign: 'right',
                    marginTop: 10
                }}
                rowClassName={() => 'center'}
                columns={columns}
                bordered={true}
                dataSource={data}
                loading={loading}
                pagination={false}
                locale={{
                    emptyText: 'موردی یافت نشد',
                }}
            />
            <Pagination
                onChange={(pageNumber, limit) => {
                    setFilter({ ...filter, skip: pageNumber > 0 ? pageNumber - 1 : pageNumber, limit: limit })
                    setReload(true)
                }}
                style={{ marginTop: 30, marginBottom: 5 }}
                current={filter.skip + 1}
                responsive={true}
                pageSize={filter.limit}
                showTotal={(total) => `تعداد کل: ${total} عضو`}
                total={count} />
            <Modal
                title={`تبدیل به آزمایشی ${memberData.memberName}`}
                open={isShowChangeToTrialModalVisible}
                closable={false}
                okButtonProps={null}
                footer={<div className='row-container'>
                    <Button onClick={() => setIsShowChangeToTrialModalVisible(false)} style={{ width: '100%' }}>بستن</Button>
                    <Button type="primary" loading={loadingChangeToTrial}
                        onClick={() => changeToTrial()}
                        style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(10, 23, 43)' }}>تایید</Button>
                </div>}
            >
                آیا از آزمایشی شدن این عضو مطمئن هستید؟
            </Modal>
        </div>
    )
}

export default Guest
