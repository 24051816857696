import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react'
import {
    CChartBar,
    CChartDoughnut,
    CChartLine,
    CChartPie,
    CChartPolarArea,
    CChartRadar,
} from '@coreui/react-chartjs'
import { DocsCallout } from 'src/components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import jquery from 'jquery';
import BraftEditor from 'braft-editor';
import { FilePdfOutlined } from '@ant-design/icons';
import { apiUrls } from '../../../apiUrls.js';

const baseURL = apiUrls.baseURL;
const { TabPane } = Tabs;
const { Option } = Select;

const DirectorForm = () => {

    const [data, setData] = useState({ medias: [] });
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [dropDownMember, setDropDownMembers] = useState([]);
    const permissions = useSelector((state) => state.permissions.permissions);

    //eslint-disable-next-line react-hooks/rules-of-hooks  
    const { id } = useParams();

    useEffect(() => {
        if (id !== undefined)
            fetchData(id)
        fetchMembers()
    }, [])

    const fetchMembers = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })

            var result = response.data.data;
            setDropDownMembers(result);
        } catch (error) {
        } finally {
        }
    };

    const setSelectedStartDate = async (date) => {
        setStartDate({
          year: date.year,
          month: date.month,
          day: date.day
        });
        setData({ ...data, startDate: `${date.year}/${date.month}/${date.day}` })
      }

    const fetchData = async (id) => {
        setLoading(true);
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/clubdirector/getbyid?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            var result = response.data.data;

            if (result.startDate != null) {
                var resultStartDate = result.startDate.split('/');
                setStartDate({
                    year: parseInt(resultStartDate[0], 10),
                    month: parseInt(resultStartDate[1], 10),
                    day: parseInt(resultStartDate[2], 10)
                });
            }
            setData(result);
            setLoading(false);
        } catch (error) {
            //alert(error)
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const save = async () => {
        setLoadingSave(true);
        try {
            const token = localStorage.getItem('damavandclub-token')

            if (id !== undefined)
                await axios.post(`${baseURL}/clubdirector/update?id=${id}`, data, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                })
            else
                await axios.post(`${baseURL}/clubdirector/create`, data, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                })

            toast.success("تغییرات با موفقیت ذخیره شد", {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } catch (error) {
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } finally {
            setLoadingSave(false);
        }
    }

    if (loading) {
        return <Spin size="large" />
    }

    return (
        <div className='row'>
            <div style={{
                backgroundColor: 'white',
                flexDirection: 'column',
                display: 'flex',
                flex: 1,
                height: '100%',
                padding: 10
            }}>
                <Button onClick={() => save()} loading={loadingSave} style={{ backgroundColor: 'rgb(0 151 46)', marginTop: 10, width: '100%', marginBottom: 10 }}
                    type="primary"><label>ذخیره یا ویرایش</label></Button>


                <label>عنوان</label>
                <Input value={data.title} onChange={(event) => setData({ ...data, title: event.target.value })} style={{ marginTop: 5 }} size="large" />


                <label className='margintop-7'>انتخاب مسئول</label> <Select
                    style={{ width: '100%' }}
                    placeholder="انتخاب مسئول"
                    optionFilterProp="label"
                    showSearch
                    onChange={(value) => setData({ ...data, userId: value })}
                    value={data.userId}
                >
                    {dropDownMember?.map((item) => (
                        <Option key={item.id} label={item.displayName} value={item.id}>
                            {item.displayName}
                        </Option>
                    ))}
                </Select>

                <label className='margintop-7'>تاریخ شروع مسئولیت</label>
                <div className='margintop-7 center-content customDatePickerWidth'>
                    <DatePicker
                        value={startDate || null}
                        //inputClassName='fullwidth'
                        onChange={(value) => setSelectedStartDate(value)}
                        //minimumDate={persianToday}
                        //calendarClassName="fullwidth"
                        shouldHighlightWeekends
                        locale='fa'
                    />
                </div>




                <label className='margintop-7'>توضیحات</label>
                <textarea value={data.descriptionFa} onChange={(event) => setData({ ...data, descriptionFa: event.target.value })}
                    style={{
                        marginTop: 5,
                        resize: 'none',
                        width: '100%',
                        minHeight: 400
                    }} />
                <Checkbox checked={data.isActive} onChange={() => setData({ ...data, isActive: !data.isActive })}>فعال/غیرفعال</Checkbox>



            </div>
        </div >
    )
}

export default DirectorForm
