import { toast } from 'react-toastify';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox, Pagination, Table, Collapse, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { apiUrls } from '../../apiUrls.js';
import { useNavigate } from 'react-router-dom';
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";
import AmountFormat from "../../components/AmountFormat.js";
import { UnlockOutlined, LockOutlined, EditOutlined, SearchOutlined, DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;

const baseURL = apiUrls.baseURL;

const StoreList = () => {

    const [data, setData] = useState([]);
    const [StoreModel, setStoreModel] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [count, setCount] = useState(0);
    const [filter, setFilter] = useState({ skip: 0, limit: 20, data: {} });
    const [dropDownUserType, setDropDownUserTypes] = useState([]);
    const [dropDownUser, setDropDownUsers] = useState([]);
    const [isShowCreateUserStoreModalVisible, setIsShowCreateUserStoreModalVisible] = useState(false);
    const [isShowCreateGroupStoreModalVisible, setIsShowCreateGroupStoreModalVisible] = useState(false);
    const permissions = useSelector((state) => state.permissions.permissions);
    const navigate = useNavigate();

    const columns = [
        {
            title: 'ردیف',
            dataIndex: 'index',
            key: 'index',
            responsive: ['xl', 'lg'],
            width: 50,
            render: (_, record, index) => (
                <>{index + 1 + filter?.limit * filter?.skip}</>
            ),
        },
        {
            title: 'عنوان',
            key: 'nameFa',
            dataIndex: 'nameFa',
            width: 100,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs']
        },
        {
            title: 'موجودی کل',
            key: 'count',
            dataIndex: 'count',
            width: 10,
            responsive: ['xl', 'lg', 'md'],
        },
        {
            title: 'موجودی باقی مانده',
            key: 'remainingCount',
            dataIndex: 'remainingCount',
            width: 10,
            responsive: ['xl', 'lg', 'md'],
        },
        {
            title: 'هزینه اجاره',
            key: 'price',
            dataIndex: 'persianCreateDate',
            width: 10,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                <AmountFormat amount={record.price}></AmountFormat>
            ),
        },
        {
            title: 'ویرایش',
            key: 'id',
            dataIndex: 'id',
            width: 100,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                <EditOutlined onClick={() => {
                    navigate('/store/storeform/' + record.id)
                }} style={{ color: 'blue', fontSize: 30 }}>  </EditOutlined>
            ),
        },
    ];

    useEffect(() => {
        setLoading(true)
        fetchData()
    }, [filter.limit, filter.skip])

    const fetchData = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.post(`${baseURL}/Store/get`, filter, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;
            result.items.map(item => {
                item.key = item.id
            })
            setCount(result.count)
            setData(result.items);
            setLoading(false);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <HideSidebarInMobileMode />

            <div className='row-container'>

               <Button onClick={() => navigate('/store/storeform')}
                    style={{ backgroundColor: 'rgb(44 0 151)', marginTop: 10, width: '100%', marginBottom: 10 }}
                    type="primary"><label>اضافه کردن وسیله</label></Button>
            </div>

            <Table
                size='small'
                style={{
                    textAlign: 'right',
                    marginTop: 10
                }}
                rowClassName={'center'}
                columns={columns}
                bordered={true}
                dataSource={data}
                loading={loading}
                pagination={false}
                expandable={{
                    expandedRowRender: (record) => <div style={{ textAlign: 'right' }}>
                        {/* <div>متن پیام: {record.Store}</div>
                        <div>ارسال کننده: {record.creatorName}</div>
                        <div>شماره: {record.phoneNumber}</div> */}
                    </div>
                    //rowExpandable: (record) => record.name !== 'Not Expandable',
                }}
                locale={{
                    emptyText: 'موردی یافت نشد',
                }}
            />
            <Pagination
                onChange={(pageNumber, limit) => setFilter({ skip: pageNumber > 0 ? pageNumber - 1 : pageNumber, limit: limit })}
                style={{ marginTop: 30, marginBottom: 5 }}
                current={filter.skip + 1}
                responsive={true}
                pageSize={filter.limit}
                showTotal={(total) => `تعداد کل: ${total}`}
                locale={{ items_per_page: '' }}
                total={count} />

        </div>
    )
}

export default StoreList
