import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrls } from '../../apiUrls.js';

const baseURL = apiUrls.baseURL;
const { TabPane } = Tabs;
const { Option } = Select;

const RoleForm = () => {

    const [data, setData] = useState({ medias: [] });
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const { id } = useParams();

    useEffect(() => {
        if (id !== undefined)
            fetchData(id)
    }, [])

    const fetchData = async (id) => {
        setLoading(true);
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/role/getbyid?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;
            setData(result);
            setLoading(false);
        } catch (error) {
            //alert(error)
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const save = async () => {
        setLoadingSave(true);
        try {
            const token = localStorage.getItem('damavandclub-token')

            if (id !== undefined) {
                await axios.post(`${baseURL}/role/update?id=${id}`, data, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                })
            }

            else {
                await axios.post(`${baseURL}/role/create`, data, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                })
            }

            toast.success("تغییرات با موفقیت ذخیره شد", {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } catch (error) {
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } finally {
            setLoadingSave(false);
        }
    }

    if (loading) {
        return <Spin size="large" />
    }

    return (
        <div className='row'>
            <div style={{
                backgroundColor: 'white',
                flexDirection: 'column',
                display: 'flex',
                flex: 1,
                height: '100%',
                padding: 10
            }}>
                <Button onClick={() => save()} loading={loadingSave} style={{ backgroundColor: 'rgb(0 151 46)', marginTop: 10, width: '100%', marginBottom: 10 }}
                    type="primary"><label>ذخیره یا ویرایش نقش</label></Button>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="فارسی" key="1">
                        <label>عنوان صفحه</label>
                        <Input value={data.nameFa} onChange={(event) => setData({ ...data, nameFa: event.target.value })} style={{ marginTop: 5 }} size="large" />

            


                        <label className='margintop-7'>توضیحات</label>
                        <textarea value={data.descriptionFa} onChange={(event) => setData({ ...data, descriptionFa: event.target.value })}
                            style={{
                                marginTop: 5,
                                resize: 'none',
                                width: '100%',
                                minHeight: 200
                            }} />
                        <Checkbox checked={data.isActive} onChange={() => setData({ ...data, isActive: !data.isActive })}>فعال/غیرفعال</Checkbox>
                    </TabPane>
                    <TabPane tab="انگلیسی" key="2">
                        <label>عنوان برنامه</label>
                        <Input value={data.nameEn} onChange={(event) => setData({ ...data, nameEn: event.target.value })} style={{ marginTop: 5 }} size="large" />
                        <label className='margintop-7'>توضیحات</label>
                        <textarea value={data.descriptionEn} onChange={(event) => setData({ ...data, descriptionEn: event.target.value })}
                            style={{
                                marginTop: 5,
                                resize: 'none',
                                width: '100%',
                                minHeight: 200
                            }} />
                    </TabPane>

                </Tabs>
            </div>
        </div >
    )
}

export default RoleForm
