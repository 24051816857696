import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from '@coreui/react'
import React, { useState, useEffect } from 'react';
import CIcon from '@coreui/icons-react'
import { cilBell, cilEnvelopeOpen, cilList, cilMenu } from '@coreui/icons'
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox, Pagination, Table, Collapse, Modal } from 'antd';
import { AppBreadcrumb } from './index'
import { AppHeaderDropdown } from './header/index'
import { logo } from 'src/assets/brand/logo'
import {
  LogoutOutlined,
  KeyOutlined
} from '@ant-design/icons';
import { apiUrls } from '../apiUrls.js';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { MenuOutlined } from '@ant-design/icons';

const AppHeader = () => {

  const baseURL = apiUrls.baseURL;
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebar.isShow)
  const [isShowChangePasswordModalVisible, setIsShowChangePasswordModalVisible] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [passwordModel, setPasswordModel] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setPasswordModel({})
  }, [])

  const changePassword = async () => {
    setLoadingSave(true);

    if (passwordModel.newPassword !== passwordModel.repeatNewPassword) {
      setLoadingSave(false)
      setIsShowChangePasswordModalVisible(false)
      return toast.error('پسورد جدید مطابقت ندارد', {
        position: 'bottom-right',
        autoClose: 6000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        //className: 'custom-toast',
        pauseOnHover: true,
        draggable: true,
      });
    }

    const token = localStorage.getItem('damavandclub-token')

    try {
      await axios.post(`${baseURL}/user/changepassword`, passwordModel, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      toast.success('با موفقیت ارسال شد', {
        position: 'bottom-right',
        autoClose: 6000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        //className: 'custom-toast',
        pauseOnHover: true,
        draggable: true,
      });
      setLoadingSave(false);
      setIsShowChangePasswordModalVisible(false)
    } catch (error) {
      if (error.response.status == 401)
        return navigate('/');
      setIsShowChangePasswordModalVisible(false)
      toast.error(error.response.data.message, {
        position: 'bottom-right',
        autoClose: 6000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        //className: 'custom-toast',
        pauseOnHover: true,
        draggable: true,
      });
      setLoadingSave(false);
    } finally {
      setLoadingSave(false);
    }
  }

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: 'set', isShow: !sidebarShow })}
        >
          <MenuOutlined style={{ fontSize: 30 }} twoToneColor="#eb2f96"/>
            {/* <CIcon icon={cilMenu} size="lg" /> */}
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          {/* <CIcon icon={logo} height={48} alt="Logo" /> */}
        </CHeaderBrand>
        {/* <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem>
            <CNavLink to="/dashboard" component={NavLink}>
              Dashboard
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Users</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Settings</CNavLink>
          </CNavItem>
        </CHeaderNav> */}
        <CHeaderNav>
          <CNavItem>
            <CNavLink>
              <KeyOutlined onClick={() => {
                setIsShowChangePasswordModalVisible(true)
                setPasswordModel({})
              }} style={{ fontSize: 30, color: 'black' }} twoToneColor="#eb2f96" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink>
              <LogoutOutlined onClick={() => {
                localStorage.removeItem("damavandclub-token");
                navigate('')
              }} style={{ fontSize: 30, color: 'red', fontWeight: 'bold' }} twoToneColor="#eb2f96" />
            </CNavLink>
          </CNavItem>
          {/* <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilList} size="lg" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilEnvelopeOpen} size="lg" />
            </CNavLink>
          </CNavItem> */}
        </CHeaderNav>
        {/* <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav> */}
      </CContainer>
      <CHeaderDivider />
      {/* <CContainer fluid>
        <AppBreadcrumb />
      </CContainer> */}
      <Modal
        title={`تغییر پسورد`}
        open={isShowChangePasswordModalVisible}
        width={500}
        closable={false}
        okButtonProps={null}
        footer={<div className='row-container'>
          <Button onClick={() => {
            setPasswordModel({})
            setIsShowChangePasswordModalVisible(false)
          }} style={{ width: '100%' }}>بستن</Button>
          <Button type="primary" loading={loadingSave}
            onClick={() => changePassword()}
            style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(10, 23, 43)' }}>ذخیره</Button>
        </div>}
      >
        <label>پسورد قبلی</label>
        <Input autocomplete="new-password" type='password' value={passwordModel.oldPassword} onChange={(event) => setPasswordModel({ ...passwordModel, oldPassword: event.target.value })} style={{ marginTop: 5 }} size="large" />

        <label className='margintop-7'>پسورد جدید</label>
        <Input type='password' value={passwordModel.newPassword} onChange={(event) => setPasswordModel({ ...passwordModel, newPassword: event.target.value })} style={{ marginTop: 5 }} size="large" />

        <label className='margintop-7'>تکرار پسورد جدید</label>
        <Input type='password' value={passwordModel.repeatNewPassword} onChange={(event) => setPasswordModel({ ...passwordModel, repeatNewPassword: event.target.value })} style={{ marginTop: 5 }} size="large" />

      </Modal>
    </CHeader>
  )
}

export default AppHeader
