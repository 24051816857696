import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Table, Pagination } from 'antd';

const MemberLeaderProgram = React.memo(({ data }) => {

    data.map(item => {
        item.key = item.id
    })

    const columns = [
        {
            title: 'ردیف',
            dataIndex: 'index',
            key: 'index',
            responsive: ['xl', 'lg'],
            width: 50,
            render: (_, record, index) => (
                <>{index + 1}</>
            ),
        },
        {
            title: 'برنامه/تاریخ',
            key: 'programName',
            dataIndex: 'programName',
            width: 100,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                <div>{record.programName} <br /> {record.programDate}</div>
            ),
        },
        {
            title: 'کارگروه',
            key: 'workingGroupCategoryName',
            dataIndex: 'workingGroupCategoryName',
            width: 10,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs']
        },
        {
            title: 'امتیاز',
            key: 'rate',
            dataIndex: 'rate',
            width: 10,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                record.rate !== 0 ? record.rate : 'امتیازی داده نشده است'
            ),
        },
        {
            title: 'تعداد',
            key: 'numberOfMembers',
            dataIndex: 'numberOfMembers',
            width: 10,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs']
        }
    ];
    return (
        <>
            <Table
                size='small'
                style={{
                    textAlign: 'right',
                    marginTop: 10
                }}
                rowClassName={() => 'center'}
                columns={columns}
                bordered={true}
                dataSource={data}
                pagination={false}
                locale={{
                    emptyText: 'موردی یافت نشد',
                }}
            />
            تعداد کل: {data.length}
        </>
    );
});

MemberLeaderProgram.propTypes = {
    data: PropTypes.any,
};

MemberLeaderProgram.displayName = 'MemberLeaderProgram';

export default MemberLeaderProgram;
