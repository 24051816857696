import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react'
import {
    CChartBar,
    CChartDoughnut,
    CChartLine,
    CChartPie,
    CChartPolarArea,
    CChartRadar,
} from '@coreui/react-chartjs'
import { DocsCallout } from 'src/components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox, Pagination, Table, Collapse, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { apiUrls } from '../../apiUrls.js';
import Program from "../../components/Program.js";
import { useNavigate } from 'react-router-dom';
import { UnlockOutlined, LockOutlined, EditOutlined, SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import AmountFormat from "../../components/AmountFormat.js";
import InputNumberFormat from "../../components/InputNumberFormat.js";
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import { UploadOutlined } from '@ant-design/icons';
import jquery from 'jquery';
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";

const { Option } = Select;

const baseURL = apiUrls.baseURL;

const ProgramCancelPolicyList = () => {

    const [data, setData] = useState([]);
    const [workingGroupCancelPolicyData, setWorkingGroupCancelPolicyData] = useState([]);
    const [workingGroupCancelPolicyDataCount, setWorkingGroupCancelPolicyDataCount] = useState(0);
    const [policyModel, setPolicyModel] = useState({});
    const [workingGroupId, setWorkingGroupId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [count, setCount] = useState(0);
    const [filter, setFilter] = useState({ skip: 0, limit: 20, data: {} });
    const [isShowCreateProgramCancelPolicyModalVisible, setIsShowCreateProgramCancelPolicyModalVisible] = useState(false);
    const [isShowCancelPoliciesModalVisible, setIsShowCancelPoliciesModalVisible] = useState(false);
    const permissions = useSelector((state) => state.permissions.permissions);
    const navigate = useNavigate();
    const [reload, setReload] = useState(false);

    const columns = [
        {
            title: 'ردیف',
            dataIndex: 'index',
            key: 'index',
            responsive: ['xl', 'lg'],
            width: 50,
            render: (_, record, index) => (
                <>{index + 1 + filter?.limit * filter?.skip}</>
            ),
        },
        {
            title: 'کارگروه',
            key: 'nameFa',
            dataIndex: 'nameFa',
            width: 100,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs']
        },
        {
            title: 'تعرفه ها',
            key: 'id',
            dataIndex: 'id',
            width: 30,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                <button onClick={() => {
                    showCancelPoliciesModal(record.id)
                    setWorkingGroupId(record.id)
                }} style={{ color: 'green' }} > تعرفه ها</button>
            ),
        },
    ];

    const cancelPolicyColumns = [
        {
            title: 'ردیف',
            dataIndex: 'index',
            key: 'index',
            responsive: ['xl', 'lg'],
            width: 50,
            render: (_, record, index) => (
                <>{index + 1 + filter?.limit * filter?.skip}</>
            ),
        },
        {
            title: 'تعداد روز باقی مانده تا شروع برنامه',
            key: 'remainingDaysToStartProgram',
            dataIndex: 'remainingDaysToStartProgram',
            width: 100,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs']
        },
        {
            title: 'درصد کسر از هزینه پرداخت شده',
            key: 'costReductionPercentage',
            dataIndex: 'costReductionPercentage',
            width: 100,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs']
        },
        {
            title: 'وضعیت',
            key: 'isActive',
            dataIndex: 'isActive',
            width: 100,
            responsive: ['xl', 'lg', 'md'],
            render: (text, record) => (
                record.isActive ? <UnlockOutlined onClick={() => ActiveOrUnActive(record.id)}
                    style={{ color: 'green', fontSize: 30 }} /> : <LockOutlined onClick={() => ActiveOrUnActive(record.id)}
                        style={{ color: 'red', fontSize: 30 }} />
            ),
        },
    ];

    useEffect(() => {
        setLoading(true)
        fetchData()
    }, [reload])

    const fetchData = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.post(`${baseURL}/workinggroupcategory/get`, filter, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;
            result.items.map(item => {
                item.key = item.id
            })
            setCount(result.count)
            setData(result.items);
            setLoading(false);
            setReload(false)
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
            setReload(false)
        } finally {
            setLoading(false);
            setReload(false)
        }
    };

    const ActiveOrUnActive = async (id) => {
        setIsShowCancelPoliciesModalVisible(true)
        const token = localStorage.getItem('damavandclub-token')
        try {
            await axios.get(`${baseURL}/ProgramCancelPolicy/activeorunactive?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            showCancelPoliciesModal(workingGroupId);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');

        } finally {
        }
    };

    const createProgramCancelPolicy = async () => {
        setLoadingSave(true);
        const token = localStorage.getItem('damavandclub-token')
        policyModel.workingGroupCategoryId = workingGroupId;
        try {
            await axios.post(`${baseURL}/ProgramCancelPolicy/create`, policyModel, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            fetchData()
            setLoadingSave(false);
            setIsShowCreateProgramCancelPolicyModalVisible(false)
            showCancelPoliciesModal(workingGroupId)
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } finally {
            setLoadingSave(false);
        }
    };

    const showCancelPoliciesModal = async (id) => {

        setIsShowCancelPoliciesModalVisible(true)
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/ProgramCancelPolicy/GetByWorkingGroup?workingGroupId=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;
            result.items.map(item => {
                item.key = item.id
            })
            setWorkingGroupCancelPolicyDataCount(result.count)
            setWorkingGroupCancelPolicyData(result.items);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
        } finally {
        }
    };

    return (
        <div>
            <HideSidebarInMobileMode />

            <Table
                size='small'
                style={{
                    textAlign: 'right',
                    marginTop: 10
                }}
                rowClassName={'center'}
                columns={columns}
                bordered={true}
                dataSource={data}
                loading={loading}
                pagination={false}
                locale={{
                    emptyText: 'موردی یافت نشد',
                }}
            />
            <Pagination
                onChange={(pageNumber, limit) => {
                    setFilter({ ...filter, skip: pageNumber > 0 ? pageNumber - 1 : pageNumber, limit: limit })
                    setReload(true)
                }}
                style={{ marginTop: 30, marginBottom: 5 }}
                current={filter.skip + 1}
                //current={10}
                responsive={true}
                defaultPageSize={filter.limit}
                locale={{ items_per_page: '' }}
                showTotal={(total) => `تعداد کل: ${total} کارگروه`}
                showSizeChanger={true}
                total={count} />

            <Modal
                title={`تعرفه های کارگروه`}
                open={isShowCancelPoliciesModalVisible}
                width={1000}
                closable={false}
                okButtonProps={null}
                footer={<div className='row-container'>
                    <Button onClick={() => {
                        setIsShowCancelPoliciesModalVisible(false)
                    }} style={{ width: '100%' }}>بستن</Button>
                </div>}
            >
                {permissions.includes('createbill') ? <Button onClick={() => setIsShowCreateProgramCancelPolicyModalVisible(true)} style={{ backgroundColor: 'rgb(44 0 151)', marginTop: 10, width: '100%', marginBottom: 10 }}
                    type="primary"><label>ایجاد تعرفه جدید</label></Button> : null}
                <Table
                    size='small'
                    style={{
                        textAlign: 'right',
                        marginTop: 10
                    }}
                    rowClassName={'center'}
                    columns={cancelPolicyColumns}
                    bordered={true}
                    dataSource={workingGroupCancelPolicyData}
                    loading={loading}
                    pagination={false}
                    locale={{
                        emptyText: 'موردی یافت نشد',
                    }}
                />
            </Modal>

            <Modal
                title={`ایجاد تعرفه جدید`}
                open={isShowCreateProgramCancelPolicyModalVisible}
                width={1000}
                closable={false}
                okButtonProps={null}
                footer={<div className='row-container'>
                    <Button onClick={() => {
                        setPolicyModel({})
                        setIsShowCreateProgramCancelPolicyModalVisible(false)
                    }} style={{ width: '100%' }}>بستن</Button>
                    <Button type="primary" loading={loadingSave}
                        onClick={() => createProgramCancelPolicy()}
                        style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(10, 23, 43)' }}>ذخیره</Button>
                </div>}
            >
                <div className='margintop-18'>
                    <InputNumberFormat value={policyModel.SalaryValue} placeholder="تعداد روزهای باقی مانده به شروع برنامه"
                        onValueChange={(value) => setPolicyModel({ ...policyModel, remainingDaysToStartProgram: value })} />
                </div>

                <div className='margintop-18'>
                    <InputNumberFormat value={policyModel.SalaryValue} placeholder="درصد کسر از هزینه پرداخت شده"
                        onValueChange={(value) => setPolicyModel({ ...policyModel, costReductionPercentage: value })} />
                </div>

            </Modal>
        </div>
    )
}

export default ProgramCancelPolicyList
