// /* eslint-disable default-case */
// /* eslint-disable array-callback-return */
// /* eslint-disable jsx-a11y/alt-text */
// import React, { useState, useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux'
// import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
// import CIcon from '@coreui/icons-react'
// import { AppSidebarNav } from './AppSidebarNav'
// import { logoNegative } from 'src/assets/brand/logo-negative'
// import { sygnet } from 'src/assets/brand/sygnet'
// import LogoImg from 'src/assets/images/logo.jpg'
// import SimpleBar from 'simplebar-react'
// import 'simplebar/dist/simplebar.min.css'
// import { Spin } from 'antd';
// // sidebar nav config
// import menuItems from '../_nav'

// const AppSidebar = () => {

//   const dispatch = useDispatch()
//   const unfoldable = useSelector((state) => state.sidebarUnfoldable)
//   const sidebarShow = useSelector((state) => state.sidebar.isShow)

//   const profilePhotoUrl = localStorage.getItem('damavandclub-profilephotourl')
//   const fullName = localStorage.getItem('damavandclub-fullname')
//   const memberTypeStorage = localStorage.getItem('damavandclub-membertype')
//   const memberType = memberTypeStorage.substring(0, memberTypeStorage.indexOf('('))
//   const roleType = memberTypeStorage.substring(50, memberTypeStorage.indexOf('(', ')'))
//   const [navigations, setNavigations] = useState(menuItems);
//   const [navigationsLoading, setNavigationsLoading] = useState(true);

//   const permissions = useSelector((state) => state.permissions.permissions);

//   useEffect(() => {
//     setNavigations(menuItems);
//   }, [menuItems, permissions])

//   useEffect(() => {
//     if (permissions.length > 0)
//       navigations.map((parent, parentIndex) => {
//         if (parent.permission !== undefined) {
//           let parentAccess = false;
//           if (Array.isArray(parent.permission)) {
//             parent.permission.map(x => {
//               if (permissions.includes(x)) {
//                 parentAccess = true;
//                 return;
//               }
//             })
//           }
//           else
//             parentAccess = permissions.includes(parent.permission);
//           if (!parentAccess)
//             setNavigations(navigations.filter(item => item !== parent));
//         }
//         if (parent.items !== undefined)
//           parent.items.map((childItem, childIndex) => {
//             if (childItem.permission !== undefined) {
//               let childAccess = permissions.includes(childItem.permission);
//               if (!childAccess) {
//                 const newNavigations = [...navigations];
//                 const newNestedArray = [...newNavigations[parentIndex].items];
//                 newNestedArray.splice(childIndex, 1);
//                 newNavigations[parentIndex] = { ...newNavigations[parentIndex], items: newNestedArray };
//                 setNavigations(newNavigations);
//               }
//             }
//           })
//           setNavigationsLoading(false)
//       })
//   }, [navigations, permissions])

//   if (navigationsLoading)
//     return <CSidebar
//       position="fixed"
//       unfoldable={unfoldable}
//       visible={sidebarShow}
//       onHide={(visible) => {
//         // if (visible == undefined)
//         //   dispatch({ type: 'set', isShow: false })
//         // else
//         // if (sidebarShow) dispatch({ type: 'set', isShow: false })
//         // if (!sidebarShow) dispatch({ type: 'set', isShow: true })
//         dispatch({ type: 'set', isShow: visible })
//       }}
//     >
//       {/* <CSidebarBrand className="d-none d-md-flex titlestyle" to="/"> */}
//       <CSidebarBrand className="d-md-flex titlestyle" to="/">
//         {/* <CIcon className="sidebar-brand-full" icon={logoNegative} height={35} /> */}
//         <img
//           style={{
//             borderRadius: 150 / 2,
//             overflow: 'hidden',
//             borderWidth: 3,
//           }}
//           width={50}
//           height={50}
//           src={LogoImg}
//         ></img>
//         <label style={{ marginRight: 5 }}>باشگاه کوهنوردی و اسکی دماوند</label>
//         <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
//       </CSidebarBrand>
//       <CSidebarNav>
//         <div
//           style={{
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//           }}
//         >
//           <img
//             style={{
//               borderRadius: 150 / 2,
//               overflow: 'hidden',
//               borderWidth: 3,
//               marginTop: 10,
//             }}
//             width={100}
//             height={100}
//             src={profilePhotoUrl}
//           ></img>
//           <label className="yellow">{fullName}</label>
//           <label className="yellow">{memberType}</label>
//           {roleType !== '()' ? <label className="yellow">{roleType}</label> : null}
//           <div className="horizontal-line"></div>
//         </div>
//         <SimpleBar>
//           <div style={{
//             display: 'flex',
//             justifyContent: 'center',
//             marginTop: 100
//           }}><Spin size="large" /> </div>
//         </SimpleBar>
//       </CSidebarNav>
//       <CSidebarToggler
//         className="d-none d-lg-flex"
//         onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
//       />
//     </CSidebar>

//   return (
//     <CSidebar
//       position="fixed"
//       unfoldable={unfoldable}
//       visible={sidebarShow}
//       onHide={(visible) => {
//         // if (visible == undefined)
//         //   dispatch({ type: 'set', isShow: false })
//         // else
//         // if (sidebarShow) dispatch({ type: 'set', isShow: false })
//         // if (!sidebarShow) dispatch({ type: 'set', isShow: true })
//         dispatch({ type: 'set', isShow: visible })
//       }}
//     >
//       {/* <CSidebarBrand className="d-none d-md-flex titlestyle" to="/"> */}
//       <CSidebarBrand className="d-md-flex titlestyle" to="/">
//         {/* <CIcon className="sidebar-brand-full" icon={logoNegative} height={35} /> */}
//         <img
//           style={{
//             borderRadius: 150 / 2,
//             overflow: 'hidden',
//             borderWidth: 3,
//           }}
//           width={50}
//           height={50}
//           src={LogoImg}
//         ></img>
//         <label style={{ marginRight: 5 }}>باشگاه کوهنوردی و اسکی دماوند</label>
//         <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
//       </CSidebarBrand>
//       <CSidebarNav>
//         <div
//           style={{
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//           }}
//         >
//           <img
//             style={{
//               borderRadius: 150 / 2,
//               overflow: 'hidden',
//               borderWidth: 3,
//               marginTop: 10,
//             }}
//             width={100}
//             height={100}
//             src={profilePhotoUrl}
//           ></img>
//           <label className="yellow">{fullName}</label>
//           <label className="yellow">{memberType}</label>
//           {roleType !== '()' ? <label className="yellow">{roleType}</label> : null}
//           <div className="horizontal-line"></div>
//         </div>
//         <SimpleBar>
//           <AppSidebarNav items={navigations} />
//         </SimpleBar>
//       </CSidebarNav>
//       <CSidebarToggler
//         className="d-none d-lg-flex"
//         onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
//       />
//     </CSidebar>
//   )
// }

// export default React.memo(AppSidebar)
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { AppSidebarNav } from './AppSidebarNav';
import { logoNegative } from 'src/assets/brand/logo-negative';
import { sygnet } from 'src/assets/brand/sygnet';
import LogoImg from 'src/assets/images/logo.jpg';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { Spin } from 'antd';
import menuItems from '../_nav';

const AppSidebar = () => {
  const dispatch = useDispatch();
  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.sidebar.isShow);
  const profilePhotoUrl = localStorage.getItem('damavandclub-profilephotourl');
  const fullName = localStorage.getItem('damavandclub-fullname');
  const memberTypeStorage = localStorage.getItem('damavandclub-membertype');
  const memberType = memberTypeStorage.substring(0, memberTypeStorage.indexOf('('));
  const roleType = memberTypeStorage.substring(50, memberTypeStorage.indexOf('(', ')'));
  const [navigations, setNavigations] = useState([]);
  const [navigationsLoading, setNavigationsLoading] = useState(true);
  const permissions = useSelector((state) => state.permissions.permissions);

  useEffect(() => {
    if (permissions.length > 0) {
      let filteredMenuItems = menuItems.filter((parent) => {
        if (parent.permission) {
          if (Array.isArray(parent.permission)) {
            return parent.permission.some((perm) => permissions.includes(perm));
          } else {
            return permissions.includes(parent.permission);
          }
        }
        return true;
      });

      filteredMenuItems = filteredMenuItems.map((parent) => {
        if (parent.items) {
          const filteredItems = parent.items.filter((childItem) => {
            if (childItem.permission) {
              return permissions.includes(childItem.permission);
            }
            return true;
          });
          return { ...parent, items: filteredItems };
        }
        return parent;
      });

      setNavigations(filteredMenuItems);
      setNavigationsLoading(false);
    }
  }, [permissions]);

  if (navigationsLoading) {
    return <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onHide={(visible) => {
        // if (visible == undefined)
        //   dispatch({ type: 'set', isShow: false })
        // else
        // if (sidebarShow) dispatch({ type: 'set', isShow: false })
        // if (!sidebarShow) dispatch({ type: 'set', isShow: true })
        dispatch({ type: 'set', isShow: visible })
      }}
    >
      {/* <CSidebarBrand className="d-none d-md-flex titlestyle" to="/"> */}
      <CSidebarBrand className="d-md-flex titlestyle" to="/">
        {/* <CIcon className="sidebar-brand-full" icon={logoNegative} height={35} /> */}
        <img
          style={{
            borderRadius: 150 / 2,
            overflow: 'hidden',
            borderWidth: 3,
          }}
          width={50}
          height={50}
          src={LogoImg}
        ></img>
        <label style={{ marginRight: 5 }}>باشگاه کوهنوردی و اسکی دماوند</label>
        <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
      </CSidebarBrand>
      <CSidebarNav>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            style={{
              borderRadius: 150 / 2,
              overflow: 'hidden',
              borderWidth: 3,
              marginTop: 10,
            }}
            width={100}
            height={100}
            src={profilePhotoUrl}
          ></img>
          <label className="yellow">{fullName}</label>
          <label className="yellow">{memberType}</label>
          {roleType !== '()' ? <label className="yellow">{roleType}</label> : null}
          <div className="horizontal-line"></div>
        </div>
        <SimpleBar>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 100
          }}><Spin size="large" /> </div>
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      />
    </CSidebar>
  }

  return (
    <CSidebar position="fixed" unfoldable={unfoldable} visible={sidebarShow}>
      <CSidebarBrand className="d-md-flex titlestyle" to="/">
        <img style={{ borderRadius: 150 / 2 }} width={50} height={50} src={LogoImg} />
        <label style={{ marginRight: 5 }}>باشگاه کوهنوردی و اسکی دماوند</label>
        <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
      </CSidebarBrand>
      <CSidebarNav>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            style={{
              borderRadius: 150 / 2,
              overflow: 'hidden',
              borderWidth: 3,
              marginTop: 10,
            }}
            width={100}
            height={100}
            src={profilePhotoUrl}
          />
          <label className="yellow">{fullName}</label>
          <label className="yellow">{memberType}</label>
          {roleType !== '()' ? <label className="yellow">{roleType}</label> : null}
          <div className="horizontal-line"></div>
        </div>
        <SimpleBar>
          <AppSidebarNav items={navigations} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      />
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
