import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const HideSidebarInMobileMode = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    if (window.innerWidth <= 768) {
      dispatch({ type: 'set', isShow: false })
    }
  }, [])

  return <></>
};

export default HideSidebarInMobileMode;
