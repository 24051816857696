import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Table, Pagination, Modal } from 'antd';
import { apiUrls } from '../apiUrls.js';
import { toast } from 'react-toastify';
import AmountFormat from "../components/AmountFormat.js";

const { Option } = Select;
const baseURL = apiUrls.baseURL;

const ProgramComments = React.memo(({ data, programKey }) => {

    const [isShowModalVisible, setIsShowModalVisible] = useState(false);
    const [dataComments, setDataComments] = useState([]);
    const [commentapi, setCommentapi] = useState('');

    useEffect(() => {
        getCommentapi()
    }, [])

    const getCommentapi = () => {
        switch (programKey) {
            case 'mountaineering':
                setCommentapi('GetMountaineeringProgramComments');
                break;
            case 'climbing':
                setCommentapi('GetClimbingProgramComments');
                break;
            case 'iceclimbing':
                setCommentapi('GetIceClimbingProgramComments');
                break;
            case 'trial':
                setCommentapi('GetTrialProgramComments');
                break;
            case 'caving':
                setCommentapi('GetCavingProgramComments');
                break;
            case 'bicycling':
                setCommentapi('GetBicyclingProgramComments');
                break;
            case 'canyoneering':
                setCommentapi('GetCanyoningProgramComments');
                break;
            case 'skyrunning':
                setCommentapi('GetSkyRunningProgramComments');
                break;
            case 'education':
                setCommentapi('GetEducationProgramComments');
                break;
        }
    }

    const getComments = async () => {
        setIsShowModalVisible(true);
        try {
            const token = localStorage.getItem('damavandclub-token')
            let response = await axios.get(`${apiUrls.baseURL}/program/${commentapi}?id=${data.id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setDataComments(response.data.data)
        } catch (error) {
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } finally {
        }
    };

    return (
        <>
            <Button size='small' onClick={() => getComments()}
                style={{ backgroundColor: 'rgba(1, 60, 11, 0.85)' }}
                type="primary"><label>نظرات</label></Button>

            <Modal
                title={`نظرات برنامه :  ${data.nameFa}`}
                open={isShowModalVisible}
                closable={false}
                width={window.innerWidth <= 768 ? '85%' : '50%'}
                okButtonProps={null}
                footer={
                    <Button onClick={() => setIsShowModalVisible(false)} style={{ width: '100%' }}>بستن</Button>
                }
                onCancel={() => setIsShowModalVisible(false)}
            >
                {dataComments.map((item, index) => {
                    return <div key={index}>
                        <div><span className='green'>{item.memberName}</span></div>
                        <div className='text-justify'><span>نظر فرد: {item.memberComment}</span></div>
                        <div className='text-justify'><span>نظر سرپرست: {item.leaderComment}</span></div>
                        <div style={{ border: '1px dashed #000', width: '100%', margin: 'auto', marginTop: 3, marginBottom: 3 }}></div>
                    </div>
                })}
            </Modal>
        </>
    );
});

ProgramComments.propTypes = {
    data: PropTypes.any,
    programKey: PropTypes.string,
};

ProgramComments.displayName = 'ProgramComments';

export default ProgramComments;
