import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Table, Pagination, Modal } from 'antd';
import { apiUrls } from '../apiUrls.js';
import { toast } from 'react-toastify';
import AmountFormat from "../components/AmountFormat.js";
import { TeamOutlined, SolutionOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;
const baseURL = apiUrls.baseURL;

const ProgramOtherMembers = React.memo(({ data, color }) => {

  const navigate = useNavigate();
  const [isShowModalVisible, setIsShowModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [members, setMembers] = useState([]);
  const [loadingMembers, setLoadingMembers] = useState(false);

  useEffect(() => {

  }, [])


  const showMembers = async (id) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('damavandclub-token')
      let response = await axios.get(`${apiUrls.baseURL}/program/getOtherProgramMembers?id=${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      var result = response.data.data;
      setMembers(result);
      setIsShowModalVisible(true);
      setLoading(false);
    } catch (error) {
      if (error.response.status == 401)
        navigate('/');
      setLoadingMembers(false);
    } finally {
      setLoadingMembers(false);
    }
  };

  return (
    <>
      <Button onClick={() => showMembers(data.id)} style={{ backgroundColor: color, border: 'none' }}
        loading={loading}>{data.otherMembersCount}<TeamOutlined style={{ fontSize: 30 }} twoToneColor="#eb2f96"></TeamOutlined>
        <span >(مشاهده اعضای شرکت کننده)</span></Button>

      <Modal
        title={`اعضای برنامه ${data.nameFa}`}
        open={isShowModalVisible}
        closable={false}
        okButtonProps={null}
        footer={<button onClick={() => setIsShowModalVisible(false)} style={{ width: '100%' }}>بستن</button>}
        loading={loadingMembers}
      >
        {members.map((item, index) => {
          return <div style={{ marginTop: 5 }} key={index}>{index + 1}-{item.firstName} {item.lastName}({item.memberType}-{item.startMemberingDate})</div>
        })}
      </Modal>
    </>
  );
});

ProgramOtherMembers.propTypes = {
  data: PropTypes.any,
  color: PropTypes.any
};

ProgramOtherMembers.displayName = 'ProgramOtherMembers';

export default ProgramOtherMembers;
