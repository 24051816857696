import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { FilePdfOutlined, PictureOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Tabs, Upload, Select, Spin } from 'antd';
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import { apiUrls } from '../apiUrls.js';
import jquery from 'jquery';
import { UploadOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

const { Option } = Select;

const MemberSpecification = React.memo(({ data, setData, hideProfileUpload }) => {

    const [birthDay, setBirthDay] = useState(null);
    const [dropDownEducations, setDropDownEducations] = useState([]);
    const [dropDownGenders, setDropDownGenders] = useState([]);
    const [dropDownMaritalStatus, setDropDownMaritalStatus] = useState([]);
    const [dropDownBloodTypes, setDropDownBloodTypes] = useState([]);
    const [profilePhoto, setProfilePhoto] = useState('');
    const baseURL = apiUrls.baseURL;
    const permissions = useSelector((state) => state.permissions.permissions);

    const setSelectedBirthDay = async (date) => {
        setBirthDay({
            year: date.year,
            month: date.month,
            day: date.day
        });
        setData({ ...data, birthDay: `${date.year}/${date.month}/${date.day}` })
    }

    useEffect(() => {
        fetchEducations()
        fetchBloodTypes()
        fetchMaritalStatus()
        fetchGenders()
    }, [])

    const fetchEducations = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getEducationLevelDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })

            var result = response.data.data;
            setDropDownEducations(result);
        } catch (error) {
        } finally {
        }
    };
    const fetchBloodTypes = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getBloodTypeDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })
            var result = response.data.data;
            setDropDownBloodTypes(result);
        } catch (error) {
        } finally {
        }
    };
    const fetchMaritalStatus = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getMaritalStatusDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })
            var result = response.data.data;
            setDropDownMaritalStatus(result);
        } catch (error) {
        } finally {
        }
    };
    const fetchGenders = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getGenderDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })
            var result = response.data.data;
            setDropDownGenders(result);
        } catch (error) {
        } finally {
        }
    };

    const profilePhotoProps = {
        name: 'file',
        action: `${baseURL}/media/UploadFiles?containerId=0&userId=${data.id}&isPublic=true`, // Replace with your upload endpoint
        headers: {
            authorization: 'Bearer ' + localStorage.getItem('damavandclub-token'),
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                //console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                let mediaResponse = info.file.response.data[0];
                setProfilePhoto(mediaResponse.url)
                setData({ ...data, mediaId: mediaResponse.id })
                jquery(".ant-upload-list-text-container").remove();
            } else if (info.file.status === 'error') {
                return toast.warning(info.file.response.Msg, {
                    position: 'bottom-right',
                    autoClose: 3000, // milliseconds
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        },
    };

    useEffect(() => {
        if (data.birthDay !== null && data.birthDay !== undefined && data.birthDay !== "") {
            var resultBirthDay = data.birthDay.split('/');
            setBirthDay({
                year: parseInt(resultBirthDay[0], 10),
                month: parseInt(resultBirthDay[1], 10),
                day: parseInt(resultBirthDay[2], 10)
            })
        }
    }, [data])

    return (
        <>
            <label>نام</label>
            <Input value={data.firstName} onChange={(event) => setData({ ...data, firstName: event.target.value })} style={{ marginTop: 5 }} size="large" />
            <label className='margintop-7'>نام خانوادگی</label>
            <Input value={data.lastName} onChange={(event) => setData({ ...data, lastName: event.target.value })} style={{ marginTop: 5 }} size="large" />
            <label className='margintop-7'>کد ملی</label>
            <Input value={data.nationalCode} onChange={(event) => setData({ ...data, nationalCode: event.target.value })} style={{ marginTop: 5 }} size="large" />
            <label className='margintop-7'>تاریخ تولد</label>
            <div className='margintop-7 center-content customDatePickerWidth'>
                <DatePicker
                    value={birthDay}
                    //inputClassName='fullwidth'
                    onChange={(value) => setSelectedBirthDay(value)}
                    //minimumDate={persianToday}
                    //calendarClassName="fullwidth"
                    shouldHighlightWeekends
                    locale='fa'
                />
            </div>
            <label className='margintop-7'>نام کاربری(برای ورود به پورتال)</label>
            <Input value={data.userName} onChange={(event) => setData({ ...data, userName: event.target.value })} style={{ marginTop: 5 }} size="large" />
            <label className='margintop-7'>شماره موبایل</label>
            <Input value={data.phoneNumber} onChange={(event) => setData({ ...data, phoneNumber: event.target.value })} style={{ marginTop: 5 }} size="large" />
            <label className='margintop-7'>شماره تماس ضروری</label>
            <Input value={data.emergencyPhoneNumber} onChange={(event) => setData({ ...data, emergencyPhoneNumber: event.target.value })} style={{ marginTop: 5 }} size="large" />

            <label className='margintop-7'>شماره کارت بانکی برای بازگشت مبلغ کیف پول</label>
            <Input value={data.cardNumber} onChange={(event) => setData({ ...data, cardNumber: event.target.value })} style={{ marginTop: 5 }} size="large" />

            <label className='margintop-7'>شغل</label>
            <Input value={data.jobTitle} onChange={(event) => setData({ ...data, jobTitle: event.target.value })} style={{ marginTop: 5 }} size="large" />
            <label className='margintop-7'>تحصیلات</label>
            <Select
                style={{ width: '100%' }}
                placeholder="انتخاب سطح تحصیلات"
                onChange={(value) => setData({ ...data, educationLevel: value })}
                value={data.educationLevel}
            >
                {dropDownEducations?.map((item) => (
                    <Option key={item.id} value={item.id}>
                        {item.displayName}
                    </Option>
                ))}
            </Select>
            <label className='margintop-7'>وضعیت ازدواج</label>
            <Select
                style={{ width: '100%' }}
                placeholder="انتخاب وضعیت ازدواج"
                onChange={(value) => setData({ ...data, maritalStatus: value })}
                value={data.maritalStatus}
            >
                {dropDownMaritalStatus?.map((item) => (
                    <Option key={item.id} value={item.id}>
                        {item.displayName}
                    </Option>
                ))}
            </Select>
            <label className='margintop-7'>گروه خونی</label>
            <Select
                style={{ width: '100%' }}
                placeholder="انتخاب گروه خونی"
                onChange={(value) => setData({ ...data, bloodType: value })}
                value={data.bloodType}
            >
                {dropDownBloodTypes?.map((item) => (
                    <Option key={item.id} value={item.id}>
                        {item.displayName}
                    </Option>
                ))}
            </Select>
            <label className='margintop-7'>بیماری خاص</label>
            <Input value={data.specialDisease} onChange={(event) => setData({ ...data, specialDisease: event.target.value })} style={{ marginTop: 5 }} size="large" />
            <label className='margintop-7'>جنسیت</label>
            <Select
                style={{ width: '100%' }}
                placeholder="انتخاب جنسیت"
                onChange={(value) => setData({ ...data, gender: value })}
                value={data.gender}
            >
                {dropDownGenders?.map((item) => (
                    <Option key={item.id} value={item.id}>
                        {item.displayName}
                    </Option>
                ))}
            </Select>
            <label className='margintop-7'>آدرس</label>
            <Input value={data.address} onChange={(event) => setData({ ...data, address: event.target.value })} style={{ marginTop: 5 }} size="large" />
            <label className='margintop-7'>توضیحات</label>
            <textarea value={data.description} onChange={(event) => setData({ ...data, description: event.target.value })} style={{ marginTop: 5, width: '100%' }} />
            {!hideProfileUpload ? <Upload
                {...profilePhotoProps}
                //action={publicUploadPath()}
                style={{ width: 200 }}
            //listType="picture-card"
            // itemRender={(originNode, file) => (
            //   <DraggableUploadListItem originNode={originNode} file={file} />
            // )}
            //fileList={fileList}
            //onPreview={handlePreview}
            //onChange={handleChange}
            >
                <Button icon={<UploadOutlined />} style={{ backgroundColor: 'rgb(231 96 45)', marginTop: 10, marginBottom: 10 }}
                    type="primary" ><label>آپلود عکس پروفایل</label></Button>
                {/* <Button >Click to Upload</Button> */}
                {/* {fileList.length >= 8 ? null : uploadButton} */}
            </Upload> : <></>}
            <img
                style={{
                    //borderRadius: 150 / 2,
                    overflow: 'hidden',
                    borderWidth: 3,
                }}
                //width={300}
                //height={300}
                src={profilePhoto != "" ? profilePhoto : data.profilePhotoUrl}
            ></img>
            <br></br>
        </>
    );
});


MemberSpecification.propTypes = {
    data: PropTypes.any,
    setData: PropTypes.any,
    hideProfileUpload: PropTypes.bool
};

MemberSpecification.displayName = 'MemberSpecification';

export default MemberSpecification;
