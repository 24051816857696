import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react'
import {
  CChartBar,
  CChartDoughnut,
  CChartLine,
  CChartPie,
  CChartPolarArea,
  CChartRadar,
} from '@coreui/react-chartjs'
import { DocsCallout } from 'src/components'
import { toast } from 'react-toastify';
import { Button, Flex, Input, Upload, Select, Spin } from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import jquery from 'jquery';
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";
import { apiUrls } from '../../apiUrls.js';
import { useNavigate } from 'react-router-dom';
import MemberSpecification from "../../components/MemberSpecification.js";
import MemberCertificate from "../../components/MemberCertificate.js";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const { Option } = Select;

const Profile = () => {

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const baseURL = apiUrls.baseURL;
  const [renderedTabs, setRenderedTabs] = useState([0]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData()
  }, [])

  function callback(key) {

  }

  const save = async () => {
    setLoadingSave(true);
    try {
      const token = localStorage.getItem('damavandclub-token')
      await axios.post(`${baseURL}/user/update`, data, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      toast.success("تغییرات با موفقیت ذخیره شد", {
        position: 'bottom-right',
        autoClose: 3000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setLoadingSave(false);
    } catch (error) {
      toast.error(error.response.data.Msg, {
        position: 'bottom-right',
        autoClose: 6000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        //className: 'custom-toast',
        pauseOnHover: true,
        draggable: true,
      });
      setLoadingSave(false);
    } finally {
      setLoadingSave(false);
    }
  }

  const fetchData = async () => {
    setLoading(true);
    const token = localStorage.getItem('damavandclub-token')
    try {
      const response = await axios.get(`${baseURL}/user/getinfo`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });

      var result = response.data.data;

      setData(result);
      setLoading(false);
    } catch (error) {
      if (error.response.status == 401)
        navigate('/');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onSelect = useCallback((index) => {
    setRenderedTabs((renderedTabs) =>
      renderedTabs.includes(index)
        ? renderedTabs
        : renderedTabs.concat(index)
    )
  }, []);

  if (loading) {
    return <Spin size="large" />
  }

  return (
    <div className='row'>
      <HideSidebarInMobileMode />
      <div style={{
        backgroundColor: 'white',
        flexDirection: 'column',
        display: 'flex',
        flex: 1,
        height: '100%',
        padding: 10
      }}>
        <Button onClick={() => save()} loading={loadingSave} style={{ backgroundColor: 'rgb(0 151 46)', marginTop: 10, width: '100%', marginBottom: 10 }}
          type="primary"><label>ذخیره تمام تغییرات پروفایل</label></Button>

        <Tabs onSelect={onSelect}>

          <TabList>
            <Tab>مشخصات</Tab>
            <Tab>مدارک</Tab>
          </TabList>

          <TabPanel forceRender={renderedTabs.includes(0)}>
            <MemberSpecification data={data} setData={setData}></MemberSpecification>
            <label className='margintop-7'>تاریخ عضویت</label>
            <div style={{ border: '1px solid lightgray' }} className='margintop-7 center-content customDatePickerWidth'>
              <label>{data.startMemberingDate}</label>
            </div>
            {/* <label className='margintop-7'>تاریخ اعتبار بیمه ورزشی</label>
            <div style={{ border: '1px solid lightgray' }} className='margintop-7 center-content customDatePickerWidth'>
              <label>{data.insuranceExpiredDate}</label>
            </div> */}
          </TabPanel>

          <TabPanel forceRender={renderedTabs.includes(1)}>
            <MemberCertificate isShowButton={true} data={data} setData={setData}></MemberCertificate>
          </TabPanel>

        </Tabs>

      </div>
    </div >
  )
}

export default Profile
