import React, { useState, useEffect } from 'react';
import {
    CChartBar,
    CChartDoughnut,
    CChartLine,
    CChartPie,
    CChartPolarArea,
    CChartRadar,
} from '@coreui/react-chartjs'
import { Button, Flex, Input, Upload, Select, Spin } from 'antd';
import {
    CAvatar,
    CButton,
    CButtonGroup,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CProgress,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'
import { getStyle, hexToRgba } from '@coreui/utils'
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";
import CIcon from '@coreui/icons-react'
import axios from 'axios';
import { apiUrls } from '../../apiUrls.js';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const baseURL = apiUrls.baseURL;

const Report = () => {
    //const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)

    const [data, setData] = useState([]);
    const [memberProgramData, setMemberProgramData] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const permissions = useSelector((state) => state.permissions.permissions);

    useEffect(() => {
        //if (window.innerWidth <= 768) {
        dispatch({ type: 'set', isShow: true })
        //if(permissions.)
        fetchLeaderChart()
        fetchMemberChart()
        //}
    }, [])

    const fetchLeaderChart = async () => {

        setLoading(true)
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/GetBarChartLeaderMembersData`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;
            setData(result);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const fetchMemberChart = async () => {

        setLoading(true)
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/GetBarChartProgramMembersData`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;
            setMemberProgramData(result);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            //setLoading(false);
        } finally {
            //setLoading(false);
        }
    };

    if (loading) {
        return <Spin size="large" />
    }
    return (
        <div>
                <HideSidebarInMobileMode/>
            <CCol xs={12} xl={6}>
                <CCard className="mb-4">
                    <CCardHeader style={{ textAlign: 'center' }}>بیشترین برنامه های اجرا شده توسط سرپرستان</CCardHeader>
                    <CCardBody>
                        <CChartBar
                            data={{
                                labels: data?.members,
                                datasets: [
                                    {
                                        label: 'تعداد برنامه',
                                        backgroundColor: ['#f87979', 'f87974'],
                                        data: data?.counts,
                                    },
                                ],
                            }}
                            labels="months"
                        />
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol xs={12} xl={6}>
                <CCard className="mb-4">
                    <CCardHeader style={{ textAlign: 'center' }}>اعضایی که بیشترین برنامه ها را شرکت کرده اند</CCardHeader>
                    <CCardBody>
                        <CChartBar
                            data={{
                                labels: memberProgramData?.members,
                                datasets: [
                                    {
                                        label: 'تعداد برنامه',
                                        backgroundColor: ['#3498db', '#07bc0c','#f1c40f'],
                                        data: memberProgramData?.counts,
                                    },
                                ],
                            }}
                            labels="months"
                        />
                    </CCardBody>
                </CCard>
            </CCol>
        </div>
    )
}

export default Report
