import { toast } from 'react-toastify';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox, Pagination, Table, Collapse, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { apiUrls } from '../../apiUrls.js';
import { useNavigate } from 'react-router-dom';
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";

const { Option } = Select;

const baseURL = apiUrls.baseURL;

const MessageList = () => {

    const [data, setData] = useState([]);
    const [messageModel, setMessageModel] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [count, setCount] = useState(0);
    const [filter, setFilter] = useState({ skip: 0, limit: 20, data: {} });
    const [dropDownUserType, setDropDownUserTypes] = useState([]);
    const [dropDownUser, setDropDownUsers] = useState([]);
    const [isShowCreateUserMessageModalVisible, setIsShowCreateUserMessageModalVisible] = useState(false);
    const [isShowCreateGroupMessageModalVisible, setIsShowCreateGroupMessageModalVisible] = useState(false);
    const permissions = useSelector((state) => state.permissions.permissions);
    const navigate = useNavigate();

    const columns = [
        {
            title: 'ردیف',
            dataIndex: 'index',
            key: 'index',
            responsive: ['xl', 'lg'],
            width: 50,
            render: (_, record, index) => (
                <>{index + 1 + filter?.limit * filter?.skip}</>
            ),
        },
        {
            title: 'گیرنده',
            key: 'userFullName',
            dataIndex: 'userFullName',
            width: 100,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs']
        },
        {
            title: 'شماره',
            key: 'phoneNumber',
            dataIndex: 'phoneNumber',
            width: 10,
            responsive: ['xl', 'lg', 'md'],
        },
        {
            title: 'زمان ارسال',
            key: 'persianCreateDate',
            dataIndex: 'persianCreateDate',
            width: 10,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
        },
        {
            title: 'متن پیام',
            key: 'message',
            dataIndex: 'message',
            width: 100,
            responsive: ['xl', 'lg', 'md'],
        },
        {
            title: 'وضعیت ارسال',
            key: 'sendResult',
            dataIndex: 'sendResult',
            width: 30,
            responsive: ['xl', 'lg', 'md']
        },
    ];

    useEffect(() => {
        setLoading(true)
        fetchData()
    }, [filter.limit, filter.skip])

    const fetchData = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.post(`${baseURL}/Message/get`, filter, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;
            result.items.map(item => {
                item.key = item.id
            })
            setCount(result.count)
            setData(result.items);
            setLoading(false);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const fetchUsers = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })

            var result = response.data.data;
            setDropDownUsers(result);
        } catch (error) {
        } finally {
        }
    };

    const fetchUserType = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getTypeDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })
            var result = response.data.data;
            setDropDownUserTypes(result);
        } catch (error) {
        } finally {
        }
    };

    const createUserMessage = async () => {

        if (messageModel.message == null || messageModel.message == '' || messageModel.userIds == null)
            return toast.warning('مقادیر انتخاب نشده اند', {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });

        setLoadingSave(true);
        const token = localStorage.getItem('damavandclub-token')
        try {
            await axios.post(`${baseURL}/message/sendUsersSms`, messageModel, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            toast.success('با موفقیت ارسال شد', {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
            fetchData()
            setLoadingSave(false);
            setIsShowCreateUserMessageModalVisible(false)
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } finally {
            setLoadingSave(false);
        }
    };

    const createGroupMessage = async () => {

        if (messageModel.message == null || messageModel.message == '' || messageModel.memberType == null)
            return toast.warning('مقادیر انتخاب نشده اند', {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });

        setLoadingSave(true);
        const token = localStorage.getItem('damavandclub-token')
        try {
            await axios.post(`${baseURL}/message/sendMemberTypeSms`, messageModel, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            toast.success('با موفقیت ارسال شد', {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
            fetchData()
            setLoadingSave(false);
            setIsShowCreateGroupMessageModalVisible(false)
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } finally {
            setLoadingSave(false);
        }
    };

    const showCreateUserMessageModal = async () => {
        fetchUsers()
        setMessageModel({ message: '' })
        setIsShowCreateUserMessageModalVisible(true)
    };

    const showCreateGroupMessageModal = async () => {
        fetchUserType()
        setMessageModel({ message: '' })
        setIsShowCreateGroupMessageModalVisible(true)
    };

    return (
        <div>
            <HideSidebarInMobileMode />

            <div className='row-container'>

                {permissions.includes('sendmessage') ? <Button onClick={() => showCreateUserMessageModal()}
                    style={{ backgroundColor: 'rgb(44 0 151)', marginTop: 10, width: '100%', marginBottom: 10 }}
                    type="primary"><label>ارسال پیام بصورت فردی</label></Button> : null}
                {permissions.includes('sendmessage') ? <Button onClick={() => showCreateGroupMessageModal()}
                    style={{ backgroundColor: 'rgb(44 0 151)', marginTop: 10, marginRight: 10, width: '100%', marginBottom: 10 }}
                    type="primary"><label>ارسال پیام بصورت گروهی</label></Button> : null}
            </div>

            <Table
                size='small'
                style={{
                    textAlign: 'right',
                    marginTop: 10
                }}
                rowClassName={(record) => record.sendResultCode == 0 ? 'greenrow' : 'redrow'}
                columns={columns}
                bordered={true}
                dataSource={data}
                loading={loading}
                pagination={false}
                expandable={{
                    expandedRowRender: (record) => <div style={{ textAlign: 'right' }}>
                        <div>متن پیام: {record.message}</div>
                        <div>ارسال کننده: {record.creatorName}</div>
                        <div>شماره: {record.phoneNumber}</div>
                    </div>
                    //rowExpandable: (record) => record.name !== 'Not Expandable',
                }}
                locale={{
                    emptyText: 'موردی یافت نشد',
                }}
            />
            <Pagination
                onChange={(pageNumber, limit) => setFilter({ skip: pageNumber > 0 ? pageNumber - 1 : pageNumber, limit: limit })}
                style={{ marginTop: 30, marginBottom: 5 }}
                current={filter.skip + 1}
                responsive={true}
                pageSize={filter.limit}
                showTotal={(total) => `تعداد کل: ${total} پیام`}
                locale={{ items_per_page: '' }}
                total={count} />
            <Modal
                title={`ارسال پیام بصورت فردی`}
                open={isShowCreateUserMessageModalVisible}
                width={1000}
                closable={false}
                okButtonProps={null}
                footer={<div className='row-container'>
                    <Button onClick={() => {
                        setMessageModel({})
                        setIsShowCreateUserMessageModalVisible(false)
                    }} style={{ width: '100%' }}>بستن</Button>
                    <Button type="primary" loading={loadingSave}
                        onClick={() => createUserMessage()}
                        style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(10, 23, 43)' }}>ذخیره</Button>
                </div>}
            >
                <Select
                    style={{ width: '100%', marginTop: 10 }}
                    placeholder="انتخاب عضو(ها)"
                    mode="multiple"
                    optionFilterProp="label"
                    showSearch
                    onChange={(value) => setMessageModel({ ...messageModel, userIds: value })}
                    value={messageModel.userIds}
                >
                    {dropDownUser?.map((item) => (
                        <Option key={item.id} label={item.displayName} value={item.id}>
                            {item.displayName}
                        </Option>
                    ))}
                </Select>
                <textarea
                    placeholder='متن پیام'
                    value={messageModel.message}
                    onChange={(event) => setMessageModel({ ...messageModel, message: event.target.value })}
                    style={{ marginTop: 10, height: 200, width: '100%' }} />
            </Modal>
            <Modal
                title={`ارسال پیام بصورت گروهی`}
                open={isShowCreateGroupMessageModalVisible}
                width={1000}
                closable={false}
                okButtonProps={null}
                footer={<div className='row-container'>
                    <Button onClick={() => {
                        setMessageModel({})
                        setIsShowCreateGroupMessageModalVisible(false)
                    }} style={{ width: '100%' }}>بستن</Button>
                    <Button type="primary" loading={loadingSave}
                        onClick={() => createGroupMessage()}
                        style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(10, 23, 43)' }}>ذخیره</Button>
                </div>}
            >

                <Select
                    style={{ width: '100%', marginTop: 10 }}
                    placeholder="انتخاب نوع عضویت"
                    onChange={(value) => setMessageModel({ ...messageModel, memberType: value })}
                    value={messageModel.memberType}
                >
                    {dropDownUserType?.map((item) => (
                        <Option key={item.id} value={item.id}>
                            {item.displayName}
                        </Option>
                    ))}
                </Select>
                <textarea
                    placeholder='متن پیام'
                    value={messageModel.message}
                    onChange={(event) => setMessageModel({ ...messageModel, message: event.target.value })}
                    style={{ marginTop: 10, height: 200, width: '100%' }} />
            </Modal>
        </div>
    )
}

export default MessageList
