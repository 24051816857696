import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react'
import {
    CChartBar,
    CChartDoughnut,
    CChartLine,
    CChartPie,
    CChartPolarArea,
    CChartRadar,
} from '@coreui/react-chartjs'
import { DocsCallout } from 'src/components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Flex, Input, Upload, Select, Spin, Checkbox } from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import jquery from 'jquery';
import BraftEditor from 'braft-editor';
import { FilePdfOutlined } from '@ant-design/icons';
import { apiUrls } from '../../../apiUrls.js';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
const baseURL = apiUrls.baseURL;
const { Option } = Select;

const PageForm = () => {

    const [data, setData] = useState({ medias: [] });
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [deadLineDate, setDeadLineDate] = useState(null);
    const [dropDownDiscountTypes, setDropDownDiscountType] = useState([]);
    const [dropDownWorkingGroup, setDropDownWorkingGroup] = useState([]);
    const [dropDownRequirements, setDropDownRequirements] = useState([]);
    const [dropDownLevel, setDropDownLevel] = useState([]);
    const [dropDownType, setDropDownType] = useState([]);
    const [dropDownLeader, setDropDownLeaders] = useState([]);
    const permissions = useSelector((state) => state.permissions.permissions);
    const [renderedTabs, setRenderedTabs] = useState([0]);
    //eslint-disable-next-line react-hooks/rules-of-hooks  
    const { id } = useParams();

    const [editorState, setEditorState] = useState(
        BraftEditor.createEditorState(null)
    );

    const handleEditorChange = (content) => {
        const modifiedContent = content.toHTML().replace(/<figure/g, '<img').replace(/\/>/g, '></img>');
        setEditorState(modifiedContent);
        setData({ ...data, contentFa: modifiedContent })
    };

    const onSelect = useCallback((index) => {
        setRenderedTabs((renderedTabs) =>
            renderedTabs.includes(index)
                ? renderedTabs
                : renderedTabs.concat(index)
        )
    }, []);

    useEffect(() => {
        if (id !== undefined)
            fetchData(id)
        fetchType()
    }, [])

    const fetchData = async (id) => {
        setLoading(true);
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/sitepage/getbyid?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            var result = response.data.data;

            if (result.startDate != null) {
                var resultStartDate = result.startDate.split('/');
                setStartDate({
                    year: parseInt(resultStartDate[0], 10),
                    month: parseInt(resultStartDate[1], 10),
                    day: parseInt(resultStartDate[2], 10)
                });
            }
            if (result.endDate != null) {
                var resultEndDate = result.endDate.split('/');
                setEndDate({
                    year: parseInt(resultEndDate[0], 10),
                    month: parseInt(resultEndDate[1], 10),
                    day: parseInt(resultEndDate[2], 10)
                });
            }
            if (result.deadlineDate != null) {
                var resultDeadlineDate = result.deadlineDate.split('/');
                setDeadLineDate({
                    year: parseInt(resultDeadlineDate[0], 10),
                    month: parseInt(resultDeadlineDate[1], 10),
                    day: parseInt(resultDeadlineDate[2], 10)
                });
            }
            setEditorState(BraftEditor.createEditorState(result.contentFa));
            setData(result);
            setLoading(false);
        } catch (error) {
            //alert(error)
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const fetchType = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/sitepage/getSitePagekeyDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })
            var result = response.data.data;
            setDropDownType(result);
        } catch (error) {
        } finally {
        }
    };

    const mediasProps = {
        name: 'file',
        action: `${baseURL}/media/UploadFiles?containerkey=sitepage&isPublic=true`, // Replace with your upload endpoint
        headers: {
            authorization: 'Bearer ' + localStorage.getItem('damavandclub-token'),
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                //console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                let response = info.file.response.data[0];
                let newMedia = { url: response.url, id: response.id };
                const newState = { ...data };
                const newMedias = [...data.medias, newMedia];
                newState.medias = newMedias;
                setData(newState)
                jquery(".ant-upload-list-text-container").remove();
            } else if (info.file.status === 'error') {
                return toast.warning(info.file.response.Msg, {
                    position: 'bottom-right',
                    autoClose: 3000, // milliseconds
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  });
            }
        },
    };

    const removeMedia = (mediaId) => {
        const newState = { ...data };
        var filterMedias = data.medias.filter(item => item.id != mediaId);
        newState.medias = filterMedias;
        setData(newState)
    };

    const save = async () => {
        setLoadingSave(true);
        try {
            const token = localStorage.getItem('damavandclub-token')

            if (id !== undefined) {
                await axios.post(`${baseURL}/sitepage/update?id=${id}`, data, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                })
                await axios.post(`${baseURL}/sitepage/updatemedias?id=${id}&key=SitePage`, data.medias.map(x => x.id), {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                })
            }

            else {
                let response = await axios.post(`${baseURL}/sitepage/create`, data, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                })
                await axios.post(`${baseURL}/sitepage/createmedias?id=${response.data.data}&key=SitePage`, data.medias.map(x => x.id), {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                })
            }

            toast.success("تغییرات با موفقیت ذخیره شد", {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } catch (error) {
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } finally {
            setLoadingSave(false);
        }
    }

    if (loading) {
        return <Spin size="large" />
    }

    return (
        <div className='row'>
            <div style={{
                backgroundColor: 'white',
                flexDirection: 'column',
                display: 'flex',
                flex: 1,
                height: '100%',
                padding: 10
            }}>
                <Button onClick={() => save()} loading={loadingSave} style={{ backgroundColor: 'rgb(0 151 46)', marginTop: 10, width: '100%', marginBottom: 10 }}
                    type="primary"><label>ذخیره یا ویرایش صفحه</label></Button>

                <Tabs onSelect={onSelect}>
                    <TabList>
                        <Tab>فارسی</Tab>
                        <Tab>انگلیسی</Tab>
                        <Tab>تصاویر</Tab>
                        <Tab>محتوای صفحه</Tab>
                    </TabList>

                    <TabPanel forceRender={renderedTabs.includes(0)}>
                        <label>عنوان صفحه</label>
                        <Input value={data.nameFa} onChange={(event) => setData({ ...data, nameFa: event.target.value })} style={{ marginTop: 5 }} size="large" />


                        <label className='margintop-7'>کلید دسته بندی صفحه</label> <Select
                            style={{ width: '100%' }}
                            placeholder="انتخاب دسته بندی"
                            onChange={(value) => setData({ ...data, key: value })}
                            value={data.key}
                        >
                            {dropDownType?.map((item) => (
                                <Option key={item.id} value={item.id}>
                                    {item.displayName}
                                </Option>
                            ))}
                        </Select>






                        <label className='margintop-7'>توضیحات</label>
                        <textarea value={data.descriptionFa} onChange={(event) => setData({ ...data, descriptionFa: event.target.value })}
                            style={{
                                marginTop: 5,
                                resize: 'none',
                                width: '100%',
                                minHeight: 300
                            }} />
                        <label className='margintop-7'>سئو</label>
                        <Input style={{ marginTop: 5 }} value={data.seoContent} onChange={(event) => setData({ ...data, seoContent: event.target.value })} size="large" />
                        <Checkbox checked={data.isActive} onChange={() => setData({ ...data, isActive: !data.isActive })}>فعال/غیرفعال</Checkbox>
                    </TabPanel>

                    <TabPanel forceRender={renderedTabs.includes(1)}>
                        <label>عنوان برنامه</label>
                        <Input value={data.nameEn} onChange={(event) => setData({ ...data, nameEn: event.target.value })} style={{ marginTop: 5 }} size="large" />
                        <label className='margintop-7'>توضیحات</label>
                        <textarea value={data.descriptionEn} onChange={(event) => setData({ ...data, descriptionEn: event.target.value })}
                            style={{
                                marginTop: 5,
                                resize: 'none',
                                width: '100%',
                                height: 300
                            }} />
                    </TabPanel>

                    <TabPanel forceRender={renderedTabs.includes(2)}>
                        <Upload
                            {...mediasProps}
                        >
                            <Button icon={<UploadOutlined />} loading={loadingSave} style={{ backgroundColor: 'rgb(0 20 46)', marginTop: 10, width: '100%', marginBottom: 10 }}
                                type="primary" ><label>افزودن تصویر</label></Button>
                        </Upload>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                            {data.medias?.map((media, index) => {
                                // eslint-disable-next-line jsx-a11y/alt-text
                                return <div style={{ display: 'flex', flexDirection: 'column' }} key={index}>
                                    <a rel='noreferrer' target='_blank' href={media.url}><img className='margintop-7' style={{ height: 200, width: 200 }} src={media.url}></img></a>
                                    <Button className='margintop-5' onClick={() => removeMedia(media.id)} type='danger'>حذف</Button>
                                </div>
                            })}
                        </div>
                    </TabPanel>

                    <TabPanel forceRender={renderedTabs.includes(3)}>
                        <BraftEditor
                            language='en'
                            
                            value={editorState}
                            onChange={handleEditorChange}
                        />
                    </TabPanel>

                </Tabs>


            </div>
        </div >
    )
}

export default PageForm
