import React, { useState, useEffect } from 'react';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox, Pagination, Table, Collapse } from 'antd';
import { getStyle, hexToRgba } from '@coreui/utils'
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";
import CIcon from '@coreui/icons-react'
import axios from 'axios';
import { apiUrls } from '../../apiUrls.js';
import { useNavigate } from 'react-router-dom';
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';

const { Option } = Select;
const baseURL = apiUrls.baseURL;

const WorkingGroupFinancialReport = () => {
    //const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [count, setCount] = useState(0);
    const [filter, setFilter] = useState({ skip: 0, limit: 10, data: {} });
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dropDownWorkingGroup, setDropDownWorkingGroup] = useState([]);
    const navigate = useNavigate();

    const columns = [
        {
            title: 'ردیف',
            dataIndex: 'index',
            key: 'index',
            responsive: ['xl', 'lg'],
            width: 50,
            render: (_, record, index) => (
                <>{index + 1 + filter?.limit * filter?.skip}</>
            ),
        },
        {
            title: 'کارگروه',
            key: 'workingGroupName',
            dataIndex: 'workingGroupName',
            width: 100,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs']
        },
        {
            title: 'نام مدیر وقت',
            key: 'adminName',
            dataIndex: 'adminName',
            width: 10,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs']
        },
        {
            title: 'تعداد برنامه های اجرا شده',
            key: 'programCount',
            dataIndex: 'programCount',
            width: 100,
            responsive: ['xl', 'lg', 'md']
        },
        {
            title: 'تعداد کل نفرات برنامه ها',
            key: 'totalMember',
            dataIndex: 'totalMember',
            width: 100,
            responsive: ['xl', 'lg', 'md']
        },
        {
            title: 'کل هزینه برنامه ها',
            key: 'totalAmount',
            dataIndex: 'totalAmount',
            width: 100,
            responsive: ['xl', 'lg', 'md']
        },
        {
            title: 'کل تخفیف',
            key: 'totalDiscount',
            dataIndex: 'totalDiscount',
            width: 100,
            responsive: ['xl', 'lg', 'md']
        },
        {
            title: 'کل جریمه کنسلی',
            key: 'totalCancelPenalty',
            dataIndex: 'totalCancelPenalty',
            width: 100,
            responsive: ['xl', 'lg', 'md']
        },
        {
            title: 'کل هزینه بیمه',
            key: 'totalInsurance',
            dataIndex: 'totalInsurance',
            width: 100,
            responsive: ['xl', 'lg', 'md']
        },
        {
            title: 'کل دستمزد سرپرستان',
            key: 'totalLeaderAmount',
            dataIndex: 'totalLeaderAmount',
            width: 100,
            responsive: ['xl', 'lg', 'md']
        },
        {
            title: 'سایر هزینه ها',
            key: 'totalExtraAmount',
            dataIndex: 'totalExtraAmount',
            width: 100,
            responsive: ['xl', 'lg', 'md']
        },
        {
            title: 'کل هزینه پرداخت شده',
            key: 'totalPaidAmount',
            dataIndex: 'totalPaidAmount',
            width: 100,
            responsive: ['xl', 'lg', 'md']
        },
        {
            title: 'درآمد باشگاه',
            key: 'clubIncome',
            dataIndex: 'clubIncome',
            width: 100,
            responsive: ['xl', 'lg', 'md']
        },
    ];

    useEffect(() => {
        fetchWorkingGroup();
    }, [reload])

    const fetchWorkingGroup = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/workingGroupCategory/getDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })

            var result = response.data.data;
            setDropDownWorkingGroup(result);
        } catch (error) {
        } finally {
        }
    };

    const setSelectedStartDate = async (date) => {
        setStartDate({
            year: date.year,
            month: date.month,
            day: date.day
        });
        setFilter({ ...filter, data: { ...filter.data, fromDate: `${date.year}/${date.month}/${date.day}` } })
    }

    const setSelectedEndDate = async (date) => {
        setEndDate({
            year: date.year,
            month: date.month,
            day: date.day
        });
        setFilter({ ...filter, data: { ...filter.data, toDate: `${date.year}/${date.month}/${date.day}` } })
    }

    const fetchData = async () => {
        setLoading(true)
        const token = localStorage.getItem('damavandclub-token')
        try {
            let filterQueryString = filter.data.id != undefined && `id=${filter.data.id}`;
            if (filter.data.fromDate != undefined) filterQueryString += `&fromDate=${filter.data.fromDate }`;
            if (filter.data.toDate != undefined) filterQueryString += `&toDate=${filter.data.toDate}`;

            const response = await axios.get(`${baseURL}/workinggroupcategory/getfinancialreport?${filterQueryString}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;
            result.items.map(item => {
                item.key = item.id
            })
            setCount(result.count)
            setData(result.items);
            setLoading(false);
            setReload(false)
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
            setReload(false)
        } finally {
            setLoading(false);
            setReload(false)
        }
    };

    // const getExcel = async () => {

    //     setLoadingExcel(true)
    //     const token = localStorage.getItem('damavandclub-token')
    //     try {
    //         const response = await axios.post(`${baseURL}/user/getMembersExcelReport`, filter, {
    //             headers: {
    //                 'Authorization': `Bearer ${token}`,
    //             }, 'responseType': 'blob'
    //         });
    //         const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //         const url = window.URL.createObjectURL(blob);
    //         window.open(url, '_blank');

    //         setLoadingExcel(false);
    //     } catch (error) {
    //         if (error.response.status == 401)
    //             navigate('/');
    //         setLoadingExcel(false);
    //     } finally {
    //         setLoadingExcel(false);
    //     }
    // };

    return (
        <div>
            <HideSidebarInMobileMode />
            <Collapse defaultActiveKey={['0']}>
                <Collapse.Panel header='فیلتر' key='1'>

                    <div className='row'>

                        <div className='col-lg-4 col-sm-12'>
                            <label>کارگروه</label>
                            <Select
                                style={{ width: '100%' }}
                                placeholder="انتخاب گارگروه"
                                onChange={(value) => {
                                    setFilter({ ...filter, data: { ...filter.data, id: value } })
                                }}
                                value={data.workingGroupCategoryId}
                            >
                                {dropDownWorkingGroup?.map((item) => (
                                    <Option key={item.id} value={item.id}>
                                        {item.displayName}
                                    </Option>
                                ))}
                            </Select>
                        </div>

                        <div className='col-lg-4 col-sm-12'>
                            <label>از تاریخ</label>
                            <div className='center-content customDatePickerWidth'>
                                <DatePicker
                                    value={startDate || null}
                                    //inputClassName='fullwidth'
                                    onChange={(value) => setSelectedStartDate(value)}
                                    //minimumDate={persianToday}
                                    //calendarClassName="fullwidth"
                                    shouldHighlightWeekends
                                    locale='fa'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4 col-sm-12'>
                            <label>تا تاریخ</label>
                            <div className='center-content customDatePickerWidth'>
                                <DatePicker
                                    value={endDate || null}
                                    //inputClassName='fullwidth'
                                    onChange={(value) => setSelectedEndDate(value)}
                                    //minimumDate={persianToday}
                                    //calendarClassName="fullwidth"
                                    shouldHighlightWeekends
                                    locale='fa'
                                />
                            </div>
                        </div>

                    </div>

                    <Button onClick={() => fetchData()} loading={loading} style={{ backgroundColor: 'rgb(0 39 151)', marginTop: 10, width: '100%', marginBottom: 10 }}
                        type="primary"><label>گزارش</label></Button>
                    {/* <Button onClick={() => getExcel()} loading={loadingExcel} style={{ backgroundColor: 'rgb(229 131 27)', marginTop: 10, width: '100%', marginBottom: 10 }}
                        type="primary"><label>خروجی اکسل</label></Button> */}
                </Collapse.Panel>
            </Collapse>

            <Table
                size='small'
                style={{
                    textAlign: 'right',
                    marginTop: 10
                }}
                rowClassName={() => 'center'}
                columns={columns}
                bordered={true}
                dataSource={data}
                loading={loading}
                pagination={false}
                // expandable={{
                //     expandedRowRender: (record) => <div style={{ textAlign: 'right' }}>
                //         <div>آخرین ورود: {record.lastLoginDate}</div>
                //     </div>
                //     //rowExpandable: (record) => record.name !== 'Not Expandable',
                // }}
                locale={{
                    emptyText: 'موردی یافت نشد',
                }}
            />
            <Pagination
                onChange={(pageNumber, limit) => {
                    setFilter({ ...filter, skip: pageNumber > 0 ? pageNumber - 1 : pageNumber, limit: limit })
                    setReload(true)
                }}
                style={{ marginTop: 30, marginBottom: 5 }}
                current={filter.skip + 1}
                //current={10}
                responsive={true}
                defaultPageSize={filter.limit}
                locale={{ items_per_page: '' }}
                showTotal={(total) => `تعداد کل: ${total} ردیف`}
                showSizeChanger={true}
                total={count} />
        </div>
    )
}

export default WorkingGroupFinancialReport
