import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Table, Pagination, Modal } from 'antd';
import { apiUrls } from '../apiUrls.js';
import { toast } from 'react-toastify';
import { TeamOutlined, SolutionOutlined } from '@ant-design/icons';
import AmountFormat from "../components/AmountFormat.js";
import { useNavigate } from 'react-router-dom';

const { Option } = Select;
const baseURL = apiUrls.baseURL;

const ProgramManageMembers = React.memo(({ data, onRefresh, color }) => {

    const navigate = useNavigate();
    const [isShowModalVisible, setIsShowModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [manageMembers, setManageMembers] = useState([]);
    const [photos, setPhotos] = useState([]);
    const [loadingInsuranceReport, setLoadingInsuranceReport] = useState(false);
    const [loadingAbsentPresent, setLoadingAbsentPresent] = useState(false);
    const [isShowRejectReasonModalVisible, setIsShowRejectReasonModalVisible] = useState(false);
    const [loadingManageMembers, setLoadingManageMembers] = useState(false);
    const [selectedUserIdForReject, setSelectedUserIdForReject] = useState(false);
    const [selectedNameForReject, setSelectedNameForReject] = useState('');
    const [dropDownRejectReason, setDropDownRejectReason] = useState([]);
    const [isShowManageMembersModalVisible, setIsShowManageMembersModalVisible] = useState(false);
    const [rejectModel, setRejectModel] = useState({});
    const [pdfUrl, setPdfUrl] = useState(null);

    useEffect(() => {

    }, [])

    const showManageMembers = async (id) => {
        setLoadingManageMembers(true);
        try {
            const token = localStorage.getItem('damavandclub-token')
            let response = await axios.get(`${apiUrls.baseURL}/program/getProgramMembers?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            var result = response.data.data;
            setManageMembers(result);
            setIsShowManageMembersModalVisible(true);
            setLoadingManageMembers(false);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoadingManageMembers(false);
        } finally {
            setLoadingManageMembers(false);
        }
    };

    const fetchRejectReason = async () => {
        setIsShowRejectReasonModalVisible(true);
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${apiUrls.baseURL}/program/getRejectReasonDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })

            var result = response.data.data;
            setDropDownRejectReason(result);
        } catch (error) {
        } finally {
        }
    };

    const rejectMember = async () => {
        setLoadingAbsentPresent(true);
        try {
            const token = localStorage.getItem('damavandclub-token')
            await axios.post(`${apiUrls.baseURL}/program/rejectmember?id=${data.id}`, rejectModel, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            toast.success("با موفقیت ثبت شد", {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });

            setLoadingAbsentPresent(false);
            setIsShowManageMembersModalVisible(false);
            setIsShowRejectReasonModalVisible(false);
            onRefresh();
        } catch (error) {
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingAbsentPresent(false);
        } finally {
            setLoadingAbsentPresent(false);
        }
    };

    const setAbsetPresent = (userId) => {
        const updatedItems = manageMembers.map(item => {
            if (item.userId === userId)
                return { ...item, isPresent: !item.isPresent }
            return item;
        })
        setManageMembers(updatedItems);
    }

    const setComment = (userId, comment) => {
        const updatedItems = manageMembers.map(item => {
            if (item.userId === userId)
                return { ...item, leaderComment: comment }
            return item;
        })
        setManageMembers(updatedItems);
    }

    const insuranceReport = async (id) => {
        setLoadingInsuranceReport(true)
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${apiUrls.baseURL}/program/getProgramMembersReport?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }, 'responseType': 'blob'
            });

            // toast.success("اگر در حال استفاده از گوشی هستید فایل بیمه در قسمت دانلودهای گوشی شما ذخیره شده است", {
            //     position: 'bottom-right',
            //     autoClose: 6000, // milliseconds
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            // });


            const reader = new FileReader();
            reader.readAsDataURL(response.data);
            reader.onloadend = () => {
              const fileURL = URL.createObjectURL(response.data);
              window.open(fileURL, '_blank');
              setLoading(false)
            };

            // const blob = new Blob([response.data], { type: 'application/pdf' });
            // const url = window.URL.createObjectURL(blob);
            // const link = document.createElement('a');
            // link.href = url;
            // link.target = '_blank'; 
            // link.click();
    
            // window.URL.revokeObjectURL(url);

            //window.open(url, '_blank');
            setLoadingInsuranceReport(false)
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingInsuranceReport(false)
        } finally {
            setLoadingInsuranceReport(false)
        }
    }

    const saveAbsentPresent = async () => {
        setLoadingAbsentPresent(true);
        try {
            const token = localStorage.getItem('damavandclub-token')
            await axios.post(`${apiUrls.baseURL}/program/setAbsentPresent?id=${data.id}`, manageMembers, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            toast.success("با موفقیت ثبت شد", {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });

            onRefresh();
            setIsShowManageMembersModalVisible(false);
            setLoadingAbsentPresent(false);

        } catch (error) {
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingAbsentPresent(false);
        } finally {
            setLoadingAbsentPresent(false);
        }
    };

    return (
        <>
            <Button style={{ backgroundColor: color,border:'none' }} loading={loadingManageMembers} onClick={() => showManageMembers(data.id)}>{data.presentsCount}/{data.membersCount}
                <SolutionOutlined style={{ fontSize: 30 }} twoToneColor="#eb2f96">
                </SolutionOutlined><span loading={loadingManageMembers}>(مدیریت اعضای برنامه و حضور غیاب)</span></Button>

            <Modal
                title={`مدیریت اعضای برنامه ${data.nameFa}`}
                open={isShowManageMembersModalVisible}
                closable={false}
                width={window.innerWidth <= 768 ? '85%' : '40%'}
                footer={<div className='row-container'>
                    <Button onClick={() => setIsShowManageMembersModalVisible(false)} style={{ width: '100%' }}>بستن</Button>
                    <Button type="primary" loading={loadingAbsentPresent}
                        onClick={() => saveAbsentPresent()}
                        style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(0, 71, 177)' }}>ذخیره تغییرات</Button>
                </div>}
                loading={loadingManageMembers}
                // onOk={handleOk}
                onCancel={() => setIsShowManageMembersModalVisible(false)}
            >
                <Button size='small' className='margintop-5' type="primary" loading={loadingInsuranceReport}
                    onClick={() => insuranceReport(data.id)}
                    style={{ width: '100%', backgroundColor: 'rgb(11, 176, 255)' }}>چاپ نامه اعضای تیم برای ارسال به بیمه</Button>
                     {pdfUrl && <iframe src={pdfUrl} width="100%" height="600px"></iframe>}

                {manageMembers.map((item, index) => {
                    return <div className='modal-container-box'
                        style={{ marginTop: 5 }}
                        key={index}>
                        <div className='row-container'>

                            <div className='column-container'>
                                <span className='blue'>
                                    <input onChange={() => setAbsetPresent(item.userId)} style={{ width: 20, height: 20 }} type='checkbox' checked={item.isPresent} />
                                    <span className='marginright-5'> {index + 1}-{item.firstName} {item.lastName}</span>
                                </span>
                                <span>موبایل: {item.phoneNumber}</span>
                                <span>سن: {item.age}</span>
                                <span>سال ورود: {item.startMemberingDate}</span>
                                <span>نوع عضویت: {item.memberType}</span>
                                <span>هزینه پرداخت شده: <AmountFormat amount={item.paidAmount} /> ریال</span>
                                <div style={{ textAlign: 'justify' }}>مدارک: {item.certificates}</div>
                            </div>

                            <div>
                                <a rel='noreferrer'
                                    href={item.profilePhotoUrl}
                                    target='_blank'>
                                    <img style={{ width: 120, height: 120, borderRadius: 50 }} src={item.profilePhotoUrl}></img>
                                </a>
                            </div>
                        </div>
                        <Input placeholder='در این قسمت می توانید بعد از برنامه در مورد ایشان نظر دهید' style={{ marginTop: 5 }}
                            value={item.leaderComment}
                            onChange={(event) => setComment(item.userId, event.target.value)}
                            size="large" />
                        <Button size='small' className='margintop-5' type="primary"
                            onClick={() => {
                                setSelectedNameForReject(item.firstName + " " + item.lastName)
                                setSelectedUserIdForReject(item.userId)
                                fetchRejectReason()
                            }}
                            style={{ width: '100%', backgroundColor: 'rgb(243, 43, 43)' }}>حذف این عضو از برنامه</Button>
                    </div>
                })}
            </Modal >

            <Modal
                title={`دلیل حذف ${selectedNameForReject}`}
                open={isShowRejectReasonModalVisible}
                closable={false}
                okButtonProps={null}
                footer={<div className='row-container'>
                    <Button onClick={() => setIsShowRejectReasonModalVisible(false)} style={{ width: '100%' }}>بستن</Button>
                    <Button type="primary" loading={loadingAbsentPresent}
                        onClick={() => rejectMember()}
                        style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(10, 23, 43)' }}>تایید</Button>
                </div>}
            >
                <Select
                    style={{ width: '100%' }}
                    placeholder="انتخاب دلیل"
                    onChange={(value) => setRejectModel({ rejectReason: value, memberId: selectedUserIdForReject })}
                >
                    {dropDownRejectReason?.map((item) => (
                        <Option key={item.id} value={item.id}>
                            {item.displayName}
                        </Option>
                    ))}
                </Select>
            </Modal>
        </>
    );
});

ProgramManageMembers.propTypes = {
    data: PropTypes.any,
    onRefresh: PropTypes.any,
    color: PropTypes.any
};

ProgramManageMembers.displayName = 'ProgramManageMembers';

export default ProgramManageMembers;
