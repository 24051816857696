import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react'
import {
    CChartBar,
    CChartDoughnut,
    CChartLine,
    CChartPie,
    CChartPolarArea,
    CChartRadar,
} from '@coreui/react-chartjs'
import { DocsCallout } from 'src/components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox, Pagination, Table, Collapse, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { apiUrls } from '../../apiUrls.js';
import Program from "../../components/Program.js";
import { useNavigate } from 'react-router-dom';
import { UnlockOutlined, LockOutlined, EditOutlined, SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import AmountFormat from "../../components/AmountFormat.js";
import InputNumberFormat from "../../components/InputNumberFormat.js";
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import { UploadOutlined } from '@ant-design/icons';
import jquery from 'jquery';
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";

const { Option } = Select;

const baseURL = apiUrls.baseURL;

const BillList = () => {

    const initialBillModel = {
        amount: '',
        userId: '',
        billType: '',
        description: '',
        payDateDeadLine: ''
    };

    const [data, setData] = useState([]);
    const [billModel, setBillModel] = useState(initialBillModel);
    const [loading, setLoading] = useState(false);
    const [payLoading, setPayLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [id, setId] = useState(0);
    const [count, setCount] = useState(0);
    const [isShowDeleteConfirmModalVisible, setIsShowDeleteConfirmModalVisible] = useState(false);
    const [isShowPayConfirmModalVisible, setIsShowPayConfirmModalVisible] = useState(false);
    const [filter, setFilter] = useState({ skip: 0, limit: 20, data: {} });
    const [dropDownBillType, setDropDownBillTypes] = useState([]);
    const [dropDownUser, setDropDownUsers] = useState([]);
    const [payDeadLineDate, setPayDeadLineDate] = useState(null);
    const [isShowCreateBillModalVisible, setIsShowCreateBillModalVisible] = useState(false);
    const [billPhoto, setBillPhoto] = useState('');
    const permissions = useSelector((state) => state.permissions.permissions);
    const navigate = useNavigate();
    const [reload, setReload] = useState(false);

    const columns = [
        {
            title: 'ردیف',
            dataIndex: 'index',
            key: 'index',
            responsive: ['xl', 'lg'],
            width: 50,
            render: (_, record, index) => (
                <>{index + 1 + filter?.limit * filter?.skip}</>
            ),
        },
        {
            title: 'نام عضو',
            key: 'userFullName',
            dataIndex: 'userFullName',
            width: 100,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs']
        },
        {
            title: 'مبلغ(ریال)',
            key: 'amount',
            dataIndex: 'amount',
            width: 10,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                <AmountFormat amount={record.amount}></AmountFormat>
            ),
        },
        {
            title: 'نوع قبض',
            key: 'typeTitle',
            dataIndex: 'typeTitle',
            width: 100,
            responsive: ['xl', 'lg', 'md'],
        },
        {
            title: 'پرداخت از کیف پول',
            key: 'id',
            dataIndex: 'id',
            width: 100,
            responsive: ['xl', 'lg', 'md'],
            render: (text, record) => (
                !record.isPaid && <Button style={{ backgroundColor: 'green', color: 'white' }} size='small' onClick={() => {
                    setIsShowPayConfirmModalVisible(true)
                    setId(record.id)
                }}>پرداخت از کیف پول</Button>
            ),
        },
        {
            title: 'حذف',
            key: 'id',
            dataIndex: 'id',
            width: 30,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                !record.isPaid && <DeleteOutlined onClick={() => {
                    setIsShowDeleteConfirmModalVisible(true)
                    setId(record.id)
                }} style={{ color: 'red', fontSize: 30 }} />
            ),
        },
    ];

    useEffect(() => {
        setLoading(true)
        fetchBillType()
        fetchUsers()
        fetchData()
    }, [reload])

    const fetchData = async () => {

        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.post(`${baseURL}/bill/get`, filter, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;
            result.items.map(item => {
                item.key = item.id
            })
            setCount(result.count)
            setData(result.items);
            setLoading(false);
            setReload(false)
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
            setReload(false)
        } finally {
            setLoading(false);
            setReload(false)
        }
    };

    const fetchUsers = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })

            var result = response.data.data;
            setDropDownUsers(result);
        } catch (error) {
        } finally {
        }
    };

    const fetchBillType = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/bill/getTypeDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })

            var result = response.data.data;
            setDropDownBillTypes(result);
        } catch (error) {
        } finally {
        }
    };

    const deleteBill = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            await axios.delete(`${baseURL}/bill/delete?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            fetchData()
            setIsShowDeleteConfirmModalVisible(false)
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const pay = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            await axios.get(`${baseURL}/bill/pay?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            toast.success('با موفقیت انجام شد', {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
            setIsShowPayConfirmModalVisible(false)
            fetchData()
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
            setIsShowPayConfirmModalVisible(false)
        } finally {
            setLoading(false);
        }
    };

    const imageProps = {
        name: 'file',
        action: `${baseURL}/media/UploadFiles?containerkey=bills&isPublic=true`, // Replace with your upload endpoint
        headers: {
            authorization: 'Bearer ' + localStorage.getItem('damavandclub-token'),
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
            }
            if (info.file.status === 'done') {
                let mediaResponse = info.file.response.data[0];
                setBillPhoto(mediaResponse.url)
                setBillModel({ ...billModel, mediaId: mediaResponse.id })
                jquery(".ant-upload-list-text-container").remove();
            } else if (info.file.status === 'error') {
                return toast.warning(info.file.response.Msg, {
                    position: 'bottom-right',
                    autoClose: 3000, // milliseconds
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        },
    };

    const createBill = async () => {

        if (billModel.userId == undefined || billModel.userId == null || billModel.userId == '') {
            return toast.warning("هیچ عضوی انتخاب نشده است", {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
        }

        if (billModel.amount == undefined || billModel.amount == null || billModel.amount == 0 || billModel.amount == '') {
            return toast.warning("مبلغ وارد شده صحیح نیست", {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
        }

        setLoadingSave(true);
        const token = localStorage.getItem('damavandclub-token')

        try {
            await axios.post(`${baseURL}/bill/create`, billModel, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            fetchData()
            setLoadingSave(false);
            setIsShowCreateBillModalVisible(false)
            setBillModel({ ...billModel, userId: null })
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } finally {
            setLoadingSave(false);
        }
    };

    const showCreateBillModal = async () => {
        //setBillModel({})
        setIsShowCreateBillModalVisible(true)
    };

    const setSelectedPayDeadLineDate = async (date) => {
        setPayDeadLineDate({
            year: date.year,
            month: date.month,
            day: date.day
        });
        setBillModel({ ...billModel, payDateDeadLine: `${date.year}/${date.month}/${date.day}` })
    }

    return (
        <div>
            <HideSidebarInMobileMode />
            {permissions.includes('createbill') ? <Button onClick={() => showCreateBillModal()} style={{ backgroundColor: 'rgb(44 0 151)', marginTop: 10, width: '100%', marginBottom: 10 }}
                type="primary"><label>ایجاد قبض جدید</label></Button> : null}

            <Collapse defaultActiveKey={['0']}>
                <Collapse.Panel header='جستجو ' key='1'>
                    <Select
                        style={{ width: '100%', marginTop: 10 }}
                        placeholder="انتخاب عضو"
                        allowClear={true}
                        showSearch
                        optionFilterProp="label"
                        onChange={(value) => {
                            setFilter({ ...filter, data: { ...filter.data, userId: value } })
                        }}
                    //value={WalletModel.userId}
                    >
                        {dropDownUser?.map((item) => (
                            <Option key={item.id} label={item.displayName} value={item.id}>
                                {item.displayName}
                            </Option>
                        ))}
                    </Select>

                    <Select
                        style={{ width: '100%', marginTop: 10 }}
                        placeholder="انتخاب نوع قبض"
                        allowClear={true}
                        onChange={(value) => {
                            setFilter({ ...filter, data: { ...filter.data, type: value } })
                        }}
                        value={billModel.type}
                    >
                        {dropDownBillType?.map((item) => (
                            <Option key={item.id} value={item.id}>
                                {item.displayName}
                            </Option>
                        ))}
                    </Select>
                    <Button onClick={() => { setReload(true); setFilter({ ...filter, skip: 0, limit: 20 }) }}
                        loading={loading} style={{ backgroundColor: 'rgb(0 39 151)', marginTop: 10, width: '100%', marginBottom: 10 }}
                        type="primary"><label>جستجو</label></Button>
                    {/* <Button onClick={() => getExcel()} loading={loadingExcel} style={{ backgroundColor: 'rgb(229 131 27)', marginTop: 10, width: '100%', marginBottom: 10 }}
                        type="primary"><label>خروجی اکسل</label></Button> */}
                </Collapse.Panel>
            </Collapse>
            <Table
                size='small'
                style={{
                    textAlign: 'right',
                    marginTop: 10
                }}
                rowClassName={(record) => record.isPaid ? 'greenrow' : 'redrow'}
                columns={columns}
                bordered={true}
                dataSource={data}
                loading={loading}
                pagination={false}
                expandable={{
                    expandedRowRender: (record) => <div style={{ textAlign: 'right' }}>
                        <div>توضیحات: {record.description}</div>
                        <div>ایجاد کننده: {record.creatorName}</div>
                        <div>مهلت پرداخت: {record.persianPayDateDeadLine}</div>
                        <div>تاریخ پرداخت: {record.persianPayDate}</div>
                        <div>تصویر : {record.photoUrl !== null ?
                            <a href={record.photoUrl} rel="noreferrer" target='_blank'><img src={record.photoUrl} /></a> :
                            'تصویر قبض قرار داده نشده است'}</div>
                    </div>
                    //rowExpandable: (record) => record.name !== 'Not Expandable',
                }}
                locale={{
                    emptyText: 'موردی یافت نشد',
                }}
            />
            <Pagination
                onChange={(pageNumber, limit) => {
                    setFilter({ ...filter, skip: pageNumber > 0 ? pageNumber - 1 : pageNumber, limit: limit })
                    setReload(true)
                }}
                style={{ marginTop: 30, marginBottom: 5 }}
                current={filter.skip + 1}
                //current={10}
                responsive={true}
                defaultPageSize={filter.limit}
                locale={{ items_per_page: '' }}
                showTotal={(total) => `تعداد کل: ${total} قبض`}
                showSizeChanger={true}
                total={count} />
            <Modal
                title='تایید حذف'
                open={isShowDeleteConfirmModalVisible}
                closable={false}
                okButtonProps={null}
                footer={<div className='row-container'>
                    <Button onClick={() => setIsShowDeleteConfirmModalVisible(false)} style={{ width: '100%' }}>بستن</Button>
                    <Button type="primary"
                        onClick={() => deleteBill()}
                        style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(10, 23, 43)' }}>تایید</Button>
                </div>
                }
                onCancel={() => setIsShowDeleteConfirmModalVisible(false)}
            >
            </Modal>
            <Modal
                title='تایید پرداخت از کیف پول'
                open={isShowPayConfirmModalVisible}
                closable={false}
                okButtonProps={null}
                footer={<div className='row-container'>
                    <Button onClick={() => setIsShowPayConfirmModalVisible(false)} style={{ width: '100%' }}>بستن</Button>
                    <Button type="primary"
                        onClick={() => pay()}
                        style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(10, 23, 43)' }}>تایید</Button>
                </div>
                }
                onCancel={() => setIsShowPayConfirmModalVisible(false)}
            >
            </Modal>
            <Modal
                title={`ایجاد قبض`}
                open={isShowCreateBillModalVisible}
                width={1000}
                closable={false}
                okButtonProps={null}
                footer={<div className='row-container'>
                    <Button onClick={() => {
                        setBillModel(initialBillModel)
                        setPayDeadLineDate(null);
                        setIsShowCreateBillModalVisible(false)
                    }} style={{ width: '100%' }}>بستن</Button>
                    <Button type="primary" loading={loadingSave}
                        onClick={() => createBill()}
                        style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(10, 23, 43)' }}>ذخیره</Button>
                </div>}
            >
                <InputNumberFormat value={billModel.amount} placeholder="مبلغ(ریال)-با اعداد انگلیسی"
                    onValueChange={(value) => setBillModel({ ...billModel, amount: value })} />
                <Select
                    style={{ width: '100%', marginTop: 10 }}
                    placeholder="انتخاب نوع قبض"
                    onChange={(value) => setBillModel({ ...billModel, type: value })}
                    value={billModel.type}
                >
                    {dropDownBillType?.map((item) => (
                        <Option key={item.id} value={item.id}>
                            {item.displayName}
                        </Option>
                    ))}
                </Select>
                <Select
                    style={{ width: '100%', marginTop: 10 }}
                    placeholder="انتخاب عضو"
                    showSearch
                    optionFilterProp="label"
                    onChange={(value) => setBillModel({ ...billModel, userId: value })}
                    value={billModel.userId}
                >
                    {dropDownUser?.map((item) => (
                        <Option key={item.id} label={item.displayName} value={item.id}>
                            {item.displayName}
                        </Option>
                    ))}
                </Select>
                <input
                    placeholder='توضیحات'
                    value={billModel.description}
                    onChange={(event) => setBillModel({ ...billModel, description: event.target.value })}
                    style={{ marginTop: 10, height: 30, width: '100%' }} />
                <label className='margintop-7'>مهلت پرداخت</label>
                <div className='margintop-7 center-content customDatePickerWidth'>
                    <DatePicker
                        value={payDeadLineDate || null}
                        onChange={(value) => setSelectedPayDeadLineDate(value)}
                        shouldHighlightWeekends
                        locale='fa'
                    />
                </div>
                <Upload
                    {...imageProps}
                    //action={publicUploadPath()}
                    style={{ width: 200 }}
                //listType="picture-card"
                // itemRender={(originNode, file) => (
                //   <DraggableUploadListItem originNode={originNode} file={file} />
                // )}
                //fileList={fileList}
                //onPreview={handlePreview}
                //onChange={handleChange}
                >
                    <Button icon={<UploadOutlined />} style={{ backgroundColor: 'rgb(231 96 45)', marginTop: 10, marginBottom: 10 }}
                        type="primary" ><label>آپلود تصویر</label></Button>
                    {/* <Button >Click to Upload</Button> */}
                    {/* {fileList.length >= 8 ? null : uploadButton} */}
                </Upload>
                <img
                    style={{
                        //borderRadius: 150 / 2,
                        overflow: 'hidden',
                        borderWidth: 3,
                    }}
                    //width={300}
                    //height={300}
                    src={billPhoto}
                ></img>
            </Modal>
        </div>
    )
}

export default BillList
