import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Table, Pagination, Modal } from 'antd';
import { apiUrls } from '../apiUrls.js';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const ProgramPermissions = React.memo(({ data }) => {

    const [isShowModalVisible, setIsShowModalVisible] = useState(false);
    const [loadingPermissionMembers, setLoadingPermissionMembers] = useState(false);
    const [permissionMembers, setPermissionMembers] = useState([]);
    const [loadingPermission, setLoadingPermission] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {

    }, [])

    const setPermission = (userId) => {
        const updatedItems = permissionMembers.map(item => {
            if (item.userId === userId)
                return { ...item, isPermission: !item.isPermission }
            return item;
        })
        setPermissionMembers(updatedItems);
    }

    const showModal = async () => {
        setLoadingPermissionMembers(true);
        try {
            const token = localStorage.getItem('damavandclub-token')
            let response = await axios.get(`${apiUrls.baseURL}/program/GetUserProgramPermissionMembers?id=${data.id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            var result = response.data.data;
            setPermissionMembers(result);
            setIsShowModalVisible(true);
            setLoadingPermissionMembers(false);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoadingPermissionMembers(false);
        } finally {
            setLoadingPermissionMembers(false);
        }
    };


    const savePermission = async () => {
        setLoadingPermission(true);
        try {
            const token = localStorage.getItem('damavandclub-token')
            await axios.post(`${apiUrls.baseURL}/program/SetProgramUserPermission?id=${data.id}`, permissionMembers, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            toast.success("با موفقیت ثبت شد", {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setIsShowModalVisible(false);
            setLoadingPermission(false);

        } catch (error) {
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingPermission(false);
        } finally {
            setLoadingPermission(false);
        }
    };

    return (
        <>
            <Button size='small' onClick={() => showModal()} loading={loadingPermissionMembers}
                style={{ backgroundColor: 'rgb(149, 0, 211)', marginTop: 10, width: '100%' }}
                type="primary"><label>صدور مجوز اعضای برنامه</label></Button>

            <Modal
                title={`مجوز شرکت اعضای برنامه ${data.nameFa}`}
                open={isShowModalVisible}
                closable={false}
                width={window.innerWidth <= 768 ? '85%' : '40%'}
                footer={<div className='row-container'>
                    <Button onClick={() => setIsShowModalVisible(false)} style={{ width: '100%' }}>بستن</Button>
                    <Button type="primary" loading={loadingPermission}
                        onClick={() => savePermission()}
                        style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(0, 71, 177)' }}>ذخیره تغییرات</Button>
                </div>}
                loading={loadingPermissionMembers}
                // onOk={handleOk}
                onCancel={() => setIsShowModalVisible(false)}
            >

                {permissionMembers.map((item, index) => {
                    return <div className='modal-container-box'
                        style={{ marginTop: 5 }}
                        key={index}>
                        <div className='row-container'>

                            <div className='column-container'>
                                <span className='blue'>
                                    <input onChange={() => setPermission(item.userId)} style={{ width: 20, height: 20 }} type='checkbox' checked={item.isPermission} />
                                    <span className='marginright-5'> {index + 1}-{item.firstName} {item.lastName}</span>
                                </span>
                                {/* <div style={{ textAlign: 'justify' }}>مدارک: {item.certificates}</div> */}
                            </div>

                        </div>
                    </div>
                })}

            </Modal >
        </>
    );
});

ProgramPermissions.propTypes = {
    data: PropTypes.any
};

ProgramPermissions.displayName = 'ProgramPermissions';

export default ProgramPermissions;
