import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import jquery from 'jquery';
import { apiUrls } from '../../../apiUrls.js';

const baseURL = apiUrls.baseURL;
const { TabPane } = Tabs;
const { Option } = Select;

const BannerForm = () => {

    const [data, setData] = useState({ medias: [] });
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const permissions = useSelector((state) => state.permissions.permissions);
    const [image, setImage] = useState('');
    //eslint-disable-next-line react-hooks/rules-of-hooks  
    const { id } = useParams();

    useEffect(() => {
        if (id !== undefined)
            fetchData(id)
    }, [])

    const fetchData = async (id) => {
        setLoading(true);
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/banner/getbyid?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            var result = response.data.data;

            setData(result);
            setLoading(false);
        } catch (error) {
            //alert(error)
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const imageProps = {
        name: 'file',
        action: `${baseURL}/media/UploadFiles?containerkey=banners&isPublic=true`, // Replace with your upload endpoint
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('damavandclub-token'),
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            //console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            let mediaResponse = info.file.response.data[0];
            setImage(mediaResponse.url)
            setData({ ...data, mediaId: mediaResponse.id })
            jquery(".ant-upload-list-text-container").remove();
          } else if (info.file.status === 'error') {
            return toast.warning(info.file.response.Msg, {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
          }
        },
      };

    const save = async () => {
        setLoadingSave(true);
        try {
            const token = localStorage.getItem('damavandclub-token')

            if (id !== undefined) {
                await axios.post(`${baseURL}/banner/update?id=${id}`, data, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                })
            }

            else {
                await axios.post(`${baseURL}/banner/create`, data, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                })
            }

            toast.success("تغییرات با موفقیت ذخیره شد", {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } catch (error) {
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } finally {
            setLoadingSave(false);
        }
    }

    if (loading) {
        return <Spin size="large" />
    }

    return (
        <div className='row'>
            <div style={{
                backgroundColor: 'white',
                flexDirection: 'column',
                display: 'flex',
                flex: 1,
                height: '100%',
                padding: 10
            }}>
                <Button onClick={() => save()} loading={loadingSave} style={{ backgroundColor: 'rgb(0 151 46)', marginTop: 10, width: '100%', marginBottom: 10 }}
                    type="primary"><label>ذخیره یا ویرایش</label></Button>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="فارسی" key="1">
                        <label>عنوان روی بنر</label>
                        <Input value={data.nameFa} onChange={(event) => setData({ ...data, nameFa: event.target.value })} style={{ marginTop: 5 }} size="large" />


                        <label className='margintop-7'>توضیحات فارسی</label>
                        <textarea value={data.descriptionFa} onChange={(event) => setData({ ...data, descriptionFa: event.target.value })}
                            style={{
                                marginTop: 5,
                                resize: 'none',
                                width: '100%',
                                minHeight: 400
                            }} />

                        <Upload
                            {...imageProps}
                            //action={publicUploadPath()}
                            style={{ width: 200 }}
                        //listType="picture-card"
                        // itemRender={(originNode, file) => (
                        //   <DraggableUploadListItem originNode={originNode} file={file} />
                        // )}
                        //fileList={fileList}
                        //onPreview={handlePreview}
                        //onChange={handleChange}
                        >
                            <Button icon={<UploadOutlined />} style={{ backgroundColor: 'rgb(231 96 45)', marginTop: 10, marginBottom: 10 }}
                                type="primary" ><label>آپلود تصویر</label></Button>
                            {/* <Button >Click to Upload</Button> */}
                            {/* {fileList.length >= 8 ? null : uploadButton} */}
                        </Upload>
                        <img
                            style={{
                                //borderRadius: 150 / 2,
                                overflow: 'hidden',
                                borderWidth: 3,
                            }}
                            //width={300}
                            //height={300}
                            src={image != "" ? image : data.imageUrl}
                        ></img>
                        <br></br>
                        <label className='margintop-7'>سئو</label>
                        <Input style={{ marginTop: 5 }} value={data.seoContent} onChange={(event) => setData({ ...data, seoContent: event.target.value })} size="large" />
                        <Checkbox checked={data.isActive} onChange={() => setData({ ...data, isActive: !data.isActive })}>فعال/غیرفعال</Checkbox>
                    </TabPane>
                    <TabPane tab="انگلیسی" key="2">
                        <label>عنوان</label>
                        <Input value={data.nameEn} onChange={(event) => setData({ ...data, nameEn: event.target.value })} style={{ marginTop: 5 }} size="large" />

                    </TabPane>
                </Tabs>
            </div>
        </div >
    )
}

export default BannerForm
