import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react'
import {
    CChartBar,
    CChartDoughnut,
    CChartLine,
    CChartPie,
    CChartPolarArea,
    CChartRadar,
} from '@coreui/react-chartjs'
import { DocsCallout } from 'src/components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox, Pagination } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { apiUrls } from '../../apiUrls.js';
import Program from "../../components/Program.js";
import { useNavigate } from 'react-router-dom';
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";
const baseURL = apiUrls.baseURL;

const Bicycling = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({ skip: 0, limit: 10 });
    const permissions = useSelector((state) => state.permissions.permissions);
    const accessShowInSite = permissions.includes('createblog');
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        fetchData()
    }, [filter])

    const fetchData = async () => {

        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/program/getBicyclingNotifyPrograms?skip=${filter.skip}&limit=${filter.limit}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            var result = response.data.data;
            setData(result);
            setLoading(false);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 300
        }}><Spin size="large" /></div>
    }

    return (
        <div className='row'>
             <HideSidebarInMobileMode/>
            {data?.items?.map((item, index) => {
                return <Program
                    key={index}
                    programKey='bicycling'
                    color='rgb(213 243 245)'
                    item={item}
                    isShowOtherMember={true}
                    isShowRemainingCapacity={true}
                    onRefresh={fetchData}>
                </Program>
            })}
            <Pagination
                onChange={(pageNumber, limit) => setFilter({ skip: pageNumber - 1, limit: limit })}
                style={{ marginTop: 30, marginBottom: 5 }}
                current={filter.skip + 1}
                responsive={true}
                pageSize={filter.limit}
                showTotal={(total) => `تعداد کل: ${total} برنامه`}
                total={data.count} />
        </div>
    )
}

export default Bicycling
