import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Table, Pagination, Modal } from 'antd';
import { apiUrls } from '../apiUrls.js';
import { toast } from 'react-toastify';

const JoinProgram = React.memo(({ data, onRefresh }) => {

    const [isShowModalVisible, setIsShowModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [programPolicies, setProgramPolicies] = useState([]);

    const join = async (id) => {
        setLoading(true);
        try {
            const token = localStorage.getItem('damavandclub-token')
            await axios.get(`${apiUrls.baseURL}/program/joinProgram?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            onRefresh();

            toast.success("با موفقیت ثبت شد", {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setIsShowModalVisible(false)
            setProgramPolicies([])
            setLoading(false);
        } catch (error) {
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setLoading(false);
            setProgramPolicies([])
            setIsShowModalVisible(false)
        } finally {
            setLoading(false);
            setProgramPolicies([])
            setIsShowModalVisible(false)
        }
    };

    const showPolicyModal = async (programId) => {
        setLoading(true)
        try {
            const token = localStorage.getItem('damavandclub-token')
            var response = await axios.get(`${apiUrls.baseURL}/WorkingGroupCategory/GetPolicies?programId=${programId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            var result = response.data.data;
            setIsShowModalVisible(true);
            setLoading(false)
            setProgramPolicies(result);
        } catch (error) {
            setLoading(false)
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } finally {
        }
    }

    return (
        <>
            <Button loading={loading} size='small' onClick={() => showPolicyModal(data.id)}
                style={{ backgroundColor: 'rgb(37, 151, 0)', marginTop: 10, width: '100%' }}
                type="primary"><label>شرکت در برنامه</label></Button>

            <Modal
                title={`قوانین حضور در برنامه:  ${data.nameFa}`}
                open={isShowModalVisible}
                closable={false}
                okButtonProps={null}
                footer={<div className='row-container'>
                    <Button onClick={() => setIsShowModalVisible(false)} style={{ width: '100%' }}>بستن</Button>
                    <Button type="primary" loading={loading}
                        onClick={() => join(data.id)}
                        style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(10, 23, 43)' }}>تایید</Button>
                </div>}
                onCancel={() => setIsShowModalVisible(false)}
            >

                {data.amount != undefined && data.amount != null && data.amount != 0 ?
                    <span>لطفا قبل از شرکت در برنامه حتما با سرپرست برنامه یا مسئول کارگروه هماهنگ شوید و همچنین به قوانین لغو حضور که در زیر قید شده است توجه فرمایید</span> :
                    <span>لطفا قبل از شرکت در برنامه حتما با سرپرست برنامه هماهنگ شوید</span>}
                <br></br>
                <br></br>

                {programPolicies !== undefined && programPolicies !== null && programPolicies.length > 0 && data.amount != 0 && data.amount != undefined && data.amount != null ? <>
                    <table style={{ border: '1px solid gray', width: '100%', textAlign: 'center', marginTop: 20 }}>
                        <tr style={{ border: '1px solid gray' }}>
                            <th>تعداد روزهای مانده تا شروع برنامه</th>
                            <th>درصد کسر از هزینه پرداخت شده</th>
                        </tr>
                        {programPolicies.map((item, index) => {
                            return <tr style={{ border: '1px solid gray' }} key={index}>
                                <td>{item.remainingDaysToStartProgram}</td>
                                <td>{item.costReductionPercentage}</td>
                            </tr>
                        })}
                    </table></> : data.amount != undefined ? "در صورت لغو حضور در این برنامه هزینه بصورت 100 درصد به کیف پول شما بازخواهد گشت و شامل کسر از هزینه پرداخت شده نخواهد شد" : ""}

                <br></br>
                <br></br>
                <div className='center'>آیا از حضور در این برنامه مطمئن هستید؟</div>
            </Modal>
        </>
    );
});

JoinProgram.propTypes = {
    data: PropTypes.any,
    onRefresh: PropTypes.any
};

JoinProgram.displayName = 'JoinProgram';

export default JoinProgram;
