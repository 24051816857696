import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Table, Pagination, Modal } from 'antd';
import { apiUrls } from '../apiUrls.js';
import { toast } from 'react-toastify';
import AmountFormat from "../components/AmountFormat.js";

const { Option } = Select;
const baseURL = apiUrls.baseURL;

const ProgramPhotos = React.memo(({ data, onRefresh }) => {

    const [isShowModalVisible, setIsShowModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [photos, setPhotos] = useState([]);

    useEffect(() => {

    }, [])

    const showPhotosModal = async () => {
        setIsShowModalVisible(true);
        try {
            const token = localStorage.getItem('damavandclub-token')
            let response = await axios.get(`${apiUrls.baseURL}/program/getmedias?id=${data.id}&key=program`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setPhotos(response.data.data)
        } catch (error) {
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setLoading(false);
        } finally {
        }
    };

    return (
        <>
            <Button size='small' onClick={() => showPhotosModal()}
                style={{ backgroundColor: 'rgb(149, 0, 211)' }}
                type="primary"><label>تصاویر</label></Button>

            <Modal
                title={`تصاویر برنامه :  ${data.nameFa}`}
                open={isShowModalVisible}
                closable={false}
                width={window.innerWidth <= 768 ? '85%' : '50%'}
                okButtonProps={null}
                bodyStyle={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
                footer={
                    <Button onClick={() => setIsShowModalVisible(false)} style={{ width: '100%' }}>بستن</Button>
                }
                onCancel={() => setIsShowModalVisible(false)}
            >
                {photos != undefined && photos.map((item, index) => {
                    return <div key={index}>
                        <a rel='noreferrer' href={item.url} target='_blank'><img style={{ width: 250, height: 250, marginTop: 10 }} src={item.url}></img></a>
                    </div>
                })}
      

            </Modal>
        </>
    );
});

ProgramPhotos.propTypes = {
    data: PropTypes.any,
    onRefresh: PropTypes.any
};

ProgramPhotos.displayName = 'ProgramPhotos';

export default ProgramPhotos;
