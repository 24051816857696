import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react'
import {
    CChartBar,
    CChartDoughnut,
    CChartLine,
    CChartPie,
    CChartPolarArea,
    CChartRadar,
} from '@coreui/react-chartjs'
import { DocsCallout } from 'src/components'
import { toast } from 'react-toastify';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import jquery from 'jquery';
import { useParams } from 'react-router-dom';
import { apiUrls } from '../../apiUrls.js';
import { useNavigate } from 'react-router-dom';
import MemberSpecification from "../../components/MemberSpecification.js";
import MemberCertificate from "../../components/MemberCertificate.js";

const { TabPane } = Tabs;
const { Option } = Select;

const Create = () => {

    const [data, setData] = useState({});
    const [roleData, setRoleData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [dropDownRoles, setDropDownRoles] = useState([]);
    const [dropDownType, setDropDownTypes] = useState([]);
    const [startMemberingDate, setStartMemberingDate] = useState(null);
    const baseURL = apiUrls.baseURL;
    const navigate = useNavigate();
    const permissions = useSelector((state) => state.permissions.permissions);

    const hasChangeRolePermission = permissions.includes('createrole');
    const hasChangeStartDateMemberingPermission = permissions.includes('createmember');

    useEffect(() => {
        fetchMemberTypes()
        if (hasChangeRolePermission) {
            fetchRoles()
        }
    }, [permissions])

    function callback(key) {

    }

    const setSelectedStartMemberingDate = async (date) => {
        setStartMemberingDate({
            year: date.year,
            month: date.month,
            day: date.day
        });
        setData({ ...data, startMemberingDate: `${date.year}/${date.month}/${date.day}` })
    }

    const save = async () => {
        setLoadingSave(true);
        try {
            const token = localStorage.getItem('damavandclub-token')
            let response = await axios.post(`${baseURL}/user/create`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            let userId = response.data.data;
            if (hasChangeRolePermission) {
                await axios.post(`${baseURL}/user/createroles?id=${userId}`, roleData, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                });
            }
            toast.success("با موفقیت ذخیره شد", {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } catch (error) {
            toast.error(error.response.data.message, {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } finally {
            setLoadingSave(false);
        }
    }

    const fetchRoles = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/role/getDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })

            var result = response.data.data;
            setDropDownRoles(result);
        } catch (error) {
        } finally {
        }
    };

    const fetchMemberTypes = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getTypeDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })
            var result = response.data.data;
            setDropDownTypes(result)
        } catch (error) {
        } finally {
        }
    };

    if (loading) {
        return <Spin size="large" />
    }

    return (
        <div className='row'>
            <div style={{
                backgroundColor: 'white',
                flexDirection: 'column',
                display: 'flex',
                flex: 1,
                height: '100%',
                padding: 10
            }}>
                <Button onClick={() => save()} loading={loadingSave} style={{ backgroundColor: 'rgb(0 151 46)', marginTop: 10, width: '100%', marginBottom: 10 }}
                    type="primary"><label>ذخیره</label></Button>
                <Tabs defaultActiveKey="1" onChange={callback}>
                    <TabPane tab="مشخصات" key="1">

                        <MemberSpecification hideProfileUpload={true} data={data} setData={setData}></MemberSpecification>

                        <label className='margintop-7'>تاریخ عضویت</label>
                        {hasChangeStartDateMemberingPermission ? <div className='margintop-7 center-content customDatePickerWidth'>
                            <DatePicker
                                value={startMemberingDate || null}
                                //inputClassName='fullwidth'
                                onChange={(value) => setSelectedStartMemberingDate(value)}
                                //minimumDate={persianToday}
                                //calendarClassName="fullwidth"
                                shouldHighlightWeekends
                                locale='fa'
                            />
                        </div> : <div style={{ border: '1px solid lightgray' }} className='margintop-7 center-content customDatePickerWidth'>
                            <label>{data.startMemberingDate}</label>
                        </div>}

                        <label className='margintop-7'>شماره عضویت</label>
                        <Input value={data.memberNumber} onChange={(event) => setData({ ...data, memberNumber: event.target.value })} style={{ marginTop: 5 }} size="large" />

                        <label className='margintop-7'>انتخاب نوع عضویت</label> <Select
                            style={{ width: '100%' }}
                            placeholder="انتخاب نوع عضویت"
                            onChange={(value) => setData({ ...data, type: value })}
                            value={data.type}
                        >
                            {dropDownType?.map((item) => (
                                <Option key={item.id} value={item.id}>
                                    {item.displayName}
                                </Option>
                            ))}
                        </Select>

                        {hasChangeRolePermission ? <> <label className='margintop-7'>انتخاب نقش سیستمی</label> <Select
                            style={{ width: '100%' }}
                            placeholder="انتخاب نقش"
                            mode="multiple"
                            onChange={(value) => setRoleData(value)}
                            value={roleData}
                        >
                            {dropDownRoles?.map((item) => (
                                <Option key={item.id} value={item.id}>
                                    {item.displayName}
                                </Option>
                            ))}
                        </Select></> : null}

                        <div><Checkbox checked={data.isActive} onChange={() => setData({ ...data, isActive: !data.isActive })}>اکانت فعال/غیرفعال</Checkbox></div>

                        <div><Checkbox checked={data.isShareHolder} onChange={() => setData({ ...data, isShareHolder: !data.isShareHolder })}>سهام دار</Checkbox></div>

                        <div><Checkbox checked={data.isDeaded} onChange={() => setData({ ...data, isDeaded: !data.isDeaded })}>مرحوم</Checkbox></div>

                    </TabPane>

                    <TabPane tab="مدارک" key="2">
                        <MemberCertificate isHideUploadButton={true} isShowButton={true} data={data} setData={setData}></MemberCertificate>
                    </TabPane>

                </Tabs>
            </div>
        </div >
    )
}

export default Create
