import React, { Suspense, useEffect } from 'react'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import './scss/style.scss'
import 'antd/dist/antd.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import 'moment/locale/fa';
import axios from 'axios';
import { apiUrls } from './apiUrls.js';
import { setPermissions, resetPermissions } from './actions';
import { useDispatch } from 'react-redux'
import DefaultLayout from './layout/DefaultLayout';
import { toast } from 'react-toastify';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const baseURL = apiUrls.baseURL;

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem('damavandclub-token');

  useEffect(() => {
    if (token) {
      axios.get(`${baseURL}/user/getPermissions`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      }).then((response) => {
        dispatch(resetPermissions());
        dispatch(setPermissions(response.data.data));
      }).catch(() => {
        navigate('/login'); // هدایت به صفحه لاگین در صورت خطا
      });
    } else {
      navigate('/login'); // اگر توکن وجود نداشته باشد، به صفحه لاگین برو
    }
  }, [dispatch, token]);

  return (
    <div>
      <ToastContainer />
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route path="*" element={<DefaultLayout />} />
          <Route path="/404" element={<Page404 />} />
        </Routes>
      </Suspense>
    </div>
  )
}

const AppWrapper = () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default AppWrapper;





// import React, { Component, Suspense, useEffect } from 'react'
// import { HashRouter, Route, Routes, BrowserRouter, useNavigate } from 'react-router-dom'
// import './scss/style.scss'
// import 'antd/dist/antd.css';
// import 'react-toastify/dist/ReactToastify.css';
// import { ToastContainer } from 'react-toastify';
// import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
// import 'moment/locale/fa';
// import axios from 'axios';
// import { apiUrls } from './apiUrls.js';
// import { setPermissions, resetPermissions } from './actions';
// import { useSelector, useDispatch } from 'react-redux'
// import 'antd/dist/antd.css';
// import DefaultLayout from './layout/DefaultLayout';
// import { toast } from 'react-toastify';

// const loading = (
//   <div className="pt-3 text-center">
//     <div className="sk-spinner sk-spinner-pulse"></div>
//   </div>
// )

// // Containers
// // const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
// // Pages
// const Login = React.lazy(() => import('./views/pages/login/Login'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
// const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
// const baseURL = apiUrls.baseURL;


// const App = () => {

//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const token = localStorage.getItem('damavandclub-token')

//   axios.get(${baseURL}/user/getPermissions, {
//     headers: {
//       'Authorization': Bearer ${token},
//       'Content-Type': 'application/json',
//     }
//   }).then((response) => {
//     dispatch(resetPermissions());
//     dispatch(setPermissions(response.data.data));
//   }).catch(() => {
//     navigate(-1)
//   })

//   return (
//     <div>
//       <ToastContainer />
//       <BrowserRouter basename="/">
//         <Suspense fallback={loading}>
//           <Routes>
//             <Route exact path="/" name="Login Page" element={<Login />} />
//             <Route exact path="/login" name="Login Page" element={<Login />} />
//             <Route exact path="*" name="Home" element={<DefaultLayout />} />
//             <Route exact path="/register" name="Register Page" element={<Register />} />
//             {/* <Route exact path="/404" name="Page 404" element={<Page404 />} /> */}
//             <Route path="*" element={<Page404 />} />
//           </Routes>
//         </Suspense>
//       </BrowserRouter>
//     </div>

//   )
// }

// // function AppWrapper() {
// //   return (
// //     <Routes>
// //       <App />
// //     </Routes>
// //   );
// // }

// // export default AppWrapper

// // class App extends Component {

// //   render() {

// //     const dispatch = useDispatch();
// //     const token = localStorage.getItem('damavandclub-token')
// //     axios.get(${baseURL}/user/getPermissions, {
// //       headers: {
// //         'Authorization': Bearer ${token},
// //         'Content-Type': 'application/json',
// //       }
// //     }).then((response)=>{
// //       dispatch(setPermissions(response.data.data));
// //     })


// //     return (
// //       <div>
// //         <ToastContainer />
// //         <BrowserRouter>
// //           <Suspense fallback={loading}>
// //             <Routes>
// //               <Route exact path="/" name="Login Page" element={<Login />} />
// //               <Route exact path="/login" name="Login Page" element={<Login />} />
// //               <Route exact path="*" name="Home" element={<DefaultLayout />} />
// //               <Route exact path="/register" name="Register Page" element={<Register />} />
// //               {/* <Route exact path="/404" name="Page 404" element={<Page404 />} /> */}
// //               <Route exact path="/500" name="Page 500" element={<Page500 />} />
// //               <Route path="*" element={<Page404 />} />
// //             </Routes>
// //           </Suspense>
// //         </BrowserRouter>
// //       </div>

// //     )
// //   }
// // }

// export default App
