import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react'
import {
    CChartBar,
    CChartDoughnut,
    CChartLine,
    CChartPie,
    CChartPolarArea,
    CChartRadar,
} from '@coreui/react-chartjs'
import { DocsCallout } from 'src/components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox, Pagination, Table, Collapse, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { apiUrls } from '../../apiUrls.js';
import Program from "../../components/Program.js";
import { useNavigate } from 'react-router-dom';
import { UnlockOutlined, LockOutlined, EditOutlined, SearchOutlined, DeleteOutlined, RadarChartOutlined } from '@ant-design/icons';
const { Option } = Select;

const baseURL = apiUrls.baseURL;

const Roles = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState(0);
    const [count, setCount] = useState(0);
    const [isShowDeleteConfirmModalVisible, setIsShowDeleteConfirmModalVisible] = useState(false);
    const [filter, setFilter] = useState({ skip: 0, limit: 20, data: {} });
    const navigate = useNavigate();
    const permissions = useSelector((state) => state.permissions.permissions);

    const columns = [
        {
            title: 'ردیف',
            dataIndex: 'index',
            key: 'index',
            responsive: ['xl', 'lg'],
            width: 50,
            render: (_, record, index) => (
                <>{index + 1 + filter?.limit * filter?.skip}</>
            ),
        },
        {
            title: 'عنوان',
            key: 'nameFa',
            dataIndex: 'nameFa',
            width: 100,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs']
        },
        {
            title: 'عنوان انگلیسی',
            key: 'nameEn',
            dataIndex: 'nameEn',
            width: 10,
            responsive: ['xl', 'lg', 'md']
        },
        {
            title: 'عملیات',
            key: 'id',
            dataIndex: 'id',
            width: 30,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                <div className='row-container'>
                    <EditOutlined onClick={() => navigate('/security/role/' + record.id)} style={{ color: 'blue', fontSize: 30 }} />
                    {permissions.includes('setpermission') ? <RadarChartOutlined onClick={() => navigate('/security/permission/' + record.id)}
                        style={{ color: 'black', fontSize: 30 }} /> : null}
                    <DeleteOutlined onClick={() => {
                        setIsShowDeleteConfirmModalVisible(true)
                        setId(record.id)
                    }} style={{ color: 'red', fontSize: 30 }} />
                </div>
            ),
        },
    ];

    useEffect(() => {
        setLoading(true);
        fetchData()
    }, [filter.limit, filter.skip])

    const fetchData = async () => {

        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.post(`${baseURL}/role/get`, filter, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;
            setCount(result.count)
            setData(result.items);
            setLoading(false);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const deleteRole = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            await axios.delete(`${baseURL}/role/delete?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            fetchData()
            setIsShowDeleteConfirmModalVisible(false)
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {permissions.includes('createrole') ? <Button onClick={() => navigate('/security/role')} style={{ backgroundColor: 'rgb(44 0 151)', marginTop: 10, width: '100%', marginBottom: 10 }}
                type="primary"><label>ایجاد نقش</label></Button> : null}
            <Table
                size='small'
                style={{
                    textAlign: 'right',
                    marginTop: 10
                }}
                rowClassName={() => 'center'}
                columns={columns}
                bordered={true}
                dataSource={data}
                loading={loading}
                pagination={false}
                locale={{
                    emptyText: 'موردی یافت نشد',
                }}
            />
            <Pagination
                onChange={(pageNumber, limit) => setFilter({ skip: pageNumber > 0 ? pageNumber - 1 : pageNumber, limit: limit })}
                style={{ marginTop: 30, marginBottom: 5 }}
                current={filter.skip + 1}
                responsive={true}
                pageSize={filter.limit}
                showTotal={(total) => `تعداد کل: ${total} صفحه`}
                total={count} />
            <Modal
                title='تایید حذف'
                open={isShowDeleteConfirmModalVisible}
                closable={false}
                okButtonProps={null}
                footer={<div className='row-container'>
                    <Button onClick={() => setIsShowDeleteConfirmModalVisible(false)} style={{ width: '100%' }}>بستن</Button>
                    <Button type="primary"
                        onClick={() => deleteRole()}
                        style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(10, 23, 43)' }}>تایید</Button>
                </div>
                }
                onCancel={() => setIsShowDeleteConfirmModalVisible(false)}
            >
            </Modal>
        </div>
    )
}

export default Roles
