import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { FilePdfOutlined, PictureOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Tabs, Upload, Select, Spin } from 'antd';

const AmountFormat = ({ amount }) => {

  const [number, setNumber] = useState('');

  useEffect(() => {
    if (amount != null && amount != undefined) {
      const input = amount.toString().replace(/[^0-9]/g, '');
      const formattedNumber = input === '' ? '' : new Intl.NumberFormat().format(parseInt(input, 10));
      setNumber(formattedNumber);
    }
  }, [amount])

  return (
    <span>{number}</span>
  );
};

AmountFormat.propTypes = {
  amount: PropTypes.any
};

export default AmountFormat;
