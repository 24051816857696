
const initalState = {
  isShow: true,
}

const sidebarReducer = (state = initalState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
      //return state
    default:
      return state
  }
}

export default sidebarReducer;
