import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react'
import {
    CChartBar,
    CChartDoughnut,
    CChartLine,
    CChartPie,
    CChartPolarArea,
    CChartRadar,
} from '@coreui/react-chartjs'
import { DocsCallout } from 'src/components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox, Pagination, Table, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { apiUrls } from '../../apiUrls.js';
import Program from "../../components/Program.js";
import { useNavigate } from 'react-router-dom';
import { UnlockOutlined, LockOutlined, EditOutlined, SafetyCertificateOutlined, PoweroffOutlined } from '@ant-design/icons';
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";
const baseURL = apiUrls.baseURL;

const Trials = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingChangeToOfficial, setLoadingChangeToOfficial] = useState(false);
    const [loadingChangeToGuest, setLoadingChangeToGuest] = useState(false);
    const [memberData, setMemberData] = useState({});
    const [count, setCount] = useState(0);
    const [isShowChangeToOfficialModalVisible, setIsShowChangeToOfficialModalVisible] = useState(false);
    const [isShowChangeToGuestModalVisible, setIsShowChangeToGuestModalVisible] = useState(false);
    const [filter, setFilter] = useState({ skip: 0, limit: 20 });
    const [loadingCertificateReport, setLoadingCertificateReport] = useState(false);
    const [loadingJoinProgramReport, setLoadingJoinProgramReport] = useState(false);
    const [loadingFinancialReport, setLoadingFinancialReport] = useState(false);
    const navigate = useNavigate();

    const columns = [
        {
            title: 'ردیف',
            dataIndex: 'index',
            key: 'index',
            responsive: ['xl', 'lg'],
            width: 50,
            render: (_, record, index) => (
                <>{index + 1 + filter?.limit * filter?.skip}</>
            ),
        },
        {
            title: 'نام',
            key: 'firstName',
            dataIndex: 'firstName',
            width: 100,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                <div>{record.firstName} <br /> {record.lastName}</div>
            ),
        },
        {
            title: 'تلفن/کدملی',
            key: 'phoneNumber',
            dataIndex: 'phoneNumber',
            width: 10,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                <div>{record.phoneNumber} <br /> {record.nationalCode}</div>
            ),
        },
        {
            title: 'تصویر',
            key: 'profilePhotoUrl',
            dataIndex: 'profilePhotoUrl',
            width: 100,
            responsive: ['xl', 'lg', 'md'],
            render: (text, record) => (
                record.profilePhotoUrl !== null ? <a rel="noreferrer" href={record.profilePhotoUrl} target='_blank'>
                    <img style={{ borderRadius: 50 }} width={100} height={100} src={record.profilePhotoUrl}>
                    </img></a> : 'بدون تصویر'
            ),
        },
        {
            title: 'تبدیل به رسمی',
            key: 'id',
            dataIndex: 'id',
            width: 100,
            responsive: ['xl', 'lg', 'md'],
            render: (text, record) => (
                <SafetyCertificateOutlined onClick={() => {
                    setIsShowChangeToOfficialModalVisible(true)
                    setMemberData({ memberName: record.firstName + " " + record.lastName, memberId: record.id })
                }} style={{ color: 'green', fontSize: 30 }} />
            ),
        },
        {
            title: 'تبدیل به مهمان',
            key: 'id',
            dataIndex: 'id',
            width: 100,
            responsive: ['xl', 'lg', 'md'],
            render: (text, record) => (
                <PoweroffOutlined onClick={() => {
                    setIsShowChangeToGuestModalVisible(true)
                    setMemberData({ memberName: record.firstName + " " + record.lastName, memberId: record.id })
                }} style={{ color: 'red', fontSize: 30 }} />
            ),
        },
        {
            title: 'جزئیات',
            key: 'id',
            dataIndex: 'id',
            width: 100,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                <a rel='noreferrer' href={'/members/trialdetail/' + record.id}><EditOutlined style={{ color: 'blue', fontSize: 30 }} /></a>
            ),
        },
    ];

    useEffect(() => {
        setLoading(true);
        fetchData()
    }, [filter])

    const fetchData = async () => {

        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getTrialMembers?skip=${filter.skip}&limit=${filter.limit}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;
            setCount(result.count)
            setData(result.items);
            setLoading(false);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const changeToOfficial = async () => {
        setLoadingChangeToOfficial(true)
        const token = localStorage.getItem('damavandclub-token')
        try {
            await axios.get(`${baseURL}/user/SetOfficialMember?id=${memberData.memberId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            toast.success("با موفقیت ثبت شد", {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            fetchData()
            setIsShowChangeToOfficialModalVisible(false)
            setLoadingChangeToOfficial(false)
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoadingChangeToOfficial(false);
        } finally {
            setLoadingChangeToOfficial(false);
        }
    };

    const changeToGuest = async () => {
        setLoadingChangeToGuest(true)
        const token = localStorage.getItem('damavandclub-token')
        try {
            await axios.get(`${baseURL}/user/SetGuestMember?id=${memberData.memberId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            toast.success("با موفقیت ثبت شد", {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            fetchData()
            setIsShowChangeToGuestModalVisible(false)
            setLoadingChangeToGuest(false)
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoadingChangeToGuest(false);
        } finally {
            setLoadingChangeToGuest(false);
        }
    };

    const getJoinProgramReport = async () => {

        setLoadingJoinProgramReport(true)
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getTrialMemberJoinProgramReport`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }, 'responseType': 'blob'
            });
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');

            setLoadingJoinProgramReport(false);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoadingJoinProgramReport(false);
        } finally {
            setLoadingJoinProgramReport(false);
        }
    };

    const getFinancialReport = async () => {

        setLoadingFinancialReport(true)
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getTrialFinancialReport`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }, 'responseType': 'blob'
            });
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');

            setLoadingFinancialReport(false);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoadingFinancialReport(false);
        } finally {
            setLoadingFinancialReport(false);
        }
    };

    const getCertificateReport = async () => {

        setLoadingCertificateReport(true)
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getTrialMembersReport`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }, 'responseType': 'blob'
            });
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');

            setLoadingCertificateReport(false);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoadingCertificateReport(false);
        } finally {
            setLoadingCertificateReport(false);
        }
    };

    return (
        <div>
            <HideSidebarInMobileMode />
            <div style={{ margin: 0 }} className='row'>
                <Button onClick={() => getCertificateReport()} loading={loadingCertificateReport}
                    style={{ backgroundColor: 'rgb(20 30 27)', marginTop: 10 }}
                    type="primary"><label>گزارش دورهای گذرانده شده</label></Button>
                <Button onClick={() => getJoinProgramReport()} loading={loadingJoinProgramReport}
                    style={{ backgroundColor: 'rgb(229 131 27)', marginTop: 10 }}
                    type="primary"><label>گزارش شرکت در برنامه ها</label></Button>
                <Button onClick={() => getFinancialReport()} loading={loadingFinancialReport}
                    style={{ backgroundColor: 'rgb(27 119 229)', marginTop: 10, marginBottom: 10 }}
                    type="primary"><label>گزارش مالی</label></Button>
            </div>

            <Table
                size='small'
                style={{
                    textAlign: 'right',
                    marginTop: 10
                }}
                rowClassName={() => 'center'}
                columns={columns}
                bordered={true}
                dataSource={data}
                loading={loading}
                pagination={false}
                locale={{
                    emptyText: 'موردی یافت نشد',
                }}
            />
            <Pagination
                onChange={(pageNumber, limit) => setFilter({ skip: pageNumber > 0 ? pageNumber - 1 : pageNumber, limit: limit })}
                style={{ marginTop: 30, marginBottom: 5 }}
                current={filter.skip + 1}
                responsive={true}
                pageSize={filter.limit}
                showTotal={(total) => `تعداد کل: ${total} عضو`}
                total={count} />

            <Modal
                title={`تبدیل به رسمی ${memberData.memberName}`}
                open={isShowChangeToOfficialModalVisible}
                closable={false}
                okButtonProps={null}
                footer={<div className='row-container'>
                    <Button onClick={() => setIsShowChangeToOfficialModalVisible(false)} style={{ width: '100%' }}>بستن</Button>
                    <Button type="primary" loading={loadingChangeToOfficial}
                        onClick={() => changeToOfficial()}
                        style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(10, 23, 43)' }}>تایید</Button>
                </div>}
            >
                آیا از رسمی شدن این عضو مطمئن هستید؟
            </Modal>

            <Modal
                title={`تبدیل به مهمان ${memberData.memberName}`}
                open={isShowChangeToGuestModalVisible}
                closable={false}
                okButtonProps={null}
                footer={<div className='row-container'>
                    <Button onClick={() => setIsShowChangeToGuestModalVisible(false)} style={{ width: '100%' }}>بستن</Button>
                    <Button type="primary" loading={loadingChangeToGuest}
                        onClick={() => changeToGuest()}
                        style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(10, 23, 43)' }}>تایید</Button>
                </div>}
            >
                آیا از مهمان شدن این عضو مطمئن هستید؟
            </Modal>
        </div>
    )
}

export default Trials
