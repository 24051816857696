import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Table, Pagination, Modal } from 'antd';
import { apiUrls } from '../apiUrls.js';
import { toast } from 'react-toastify';
import AmountFormat from "../components/AmountFormat.js";

const { Option } = Select;
const baseURL = apiUrls.baseURL;

const UnJoinProgram = React.memo(({ data, onRefresh }) => {

    const [isShowModalVisible, setIsShowModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cancelPolicy, setCancelPolicy] = useState({});

    useEffect(() => {

    }, [])

    const unJoin = async (id) => {
        setLoading(true);
        try {
            const token = localStorage.getItem('damavandclub-token')
            await axios.get(`${apiUrls.baseURL}/program/unjoinProgram?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            onRefresh();

            toast.success("با موفقیت ثبت شد", {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setIsShowModalVisible(false)
            setLoading(false);
        } catch (error) {
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setLoading(false);
            setIsShowModalVisible(false)
        } finally {
            setLoading(false);
            setIsShowModalVisible(false)
        }
    };

    const showPolicyModal = async (programId) => {
        setIsShowModalVisible(true);

        try {
            const token = localStorage.getItem('damavandclub-token')
            var response = await axios.get(`${apiUrls.baseURL}/WorkingGroupCategory/GetCancelPolicy?programId=${programId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            var result = response.data.data;
            setCancelPolicy(result);
        } catch (error) {
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } finally {
        }
    }

    return (
        <>
            <Button size='small' loading={loading} onClick={() => showPolicyModal(data.id)} style={{ backgroundColor: 'rgb(195, 42, 26)', marginTop: 10, width: '100%' }}
                type="primary">لغو حضور</Button>

            <Modal
                title={`تایید لغو حضور برنامه:  ${data.nameFa}`}
                open={isShowModalVisible}
                closable={false}
                okButtonProps={null}
                footer={<div className='row-container'>
                    <Button onClick={() => { setIsShowModalVisible(false); setCancelPolicy({}) }} style={{ width: '100%' }}>بستن</Button>
                    <Button type="primary" loading={loading}
                        onClick={() => unJoin(data.id)}
                        style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(10, 23, 43)' }}>تایید</Button>
                </div>}
                onCancel={() => setIsShowModalVisible(false)}
            >
                {cancelPolicy !== undefined && cancelPolicy !== null && <>
                    <table style={{ border: '1px solid gray', width: '100%', textAlign: 'center', marginTop: 20 }}>
                        <tr style={{ border: '1px solid gray' }}>
                            <th>تعداد روزهای مانده تا شروع برنامه</th>
                            <th>درصد کسر از هزینه پرداخت شده</th>
                            <th>مبلغ پرداخت شده(ریال)</th>
                            <th>مبلغ قابل برگشت(ریال)</th>
                        </tr>
                        <tr style={{ border: '1px solid gray' }}>
                            <td>{cancelPolicy.remainingDaysToStartProgram}</td>
                            <td>{cancelPolicy.costReductionPercentage}</td>
                            <td><AmountFormat amount={cancelPolicy.paidAmount} /></td>
                            <td><AmountFormat amount={cancelPolicy.returnedAmount} /></td>
                        </tr>
                    </table></>}

                <br></br>
                <br></br>
                <div className='center'>آیا از لغو حضور در این برنامه مطمئن هستید؟</div>
            </Modal>
        </>
    );
});

UnJoinProgram.propTypes = {
    data: PropTypes.any,
    onRefresh: PropTypes.any
};

UnJoinProgram.displayName = 'UnJoinProgram';

export default UnJoinProgram;
