/* eslint-disable default-case */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Modal, Select } from 'antd';
import { apiUrls } from '../apiUrls.js';
import { toast } from 'react-toastify';
import AmountFormat from "../components/AmountFormat.js";
import AddMemberProgram from "../components/AddMemberProgram.js";
import JoinProgram from "../components/JoinProgram.js";
import UnJoinProgram from "../components/UnJoinProgram.js";
import ProgramPhotos from "../components/ProgramPhotos.js";
import EndOfProgram from "../components/EndOfProgram.js";
import ProgramManageMembers from "../components/ProgramManageMembers.js";
import ProgramComments from "../components/ProgramComments.js";
import ProgramPermissions from "../components/ProgramPermissions.js";
import ProgramVideos from "../components/ProgramVideos.js";
import ProgramOtherMembers from "../components/ProgramOtherMembers.js";
import ProgramSetComment from "../components/ProgramSetComment.js";
import HideSidebarInMobileMode from "../components/HideSidebarInMobileMode.js";

const Program = ({ item, color, programKey, onRefresh, isHideApproveButton,
  isShowSetPermissionJoin, isShowPolicy, isShowAddMember,
  isShowOtherProgramCosts, isShowAssistantLeaderAmount,
  isShowCreateDate, isShowRemainingCapacity, isShowOtherMember }) => {

  const navigate = useNavigate();
  const permissions = useSelector((state) => state.permissions.permissions);
  const [loadingJoin, setLoadingJoin] = useState(false);
  const [loadingApproveProgram, setLoadingApproveProgram] = useState(false);
  const [loadingCancelProgram, setLoadingCancelProgram] = useState(false);
  const [isShowDescriptionModalVisible, setIsShowDescriptionModalVisible] = useState(false);
  const [isShowApproveModalVisible, setIsShowApproveModalVisible] = useState(false);
  const [isShowLeaderInfoModalVisible, setIsShowLeaderInfoModalVisible] = useState(false);
  const [leaderInfoData, setLeaderInfoData] = useState({});
  const [isShowCancelModalVisible, setIsShowCancelModalVisible] = useState(false);
  const { Option } = Select;

  useEffect(() => {

  }, [])

  const navigateToEdit = (id) => {
    navigate(`/programform/${id}`);
  }

  const approveProgram = async () => {
    setLoadingApproveProgram(true);
    try {
      const token = localStorage.getItem('damavandclub-token')
      await axios.get(`${apiUrls.baseURL}/program/ApproveOrUnapprove?id=${item.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      onRefresh();
      toast.success("با موفقیت تایید شد", {
        position: 'bottom-right',
        autoClose: 3000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setIsShowApproveModalVisible(false);
      setLoadingApproveProgram(false);
    } catch (error) {
      toast.error(error.response.data.Msg, {
        position: 'bottom-right',
        autoClose: 3000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setLoadingApproveProgram(false);
    } finally {
      setLoadingApproveProgram(false);
    }
  };

  const cancelProgram = async () => {
    setLoadingCancelProgram(true);
    try {
      const token = localStorage.getItem('damavandclub-token')
      await axios.delete(`${apiUrls.baseURL}/program/delete?id=${item.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      onRefresh();
      toast.success("با موفقیت لغو شد", {
        position: 'bottom-right',
        autoClose: 3000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setIsShowCancelModalVisible(false);
      setLoadingCancelProgram(false);
    } catch (error) {
      toast.error(error.response.data.Msg, {
        position: 'bottom-right',
        autoClose: 3000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setLoadingCancelProgram(false);
      setIsShowCancelModalVisible(false);
    } finally {
      setLoadingCancelProgram(false);
      setIsShowCancelModalVisible(false);
    }
  };

  const getLeaderInfo = async () => {
    setIsShowLeaderInfoModalVisible(true);
    try {
      const token = localStorage.getItem('damavandclub-token')
      let response = await axios.get(`${apiUrls.baseURL}/user/getleaderinfo?id=${item.leaderId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setLeaderInfoData(response.data.data)
    } catch (error) {
      toast.error(error.response.data.Msg, {
        position: 'bottom-right',
        autoClose: 3000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setLoadingJoin(false);
    } finally {
      setLoadingJoin(false);
    }
  };

  const hasJoinPermission = () => {
    switch (programKey) {
      case 'mountaineering':
        return permissions.includes('joinmountaineeringprogram');
      case 'climbing':
        return permissions.includes('joinclimbingprogram');
      case 'iceclimbing':
        return permissions.includes('joiniceclimbingprogram');
      case 'caving':
        return permissions.includes('joincavingprogram');
      case 'bicycling':
        return permissions.includes('joinbicyclingprogram');
      case 'canyoneering':
        return permissions.includes('joincanyoningprogram');
      case 'skyrunning':
        return permissions.includes('joinskyrunningprogram');
      case 'trial':
        return permissions.includes('jointrialprogram');
      case 'education':
        return permissions.includes('joineducationclassess');
      default:
        return false;
    }
  }

  const hasViewCommentPermission = () => {
    switch (programKey) {
      case 'mountaineering':
        return permissions.includes('viewmountaineeringcomments');
      case 'climbing':
        return permissions.includes('viewclimbingcomments');
      case 'caving':
        return permissions.includes('viewcavingcomments');
      case 'iceclimbing':
        return permissions.includes('viewiceclimbingcomments');
      case 'trial':
        return permissions.includes('viewtrialcomments');
      case 'bicycling':
        return permissions.includes('viewbicyclecomments');
      case 'canyoneering':
        return permissions.includes('viewcanyoningcomments');
      case 'skyrunning':
        return permissions.includes('viewskyrunningcomments');
      case 'education':
        return permissions.includes('vieweducationcomments');
      default:
        return false;
    }
  }

  return <div className='col-md-12 col-lg-12 col-sm-12 container-box' style={{
    backgroundColor: color
  }}>
    <div className='row-container'>
      <div className='conditional-center' style={{ flex: 1, fontSize: 18 }}>{item.nameFa}</div>
      {item.rate !== 0 ? <div>امتیاز: <span className='red'>{item.rate}</span></div> : null}
    </div>

    <div className="horizontal-line-program"></div>
    <div>{item.startDate === item.endDate ? <span>{item.startDate}</span> : <><span>{item.startDate}</span> تا <span>{item.endDate}</span></>}</div>

    <div>مهلت ثبت نام: {<span className='red'>{item.deadlineDate}</span>}</div>

    {item.hasAccessApprove && isShowCreateDate && <div>تاریخ ثبت: {<span>{item.persianCreateDate}</span>}</div>}

    {programKey != 'education' ? <span>سرپرست: <span className='green'>{item.leaderName}(<span onClick={() => getLeaderInfo()}><span className='blue'>نمایش اطلاعات</span></span>)</span></span> :
      <span>مدرس: <span className='green'>{item.leaderName}</span></span>}

    {item.programAssistantLeaderResponses !== '' && programKey != 'education' && <div>کمک سرپرستان: {item.programAssistantLeaderResponses}</div>}

    {item.programAssistantLeaderResponses !== '' && programKey == 'education' && <div> مربیان: {item.programAssistantLeaderResponses}</div>}

    {programKey != 'education' && <span>سطح برنامه: {item.programLevelName}</span>}

    {(isShowRemainingCapacity || item.hasAccessAbsentPresentEditCancel || item.workingGroupCategoryKey != 'Education') && <div>ظرفیت باقی مانده: <span className='red'>{item.remainingCapacity}</span></div>}

    {(item.amount !== 0 && item.amount !== null) &&
      <div>هزینه برنامه: <AmountFormat amount={item.amount} /> ریال</div>}

    {item.discountAmount !== null &&
      <div>{item.discountTitle} ریال</div>}

    {item.programRequirementsResponses !== "" && <div><span>پیش نیازها:</span>{item.programRequirementsResponses}</div>}

    {item.isActive && (isShowOtherMember || item.hasAccessAbsentPresentEditCancel || item.workingGroupCategoryKey != 'Education') && <ProgramOtherMembers color={color} data={item}></ProgramOtherMembers>}

    <div> {item.hasAccessAbsentPresentEditCancel && item.isActive && <ProgramManageMembers color={color} data={item} onRefresh={onRefresh}></ProgramManageMembers>}</div>

    {item.hasAccessAbsentPresentEditCancel && item.isActive && isShowSetPermissionJoin && <ProgramPermissions data={item}></ProgramPermissions>}

    {item.hasAccessAbsentPresentEditCancel && item.isActive && !item.isDone && item.isVisibleCancel && <AddMemberProgram data={item} onRefresh={onRefresh}></AddMemberProgram>}

    {item.isRejected ? <div className='red'>حذف از برنامه به دلیل: {item.rejectReason}</div> : null}

    {item.hasAccessApprove && item.isActive && !item.isDone && <EndOfProgram isShowOtherProgramCosts={isShowOtherProgramCosts}
      isShowAssistantLeaderAmount={isShowAssistantLeaderAmount}
      data={item}
      onRefresh={onRefresh}></EndOfProgram>}

    {item.isVisibleJoin && hasJoinPermission() && !item.isRejected && !item.isJoin && item.isActive && <JoinProgram data={item} onRefresh={onRefresh}>
    </JoinProgram>}

    {item.isJoin &&

      <div className='row-container'>

        {item.isVisibleJoin && <UnJoinProgram data={item} onRefresh={onRefresh}></UnJoinProgram>}

        {!item.isAbsent && <ProgramSetComment data={item}></ProgramSetComment>}

      </div>}

    {item.isAbsent && <div style={{
      position: 'absolute',
      textAlign: 'center',
      backgroundColor: 'black',
      padding: 5,
      color: 'red',
      fontSize: 20,
      marginTop: 185,
      transform: 'rotate(305deg)'
    }}>شما در این برنامه غایب بوده اید</div>}

    {!isHideApproveButton && item.hasAccessApprove && !item.isActive ? <Button size='small' className='margintop-5' onClick={() => setIsShowApproveModalVisible(true)}
      style={{ backgroundColor: 'rgb(12, 139, 4)', width: '100%' }}
      type="primary"><label>تایید برنامه</label></Button> : null}

    {item.hasAccessAbsentPresentEditCancel ? <Button className='margintop-5' size='small' onClick={() => navigateToEdit(item.id)}
      style={{ backgroundColor: 'rgb(0, 0, 0)', width: '100%' }}
      type="primary"><label>ویرایش</label></Button> : null}

    {item.hasAccessAbsentPresentEditCancel && item.isVisibleCancel ? <Button className='margintop-5' size='small' onClick={() => setIsShowCancelModalVisible(item.id)}
      style={{ backgroundColor: 'rgb(243, 43, 43)', width: '100%' }}
      type="primary"><label>لغو برنامه</label></Button> : null}

    {item.isLeader && !item.isActive ? <div style={{
      //position: 'absolute',
      textAlign: 'center',
      backgroundColor: 'lightgray',
      padding: 20,
      color: 'green',
      fontSize: 25,
      marginTop: 15,
      width: '100%',
      transform: 'rotate(0deg)'
    }}>در انتظار تایید مدیر کارگروه</div> : null}

    <div className='row-container margintop-5'>
      <Button size='small' onClick={() => setIsShowDescriptionModalVisible(true)}
        style={{ backgroundColor: '#77a4f5' }}
        type="primary"><label>توضیحات</label></Button>

      {item.hasPhotos && <ProgramPhotos data={item}></ProgramPhotos>}

      {hasViewCommentPermission() && <ProgramComments data={item} programKey={programKey}></ProgramComments>}

      {item.hasVideo && <ProgramVideos data={item}></ProgramVideos>}

      {item.reportFile?.url && <a target='_blank' rel="noreferrer" href={item.reportFile.url}><Button size='small'
        style={{ backgroundColor: 'rgb(211 95 16 / 85%)' }}
        type="primary"><label>گزارش برنامه</label></Button></a>}
    </div>

    <Modal
      title={`توضیحات برنامه :  ${item.nameFa}`}
      open={isShowDescriptionModalVisible}
      closable={false}
      okButtonProps={null}
      bodyStyle={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
      footer={
        <Button onClick={() => setIsShowDescriptionModalVisible(false)} style={{ width: '100%' }}>بستن</Button>
      }
      onCancel={() => setIsShowDescriptionModalVisible(false)}
    >
      <textarea style={{
        width: '100%',
        height: '300PX',
        overflow: 'hidden',
        resize: 'none',
        border: 'white'
      }} disabled={true}>{item.description}</textarea>

    </Modal>

    <Modal
      title={`اطلاعات سرپرست:  ${item.leaderName}`}
      open={isShowLeaderInfoModalVisible}
      closable={false}
      okButtonProps={null}
      footer={
        <Button onClick={() => setIsShowLeaderInfoModalVisible(false)} style={{ width: '100%' }}>بستن</Button>
      }
      onCancel={() => setIsShowLeaderInfoModalVisible(false)}
    >
      <div>سال عضویت: {leaderInfoData.startMemberingDate}</div>
      <div>شماره همراه: {leaderInfoData.phoneNumber}</div>
      <div>مدارک: {leaderInfoData.certificates}</div>
    </Modal>

    <Modal
      title={`تایید برنامه:  ${item.nameFa}`}
      open={isShowApproveModalVisible}
      closable={false}
      okButtonProps={null}
      footer={<div className='row-container'>
        <Button onClick={() => setIsShowApproveModalVisible(false)} style={{ width: '100%' }}>بستن</Button>
        <Button type="primary" loading={loadingApproveProgram}
          onClick={() => approveProgram()}
          style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(10, 23, 43)' }}>تایید</Button>
      </div>}
      onCancel={() => setIsShowApproveModalVisible(false)}
    >
      آیا از تایید این برنامه مطمئن هستید؟
    </Modal>

    <Modal
      title={`لغو برنامه:  ${item.nameFa}`}
      open={isShowCancelModalVisible}
      closable={false}
      okButtonProps={null}
      footer={<div className='row-container'>
        <Button onClick={() => setIsShowCancelModalVisible(false)} style={{ width: '100%' }}>بستن</Button>
        <Button type="primary" loading={loadingCancelProgram}
          onClick={() => cancelProgram()}
          style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(10, 23, 43)' }}>تایید</Button>
      </div>}
      onCancel={() => setIsShowCancelModalVisible(false)}
    >
      آیا از لغو این برنامه مطمئن هستید؟
    </Modal>

  </div >
};

Program.propTypes = {
  item: PropTypes.any,
  color: PropTypes.string,
  programKey: PropTypes.string,
  onRefresh: PropTypes.any,
  isHideApproveButton: PropTypes.bool,
  isShowSetPermissionJoin: PropTypes.bool,
  isShowPolicy: PropTypes.bool,
  isShowAddMember: PropTypes.bool,
  isShowOtherProgramCosts: PropTypes.bool,
  isShowAssistantLeaderAmount: PropTypes.bool,
  isShowCreateDate: PropTypes.bool,
  isShowRemainingCapacity: PropTypes.bool,
  isShowOtherMember: PropTypes.bool,
};

export default Program;
