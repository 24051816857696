import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react'
import {
    CChartBar,
    CChartDoughnut,
    CChartLine,
    CChartPie,
    CChartPolarArea,
    CChartRadar,
} from '@coreui/react-chartjs'
import { DocsCallout } from 'src/components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Flex, Input, Upload, Select, Spin, Checkbox } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import jquery from 'jquery';
import BraftEditor from 'braft-editor';
import { FilePdfOutlined } from '@ant-design/icons';
import { apiUrls } from '../../apiUrls.js';
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useNavigate } from 'react-router-dom';
import InputNumberFormat from "../../components/InputNumberFormat.js";

const { Option } = Select;
const baseURL = apiUrls.baseURL;

const StoreForm = () => {

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const permissions = useSelector((state) => state.permissions.permissions);
    const accessShowInSite = permissions.includes('createStore');
    const navigate = useNavigate();
    const [storePhoto, setStorePhoto] = useState('');
    //eslint-disable-next-line react-hooks/rules-of-hooks  
    const { id } = useParams();

    useEffect(() => {
        if (id !== undefined)
            fetchData(id)
    }, [])

    const fetchData = async (id) => {
        setLoading(true);
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/Store/getbyid?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            var result = response.data.data;
            setStorePhoto(result.photoUrl)
            setData(result);
            setLoading(false);
        } catch (error) {
            //alert(error)
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const mediaProps = {
        name: 'file',
        action: `${baseURL}/media/UploadFiles?containerkey=store&isPublic=true`, // Replace with your upload endpoint
        headers: {
            authorization: 'Bearer ' + localStorage.getItem('damavandclub-token'),
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                //console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                let response = info.file.response.data[0];
                setStorePhoto(response.url)
                setData({ ...data, mediaId: response.id })
                jquery(".ant-upload-list-text-container").remove();
            } else if (info.file.status === 'error') {
                return toast.warning(info.file.response.Msg, {
                    position: 'bottom-right',
                    autoClose: 3000, // milliseconds
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  });
            }
        },
    };

    const removeMedia = () => {
        const newState = { ...data };
        newState.mediaId = null;
        setStorePhoto(null)
        setData(newState)
    };

    const save = async () => {
        setLoadingSave(true);
        try {
            const token = localStorage.getItem('damavandclub-token')

            if (id !== undefined) {
                await axios.post(`${baseURL}/Store/update?id=${id}`, data, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                })
                await axios.post(`${baseURL}/Store/updatemedias?id=${id}&key=Store`, data.medias.map(x => x.id), {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                })
            }

            else {
                let response = await axios.post(`${baseURL}/Store/create`, data, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                })
                await axios.post(`${baseURL}/Store/createmedias?id=${response.data.data}&key=Store`, data.medias.map(x => x.id), {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                })
            }



            toast.success("تغییرات با موفقیت ذخیره شد", {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } catch (error) {
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } finally {
            setLoadingSave(false);
        }
    }

    if (loading) {
        return <Spin size="large" />
    }

    return (
        <div className='row'>
            <HideSidebarInMobileMode />
            <div style={{
                backgroundColor: 'white',
                flexDirection: 'column',
                display: 'flex',
                flex: 1,
                height: '100%',
                padding: 10
            }}>
                <Button onClick={() => save()} loading={loadingSave} style={{ backgroundColor: 'rgb(0 151 46)', marginTop: 10, width: '100%', marginBottom: 10 }}
                    type="primary"><label>ذخیره یا ویرایش</label></Button>

                <Tabs>

                    <TabList>
                        <Tab>فارسی</Tab>
                        <Tab>انگلیسی</Tab>
                    </TabList>

                    <TabPanel>
                        <label>عنوان</label>
                        <Input value={data.nameFa} onChange={(event) => setData({ ...data, nameFa: event.target.value })} style={{ marginTop: 5 }} size="large" />
                        <label className='margintop-18'>هزینه اجاره(ریال) با اعداد انگلیسی وارد کنید</label>
                        <InputNumberFormat value={data.price} onValueChange={(value) => setData({ ...data, price: value })} />
                        <label className='margintop-18'>تعداد</label>
                        <Input style={{ textAlign: 'right', marginTop: 5 }} type='number' value={data.count} onChange={(event) => setData({ ...data, count: event.target.value })} size="large" />
                        <Checkbox checked={data.isActive} onChange={() => setData({ ...data, isActive: !data.isActive })}>فعال/غیرفعال</Checkbox>

                        <Upload
                            {...mediaProps}
                        >
                            <Button icon={<UploadOutlined />} loading={loadingSave} style={{ backgroundColor: 'rgb(0 20 46)', marginTop: 10, width: '30%', marginBottom: 10 }}
                                type="primary" ><label>انتخاب تصویر</label></Button>
                        </Upload>
                        {storePhoto != null && storePhoto != '' && <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <a rel='noreferrer' target='_blank' href={storePhoto}><img className='margintop-7' style={{ height: 200, width: 200 }} src={storePhoto}></img></a>
                                <Button className='margintop-5' onClick={() => removeMedia()} type='danger'>حذف</Button>
                            </div>
                        </div>}
                    </TabPanel>

                    <TabPanel>
                        <label>عنوان</label>
                        <Input value={data.nameEn} onChange={(event) => setData({ ...data, nameEn: event.target.value })} style={{ marginTop: 5 }} size="large" />
                    </TabPanel>

                    <TabPanel>

                    </TabPanel>
                </Tabs>

            </div>
        </div >
    )
}

export default StoreForm
