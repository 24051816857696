
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox, Pagination, Table, Collapse } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { apiUrls } from '../../apiUrls.js';
import Program from "../../components/Program.js";
import { useNavigate } from 'react-router-dom';
import { UnlockOutlined, LockOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";
const { Option } = Select;

const baseURL = apiUrls.baseURL;

const Total = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [count, setCount] = useState(0);
    const [filter, setFilter] = useState({ skip: 0, limit: 10, data: {} });
    const [dropDownType, setDropDownTypes] = useState([]);
    const [dropDownCertificates, setDropDownCertificates] = useState([]);
    const navigate = useNavigate();

    const columns = [
        {
            title: 'ردیف',
            dataIndex: 'index',
            key: 'index',
            responsive: ['xl', 'lg'],
            width: 50,
            render: (_, record, index) => (
                <>{index + 1 + filter?.limit * filter?.skip}</>
            ),
        },
        {
            title: 'نام',
            key: 'firstName',
            dataIndex: 'firstName',
            width: 100,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                <div>{record.firstName} <br /> {record.lastName}</div>
            ),
        },
        {
            title: 'تلفن/کدملی',
            key: 'phoneNumber',
            dataIndex: 'phoneNumber',
            width: 10,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                <div>{record.phoneNumber} <br /> {record.nationalCode}</div>
            ),
        },
        {
            title: 'تصویر',
            key: 'profilePhotoUrl',
            dataIndex: 'profilePhotoUrl',
            width: 100,
            responsive: ['xl', 'lg', 'md'],
            render: (text, record) => (
                record.profilePhotoUrl !== null ? <a rel="noreferrer" href={record.profilePhotoUrl} target='_blank'>
                    <img style={{ borderRadius: 50 }} width={100} height={100} src={record.profilePhotoUrl}>
                    </img></a> : 'بدون تصویر'
            ),
        },
        {
            title: 'نوع عضویت',
            key: 'type',
            dataIndex: 'type',
            width: 100,
            responsive: ['xl', 'lg', 'md'],
            render: (text, record) => (
                <>
                    <div>{record.type}</div>
                    {record.isDeaded && 'مرحوم'}
                </>

            ),
        },
        {
            title: 'وضعیت اکانت',
            key: 'isActive',
            dataIndex: 'isActive',
            width: 100,
            responsive: ['xl', 'lg', 'md'],
            render: (text, record) => (
                record.isActive ? <UnlockOutlined style={{ color: 'green', fontSize: 30 }} /> : <LockOutlined style={{ color: 'red', fontSize: 30 }} />
            ),
        },
        {
            title: 'جزئیات',
            key: 'id',
            dataIndex: 'id',
            width: 100,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                <EditOutlined onClick={() => {
                    window.open('/members/detail/' + record.id)
                }} style={{ color: 'blue', fontSize: 30 }}>  </EditOutlined>
            ),
        },
    ];

    useEffect(() => {
        setLoading(true);
        fetchMemberTypes()
        fetchCertificates()
        fetchData()
    }, [reload])

    const fetchMemberTypes = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getTypeDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })
            var result = response.data.data;
            setDropDownTypes(result)
        } catch (error) {
        } finally {
        }
    };

    const fetchCertificates = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getCertificateDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })

            var result = response.data.data;
            setDropDownCertificates(result);
        } catch (error) {
        } finally {
        }
    }

    const fetchData = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.post(`${baseURL}/user/getmembers`, filter, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;
            result.items.map(item => {
                item.key = item.id
            })
            setCount(result.count)
            setData(result.items);
            setLoading(false);
            setReload(false)
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
            setReload(false)
        } finally {
            setLoading(false);
            setReload(false)
        }
    };

    const getExcel = async () => {

        setLoadingExcel(true)
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.post(`${baseURL}/user/getMembersExcelReport`, filter, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }, 'responseType': 'blob'
            });
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');

            setLoadingExcel(false);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoadingExcel(false);
        } finally {
            setLoadingExcel(false);
        }
    };

    return (
        <div>
            <HideSidebarInMobileMode />
            <Collapse defaultActiveKey={['0']}>
                <Collapse.Panel header='جستجو ' key='1'>
                    <div className='row'>
                        <div className='col-lg-4 col-sm-12'>
                            <Select
                                style={{ width: '100%' }}
                                allowClear={true}
                                placeholder="انتخاب نوع عضویت"
                                onChange={(value) => {
                                    setFilter({ ...filter, data: { ...filter.data, type: value } })
                                }}
                                value={data.type}
                            >
                                {dropDownType?.map((item) => (
                                    <Option key={item.id} value={item.id}>
                                        {item.displayName}
                                    </Option>
                                ))}
                            </Select>

                        </div>

                        <div className='col-lg-4 col-sm-12'>
                            <Select
                                showSearch
                                style={{ width: '100%', marginBottom: 5 }}
                                placeholder="انتخاب مدرک"
                                allowClear={true}
                                optionFilterProp="label"
                                notFoundContent="لطفا کمی صبر کنید..."
                                onChange={(value) => {
                                    setFilter({ ...filter, data: { ...filter.data, certificateId: value } })
                                }}
                            >
                                {dropDownCertificates?.map((item) => (
                                    <Option key={item.id} label={item.displayName} value={item.id}>
                                        {item.displayName}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>


                    <Button onClick={() => setReload(true)} loading={loading} style={{ backgroundColor: 'rgb(0 39 151)', marginTop: 10, width: '100%', marginBottom: 10 }}
                        type="primary"><label>جستجو</label></Button>
                    <Button onClick={() => getExcel()} loading={loadingExcel} style={{ backgroundColor: 'rgb(229 131 27)', marginTop: 10, width: '100%', marginBottom: 10 }}
                        type="primary"><label>خروجی اکسل</label></Button>
                </Collapse.Panel>
            </Collapse>
            <div className="row-container margintop-5">

                <div style={{ border: '1px solid lightgray', marginTop: 5 }}><SearchOutlined style={{ color: 'blue', fontSize: 35 }} /></div>
                <Input placeholder='جستجو با نام نام خانوادگی کد ملی عنوان شغلی و شماره عضویت' onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        setFilter({ ...filter, skip: 0, limit: 10 })
                        setReload(true)
                    }

                }} onChange={(event) => setFilter({ ...filter, search: event.target.value })} style={{ marginTop: 5 }} size="large" />
            </div>


            <Table
                size='small'
                style={{
                    textAlign: 'right',
                    marginTop: 10
                }}
                rowClassName={() => 'center'}
                columns={columns}
                bordered={true}
                dataSource={data}
                loading={loading}
                pagination={false}
                expandable={{
                    expandedRowRender: (record) => <div style={{ textAlign: 'right' }}>
                        <div>آخرین ورود: {record.lastLoginDate}</div>
                    </div>
                    //rowExpandable: (record) => record.name !== 'Not Expandable',
                }}
                locale={{
                    emptyText: 'موردی یافت نشد',
                }}
            />
            <Pagination
                onChange={(pageNumber, limit) => {
                    setFilter({ ...filter, skip: pageNumber > 0 ? pageNumber - 1 : pageNumber, limit: limit })
                    setReload(true)
                }}
                style={{ marginTop: 30, marginBottom: 5 }}
                current={filter.skip + 1}
                //current={10}
                responsive={true}
                defaultPageSize={filter.limit}
                locale={{ items_per_page: '' }}
                showTotal={(total) => `تعداد کل: ${total} عضو`}
                showSizeChanger={true}
                total={count} />
        </div>
    )
}

export default Total
