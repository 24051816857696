import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react'
import {
    CChartBar,
    CChartDoughnut,
    CChartLine,
    CChartPie,
    CChartPolarArea,
    CChartRadar,
} from '@coreui/react-chartjs'
import { DocsCallout } from 'src/components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox, Pagination, Table, Collapse, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { apiUrls } from '../../apiUrls.js';
import Program from "../../components/Program.js";
import { useNavigate } from 'react-router-dom';
import { UnlockOutlined, LockOutlined, EditOutlined, SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import AmountFormat from "../../components/AmountFormat.js";
import InputNumberFormat from "../../components/InputNumberFormat.js";
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import { UploadOutlined } from '@ant-design/icons';
import jquery from 'jquery';
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";

const { Option } = Select;

const baseURL = apiUrls.baseURL;

const ProgramSettingList = () => {

    const [data, setData] = useState([]);
    const [programSettingModel, setProgramSettingModel] = useState({});
    const [loading, setLoading] = useState(false);
    const [payLoading, setPayLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [count, setCount] = useState(0);
    const [dropDownWorkingGroup, setDropDownWorkingGroup] = useState([]);
    const [filter, setFilter] = useState({ skip: 0, limit: 20, data: {} });
    const [dropDownProgramSettingType, setDropDownProgramSettingTypes] = useState([]);
    const [isShowCreateProgramSettingModalVisible, setIsShowCreateProgramSettingModalVisible] = useState(false);
    const permissions = useSelector((state) => state.permissions.permissions);
    const navigate = useNavigate();
    const [reload, setReload] = useState(false);

    const columns = [
        {
            title: 'ردیف',
            dataIndex: 'index',
            key: 'index',
            responsive: ['xl', 'lg'],
            width: 50,
            render: (_, record, index) => (
                <>{index + 1 + filter?.limit * filter?.skip}</>
            ),
        },
        {
            title: 'کارگروه',
            key: 'workingGroupCategoryTitle',
            dataIndex: 'workingGroupCategoryTitle',
            width: 100,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs']
        },
        {
            title: 'تاریخ ایجاد',
            key: 'persianCreateDate',
            dataIndex: 'persianCreateDate',
            width: 100,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs']
        },
        {
            title: 'مبلغ به ریال(روزانه)/درصد(از کل هزینه)',
            key: 'salaryAmount',
            dataIndex: 'salaryAmount',
            width: 10,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                <AmountFormat amount={record.salaryValue}></AmountFormat>
            ),
        },
        {
            title: 'نوع پرداخت دستمزد',
            key: 'salaryPolicyLeaderTypeTitle',
            dataIndex: 'salaryPolicyLeaderTypeTitle',
            width: 100,
            responsive: ['xl', 'lg', 'md'],
        }
    ];

    useEffect(() => {
        setLoading(true)
        fetchProgramSettingType()
        fetchWorkingGroup();
        fetchData()
    }, [reload])

    const fetchWorkingGroup = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/workingGroupCategory/getDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })

            var result = response.data.data;
            setDropDownWorkingGroup(result);
        } catch (error) {
        } finally {
        }
    };

    const fetchData = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.post(`${baseURL}/programsetting/get`, filter, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;
            result.items.map(item => {
                item.key = item.id
            })
            setCount(result.count)
            setData(result.items);
            setLoading(false);
            setReload(false)
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
            setReload(false)
        } finally {
            setLoading(false);
            setReload(false)
        }
    };

    const fetchProgramSettingType = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/programsetting/GetSalaryPolicyLeaderTypeDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })

            var result = response.data.data;
            setDropDownProgramSettingTypes(result);
        } catch (error) {
        } finally {
        }
    };

    const createProgramSetting = async () => {
        setLoadingSave(true);
        const token = localStorage.getItem('damavandclub-token')
        try {
            await axios.post(`${baseURL}/programSetting/create`, programSettingModel, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            fetchData()
            setLoadingSave(false);
            setIsShowCreateProgramSettingModalVisible(false)
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } finally {
            setLoadingSave(false);
        }
    };

    const showCreateProgramSettingModal = async () => {
        setProgramSettingModel({})
        setIsShowCreateProgramSettingModalVisible(true)
    };

    return (
        <div>
            <HideSidebarInMobileMode />
            {permissions.includes('createbill') ? <Button onClick={() => showCreateProgramSettingModal()} style={{ backgroundColor: 'rgb(44 0 151)', marginTop: 10, width: '100%', marginBottom: 10 }}
                type="primary"><label>ایجاد تعرفه جدید</label></Button> : null}

            <Table
                size='small'
                style={{
                    textAlign: 'right',
                    marginTop: 10
                }}
                rowClassName={'center'}
                columns={columns}
                bordered={true}
                dataSource={data}
                loading={loading}
                pagination={false}
                expandable={{
                    expandedRowRender: (record) => <div style={{ textAlign: 'right' }}>
                        <div>توضیحات: {record.description}</div>
                        <div>ایجاد کننده: {record.creatorName}</div>
                    </div>
                }}
                locale={{
                    emptyText: 'موردی یافت نشد',
                }}
            />
            <Pagination
                onChange={(pageNumber, limit) => {
                    setFilter({ ...filter, skip: pageNumber > 0 ? pageNumber - 1 : pageNumber, limit: limit })
                    setReload(true)
                }}
                style={{ marginTop: 30, marginBottom: 5 }}
                current={filter.skip + 1}
                //current={10}
                responsive={true}
                defaultPageSize={filter.limit}
                locale={{ items_per_page: '' }}
                showTotal={(total) => `تعداد کل: ${total} تعرفه`}
                showSizeChanger={true}
                total={count} />

            <Modal
                title={`ایجاد تعرفه`}
                open={isShowCreateProgramSettingModalVisible}
                width={1000}
                closable={false}
                okButtonProps={null}
                footer={<div className='row-container'>
                    <Button onClick={() => {
                        setProgramSettingModel({})
                        setIsShowCreateProgramSettingModalVisible(false)
                    }} style={{ width: '100%' }}>بستن</Button>
                    <Button type="primary" loading={loadingSave}
                        onClick={() => createProgramSetting()}
                        style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(10, 23, 43)' }}>ذخیره</Button>
                </div>}
            >
                <Select
                    style={{ width: '100%' }}
                    placeholder="انتخاب گارگروه"
                    onChange={(value) => setProgramSettingModel({ ...data, workingGroupCategoryId: value })}
                    value={data.workingGroupCategoryId}
                >
                    {dropDownWorkingGroup?.map((item) => (
                        <Option key={item.id} value={item.id}>
                            {item.displayName}
                        </Option>
                    ))}
                </Select>

                <Select
                    style={{ width: '100%', marginTop: 20 }}
                    placeholder="انتخاب نوع تعرفه پرداخت"
                    onChange={(value) => setProgramSettingModel({ ...programSettingModel, salaryPolicyLeaderType: value })}
                    value={programSettingModel.type}
                >
                    {dropDownProgramSettingType?.map((item) => (
                        <Option key={item.id} value={item.id}>
                            {item.displayName}
                        </Option>
                    ))}
                </Select>

                {programSettingModel.salaryPolicyLeaderType == 1 && <div className='margintop-18'>
                    <InputNumberFormat value={programSettingModel.SalaryValue} placeholder="مبلغ(ریال)-با اعداد انگلیسی"
                        onValueChange={(value) => setProgramSettingModel({ ...programSettingModel, salaryValue: value })} />
                </div>}

                {programSettingModel.salaryPolicyLeaderType == 2 && <div className='margintop-18'>
                    <InputNumberFormat value={programSettingModel.SalaryValue} placeholder="مقدار درصد از کل هزینه برنامه"
                        onValueChange={(value) => setProgramSettingModel({ ...programSettingModel, salaryValue: value })} />
                </div>}

                <input
                    placeholder='توضیحات'
                    value={programSettingModel.description}
                    onChange={(event) => setProgramSettingModel({ ...programSettingModel, description: event.target.value })}
                    style={{ marginTop: 20, height: 80, width: '100%' }} />
            </Modal>
        </div>
    )
}

export default ProgramSettingList
