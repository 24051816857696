
import { toast } from 'react-toastify';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox, Pagination, Table, Collapse, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { apiUrls } from '../../apiUrls.js';
import { useNavigate } from 'react-router-dom';
import AmountFormat from "../../components/AmountFormat.js";
import InputNumberFormat from "../../components/InputNumberFormat.js";
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";

const { Option } = Select;

const baseURL = apiUrls.baseURL;

const MyWallet = () => {

    const [data, setData] = useState([]);
    const [WalletModel, setWalletModel] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [id, setId] = useState(0);
    const [count, setCount] = useState(0);
    const [filter, setFilter] = useState({ skip: 0, limit: 20, data: {} });
    const [accountBalance, setAccountBalance] = useState('');
    const [isShowCreateWalletModalVisible, setIsShowCreateWalletModalVisible] = useState(false);
    const permissions = useSelector((state) => state.permissions.permissions);
    const [reload, setReload] = useState(false);
    const navigate = useNavigate();

    const columns = [
        {
            title: 'ردیف',
            dataIndex: 'index',
            key: 'index',
            responsive: ['xl', 'lg'],
            width: 50,
            render: (_, record, index) => (
                <>{index + 1 + filter?.limit * filter?.skip}</>
            ),
        },
        {
            title: 'مبلغ(ریال)',
            key: 'amount',
            dataIndex: 'amount',
            width: 10,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                <AmountFormat amount={record.amount}></AmountFormat>
            ),
        },
        {
            title: 'نوع تراکنش',
            key: 'walletTypeTitle',
            dataIndex: 'walletTypeTitle',
            width: 100,
            responsive: ['xl', 'lg', 'md'],
        }
    ];

    useEffect(() => {
        setLoading(true)
        fetchData()
    }, [reload])

    const fetchData = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.post(`${baseURL}/wallet/GetMyTransactions`, filter, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;
            result.items.map(item => {
                item.key = item.id
            })
            setCount(result.count)
            setData(result.items);
            fetchAccountBalance();
            setReload(false);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setReload(false)
            setLoading(false);
        } finally {
            setReload(false)
            //setLoading(false);
        }
    };

    const fetchAccountBalance = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/GetAccountBalance`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;
            setAccountBalance(result)
            setLoading(false);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
        } finally {
            setLoading(false);
        }
    };

    const createWallet = async () => {
        setLoadingSave(true);
        const token = localStorage.getItem('damavandclub-token')
        try {

            var response = await axios.get(`${baseURL}/payment/IncreaseAccountBalance?amount=${WalletModel.amount}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            // toast.success('در حال انتقال به درگاه...', {
            //     position: 'bottom-right',
            //     autoClose: 1000, // milliseconds
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     //className: 'custom-toast',
            //     pauseOnHover: true,
            //     draggable: true,
            // });
            var result = response.data.data;

            const newWindow = window.open('', '_blank');

            if (newWindow) {
                newWindow.location.href = result;
            } else {
                // در صورتی که پیش باز کردن پنجره مسدود شده باشد
                window.location.href = result;
            }

            fetchData()
            setLoadingSave(false);
            setIsShowCreateWalletModalVisible(false)
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } finally {
            setLoadingSave(false);
        }
    };

    const showCreateWalletModal = async () => {
        setWalletModel({})
        setIsShowCreateWalletModalVisible(true)
    };

    return (
        <div>
            <HideSidebarInMobileMode />
            {!loading ? <Button onClick={() => showCreateWalletModal()} style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'rgb(44 0 151)', marginTop: 10, width: '100%', marginBottom: 10 }}
                type="primary"><label>افزایش موجودی کیف پول</label><span>موجودی: <AmountFormat amount={accountBalance} /> ریال</span></Button> : null}
            <div style={{ textAlign: 'center' }}>لیست تراکنش ها</div>
            <Table
                size='small'
                style={{
                    textAlign: 'right',
                    marginTop: 10
                }}
                rowClassName={(record) => record.increaseMode ? 'greenrow' : 'redrow'}
                columns={columns}
                bordered={true}
                dataSource={data}
                loading={loading}
                pagination={false}
                expandable={{
                    expandedRowRender: (record) => <div style={{ textAlign: 'right' }}>
                        <div>توضیحات: {record.description}</div>
                        <div>ایجاد کننده: {record.creatorName}</div>
                        <div>تاریخ ثبت: {record.persianCreateDate}</div>
                    </div>
                }}
                locale={{
                    emptyText: 'موردی یافت نشد',
                }}
            />
            <Pagination
                onChange={(pageNumber, limit) => {
                    setFilter({ ...filter, skip: pageNumber > 0 ? pageNumber - 1 : pageNumber, limit: limit })
                    setReload(true)
                }}
                style={{ marginTop: 30, marginBottom: 5 }}
                current={filter.skip + 1}
                //current={10}
                responsive={true}
                defaultPageSize={filter.limit}
                locale={{ items_per_page: '' }}
                showTotal={(total) => `تعداد کل: ${total}`}
                showSizeChanger={true}
                total={count} />
            <Modal
                title={`افزایش موجودی کیف پول`}
                open={isShowCreateWalletModalVisible}
                closable={false}
                okButtonProps={null}
                footer={<div className='row-container'>
                    <Button onClick={() => {
                        setWalletModel({})
                        setIsShowCreateWalletModalVisible(false)
                    }} style={{ width: '100%' }}>بستن</Button>
                    <Button type="primary" loading={loadingSave}
                        onClick={() => createWallet()}
                        style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(10, 23, 43)' }}>اتصال به درگاه بانک</Button>
                </div>}
            >
                <div style={{ textAlign: 'justify' }}><span className='red'>توجه</span>: شما کیف پول خود را برای شرکت در برنامه ها و کلاس های باشگاه شارژ میکنید و در صورت انصراف از حضور در برنامه یا کلاس ها، هزینه با کسر تعرفه انصراف، به کیف پول شما بازخواهد گشت.
                    در صورت تمایل به برداشت کیف پول میتوانید به واحد مالی آقای کاشف به شماره 09123113502 در واستاپ یا تلگرام پیام دهید تا نسبت به واریز موجودی کیف پول به حساب بانکی شما اقدام گردد.
                    <div><span className='red'>در هنگام اتصال به درگاه بانک لطفا فیلترشکن خود را خاموش کنید</span></div></div>

                <InputNumberFormat value={WalletModel.amount} placeholder="مبلغ(ریال)-با اعداد انگلیسی"
                    onValueChange={(value) => setWalletModel({ ...WalletModel, amount: value })} />
            </Modal>
        </div>
    )
}

export default MyWallet
