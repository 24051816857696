import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { FilePdfOutlined, PictureOutlined } from '@ant-design/icons';
import { apiUrls } from '../apiUrls.js';

const PrivateImage = ({ mediaId }) => {

  const [base64String, setBase64String] = useState('');
  const [isPdf, setIsPdf] = useState(false);
  const [loading, setLoading] = useState(false);
  const baseURL = apiUrls.baseURL;

  useEffect(() => {
    if (mediaId != null)
      getData()
    else
      setBase64String('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaId])

  const openFile = async () =>{
    setLoading(true)
    const token = localStorage.getItem('damavandclub-token')
    const response = await axios.get(`${baseURL}/media/image?id=${mediaId}&size=4`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      'responseType': 'blob'
    });
    const reader = new FileReader();
    reader.readAsDataURL(response.data);
    reader.onloadend = () => {
      const fileURL = URL.createObjectURL(response.data);
      window.open(fileURL, '_blank');
      setLoading(false)
    };
  } 

  const getData = async () => {
    setLoading(true)
    const token = localStorage.getItem('damavandclub-token')
    const response = await axios.get(`${baseURL}/media/image?id=${mediaId}&size=1`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      'responseType': 'blob'

    });
    if (response.data.type === 'application/pdf') {
      setIsPdf(true);
    } else {
      setIsPdf(false)
    }
    const reader = new FileReader();
    reader.readAsDataURL(response.data);
    reader.onloadend = () => {
      const base64Data = reader.result;
      setBase64String(base64Data);
      setLoading(false)
    };
  };

  if (loading)
    return <div style={{
      justifyContent: 'center',
      alignItems: 'center',
      width: 180,
      height: 180,
      display: 'flex'
    }}><Spin size="large" /></div>

  if (base64String == '')
    return <div style={{
      justifyContent: 'center',
      alignItems: 'center',
      width: 180,
      height: 180,
      display: 'flex',
      backgroundColor: 'lightgray'
    }}><PictureOutlined style={{ fontSize: 50 }} twoToneColor="#eb2f96" /></div>

  if (isPdf)
    return <div style={{
      justifyContent: 'center',
      alignItems: 'center',
      width: 180,
      height: 180,
      display: 'flex',
    }}> <FilePdfOutlined onClick={() => openFile()} style={{ fontSize: '80px', color: 'red' }} /></div>

  return <div style={{
    justifyContent: 'center',
    alignItems: 'center',
    width: 180,
    height: 180,
    display: 'flex'
  }}> <img
    onClick={() => openFile()}
    width={180}
    height={180}
    src={base64String}
  >
    </img></div>
};


PrivateImage.propTypes = {
  mediaId: PropTypes.number
};

export default PrivateImage;
