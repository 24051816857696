import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react'
import {
    CChartBar,
    CChartDoughnut,
    CChartLine,
    CChartPie,
    CChartPolarArea,
    CChartRadar,
} from '@coreui/react-chartjs'
import { DocsCallout } from 'src/components'
import { toast } from 'react-toastify';
import { Button, Flex, Input, Tabs, Upload, Select, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import jquery from 'jquery';
import { useParams } from 'react-router-dom';
import { apiUrls } from '../../apiUrls.js';
import { useNavigate } from 'react-router-dom';
import MemberSpecification from "../../components/MemberSpecification.js";
import MemberCertificate from "../../components/MemberCertificate.js";
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";
const { TabPane } = Tabs;
const { Option } = Select;

const TrialDetail = () => {

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const baseURL = apiUrls.baseURL;
    const navigate = useNavigate();
    const { id } = useParams();
    const permissions = useSelector((state) => state.permissions.permissions);

    useEffect(() => {
        fetchData()
    }, [])

    function callback(key) {
  
    }

    const fetchData = async () => {
        setLoading(true);
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getTrialMemberDetail?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            var result = response.data.data;
            result.id = id;
            setData(result);
            setLoading(false);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Spin size="large" />
    }

    return (
        <div className='row'>
             <HideSidebarInMobileMode/>
            <div style={{
                backgroundColor: 'white',
                flexDirection: 'column',
                display: 'flex',
                flex: 1,
                height: '100%',
                padding: 10
            }}>
                <Tabs defaultActiveKey="1" onChange={callback}>
                    <TabPane tab="مشخصات" key="1">

                        <MemberSpecification data={data} setData={setData}></MemberSpecification>

                    </TabPane>

                    <TabPane tab="مدارک" key="2">
                        <MemberCertificate data={data} setData={setData}></MemberCertificate>
                    </TabPane>

                </Tabs>
            </div>
        </div >
    )
}

export default TrialDetail
