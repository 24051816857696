import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react'
import {
    CChartBar,
    CChartDoughnut,
    CChartLine,
    CChartPie,
    CChartPolarArea,
    CChartRadar,
} from '@coreui/react-chartjs'
import { DocsCallout } from 'src/components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox, Pagination, Table, Collapse, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { apiUrls } from '../../apiUrls.js';
import Program from "../../components/Program.js";
import { useNavigate } from 'react-router-dom';
import { UnlockOutlined, LockOutlined, EditOutlined, SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import AmountFormat from "../../components/AmountFormat.js";
import InputNumberFormat from "../../components/InputNumberFormat.js";
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import { UploadOutlined } from '@ant-design/icons';
import jquery from 'jquery';
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";

const { Option } = Select;

const baseURL = apiUrls.baseURL;

const MembersWallet = () => {

    const [data, setData] = useState([]);
    const [WalletModel, setWalletModel] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [id, setId] = useState(0);
    const [count, setCount] = useState(0);
    const [filter, setFilter] = useState({ skip: 0, limit: 20, data: {} });
    const [dropDownWalletType, setDropDownWalletTypes] = useState([]);
    const [reload, setReload] = useState(false);
    const [dropDownUser, setDropDownUsers] = useState([]);
    const [isShowCreateWalletModalVisible, setIsShowCreateWalletModalVisible] = useState(false);
    const permissions = useSelector((state) => state.permissions.permissions);
    const navigate = useNavigate();
    const [loadingFinancialReport, setLoadingFinancialReport] = useState(false);

    const columns = [
        {
            title: 'ردیف',
            dataIndex: 'index',
            key: 'index',
            responsive: ['xl', 'lg'],
            width: 50,
            render: (_, record, index) => (
                <>{index + 1 + filter?.limit * filter?.skip}</>
            ),
        },
        {
            title: 'نام عضو',
            key: 'userFullName',
            dataIndex: 'userFullName',
            width: 100,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs']
        },
        {
            title: 'مبلغ(ریال)',
            key: 'amount',
            dataIndex: 'amount',
            width: 10,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                <AmountFormat amount={record.amount}></AmountFormat>
            ),
        },
        {
            title: 'مانده حساب(ریال)',
            key: 'accountBalance',
            dataIndex: 'accountBalance',
            width: 10,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                <AmountFormat amount={record.accountBalance}></AmountFormat>
            ),
        },
        {
            title: 'نوع تراکنش',
            key: 'walletTypeTitle',
            dataIndex: 'walletTypeTitle',
            width: 100,
            responsive: ['xl', 'lg', 'md'],
        }
    ];

    useEffect(() => {
        setLoading(true)
        fetchWalletType()
        fetchUsers()
        fetchData()
    }, [reload])

    const fetchData = async () => {

        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.post(`${baseURL}/wallet/get`, filter, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;
            result.items.map(item => {
                item.key = item.id
            })
            setCount(result.count)
            setData(result.items);
            setLoading(false);
            setReload(false)
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
            setReload(false)
        } finally {
            setLoading(false);
            setReload(false)
        }
    };

    const fetchUsers = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })

            var result = response.data.data;
            setDropDownUsers(result);
        } catch (error) {
        } finally {
        }
    };

    const fetchWalletType = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/wallet/getTypeDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })

            var result = response.data.data;
            setDropDownWalletTypes(result);
        } catch (error) {
        } finally {
        }
    };

    const createWallet = async () => {
        setLoadingSave(true);
        const token = localStorage.getItem('damavandclub-token')
        try {
            await axios.post(`${baseURL}/Wallet/create`, WalletModel, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            toast.success('ذخیره شد', {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
            fetchData()
            setLoadingSave(false);
            setIsShowCreateWalletModalVisible(false)
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } finally {
            setLoadingSave(false);
        }
    };

    const getFinancialReport = async () => {

        setLoadingFinancialReport(true)
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getFinancialReport`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }, 'responseType': 'blob'
            });
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');

            setLoadingFinancialReport(false);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoadingFinancialReport(false);
        } finally {
            setLoadingFinancialReport(false);
        }
    };

    const showCreateWalletModal = async () => {
        setWalletModel({})
        setIsShowCreateWalletModalVisible(true)
    };

    return (
        <div>
            {permissions.includes('createwallet') && <Button onClick={() => getFinancialReport()} loading={loadingFinancialReport}
                style={{ backgroundColor: 'rgb(229 120 27)', marginTop: 10, width: '100%' }}
                type="primary"><label>گزارش مالی</label></Button>}

            {permissions.includes('createwallet') ? <Button onClick={() => showCreateWalletModal()} style={{ backgroundColor: 'rgb(44 0 151)', marginTop: 10, width: '100%', marginBottom: 10 }}
                type="primary"><label>ایجاد کیف پول</label></Button> : null}

            <HideSidebarInMobileMode />
            <Collapse defaultActiveKey={['0']}>
                <Collapse.Panel header='جستجو ' key='1'>
                    <Select
                        style={{ width: '100%', marginTop: 10 }}
                        placeholder="انتخاب عضو"
                        allowClear={true}
                        showSearch
                        optionFilterProp="label"
                        onChange={(value) => {
                            setFilter({ ...filter, data: { ...filter.data, userId: value } })
                        }}
                    //value={WalletModel.userId}
                    >
                        {dropDownUser?.map((item) => (
                            <Option key={item.id} label={item.displayName} value={item.id}>
                                {item.displayName}
                            </Option>
                        ))}
                    </Select>
                    <Button onClick={() => setReload(true)} loading={loading} style={{ backgroundColor: 'rgb(0 39 151)', marginTop: 10, width: '100%', marginBottom: 10 }}
                        type="primary"><label>جستجو</label></Button>
                    {/* <Button onClick={() => getExcel()} loading={loadingExcel} style={{ backgroundColor: 'rgb(229 131 27)', marginTop: 10, width: '100%', marginBottom: 10 }}
                        type="primary"><label>خروجی اکسل</label></Button> */}
                </Collapse.Panel>
            </Collapse>


            <Table
                size='small'
                style={{
                    textAlign: 'right',
                    marginTop: 10
                }}
                rowClassName={(record) => record.increaseMode ? 'greenrow' : 'redrow'}
                columns={columns}
                bordered={true}
                dataSource={data}
                loading={loading}
                pagination={false}
                expandable={{
                    expandedRowRender: (record) => <div style={{ textAlign: 'right' }}>
                        <div>توضیحات: {record.description}</div>
                        <div>ایجاد کننده: {record.creatorName}</div>
                        <div>تاریخ ثبت: {record.persianCreateDate}</div>
                    </div>
                }}
                locale={{
                    emptyText: 'موردی یافت نشد',
                }}
            />
            <Pagination
                onChange={(pageNumber, limit) => {
                    setFilter({ ...filter, skip: pageNumber > 0 ? pageNumber - 1 : pageNumber, limit: limit })
                    setReload(true)
                }}
                style={{ marginTop: 30, marginBottom: 5 }}
                current={filter.skip + 1}
                //current={10}
                responsive={true}
                defaultPageSize={filter.limit}
                locale={{ items_per_page: '' }}
                showTotal={(total) => `تعداد کل: ${total} کیف پول`}
                showSizeChanger={true}
                total={count} />
            <Modal
                title={`ایجاد کیف پول`}
                open={isShowCreateWalletModalVisible}
                width={1000}
                closable={false}
                okButtonProps={null}
                footer={<div className='row-container'>
                    <Button onClick={() => {
                        setWalletModel({})
                        setIsShowCreateWalletModalVisible(false)
                    }} style={{ width: '100%' }}>بستن</Button>
                    <Button type="primary" loading={loadingSave}
                        onClick={() => createWallet()}
                        style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(10, 23, 43)' }}>ذخیره</Button>
                </div>}
            >
                <InputNumberFormat value={WalletModel.amount} placeholder="مبلغ(ریال)-با اعداد انگلیسی"
                    onValueChange={(value) => setWalletModel({ ...WalletModel, amount: value })} />
                <Select
                    style={{ width: '100%', marginTop: 10 }}
                    placeholder="انتخاب نوع تراکنش"
                    onChange={(value) => setWalletModel({ ...WalletModel, walletType: value })}
                    value={WalletModel.walletType}
                >
                    {dropDownWalletType?.map((item) => (
                        <Option key={item.id} value={item.id}>
                            {item.displayName}
                        </Option>
                    ))}
                </Select>
                <Select
                    style={{ width: '100%', marginTop: 10 }}
                    placeholder="انتخاب عضو"
                    showSearch
                    optionFilterProp="label"
                    onChange={(value) => setWalletModel({ ...WalletModel, userId: value })}
                    value={WalletModel.userId}
                >
                    {dropDownUser?.map((item) => (
                        <Option key={item.id} label={item.displayName} value={item.id}>
                            {item.displayName}
                        </Option>
                    ))}
                </Select>
                <input
                    placeholder='توضیحات'
                    value={WalletModel.description}
                    onChange={(event) => setWalletModel({ ...WalletModel, description: event.target.value })}
                    style={{ marginTop: 10, height: 30, width: '100%' }} />
            </Modal>
        </div>
    )
}

export default MembersWallet
