import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Table, Pagination, Modal } from 'antd';
import { apiUrls } from '../apiUrls.js';
import { toast } from 'react-toastify';
import AmountFormat from "../components/AmountFormat.js";

const { Option } = Select;
const baseURL = apiUrls.baseURL;

const ProgramVideos = React.memo(({ data }) => {

    const [isShowModalVisible, setIsShowModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [photos, setPhotos] = useState([]);
    const [videos, setDataVideo] = useState([]);

    useEffect(() => {

    }, [])


    const getVideo = async () => {
        setIsShowModalVisible(true);
        try {
            const token = localStorage.getItem('damavandclub-token')
            let response = await axios.get(`${apiUrls.baseURL}/program/getvideos?id=${data.id}&key=program`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setDataVideo(response.data.data)
        } catch (error) {
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setIsShowModalVisible(false);
        } finally {
        }
    };

    return (
        <>
            <Button size='small' onClick={() => getVideo()}
                style={{ backgroundColor: 'rgb(219 26 78 / 85%)' }}
                type="primary"><label>ویدئو</label></Button>
            <Modal
                title={`ویدئو برنامه:  ${data.nameFa}`}
                open={isShowModalVisible}
                closable={false}
                okButtonProps={null}
                footer={
                    <Button onClick={() => setIsShowModalVisible(false)} style={{ width: '100%' }}>بستن</Button>
                }
                onCancel={() => setIsShowModalVisible(false)}
            >
                {videos.map((item, index) => {
                    return <div key={index}>
                        <a rel="noreferrer" target='_blank' href={item.url}><video width={300} height={300} src={item.url}></video></a>
                    </div>
                })}
            </Modal>
        </>
    );
});

ProgramVideos.propTypes = {
    data: PropTypes.any
};

ProgramVideos.displayName = 'ProgramVideos';

export default ProgramVideos;
