import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react'
import {
  CChartBar,
  CChartDoughnut,
  CChartLine,
  CChartPie,
  CChartPolarArea,
  CChartRadar,
} from '@coreui/react-chartjs'
import { DocsCallout } from 'src/components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Flex, Input, Upload, Select, Spin, Checkbox } from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import jquery from 'jquery';
import InputNumberFormat from "../../components/InputNumberFormat.js";
import BraftEditor from 'braft-editor';
import { FilePdfOutlined } from '@ant-design/icons';
import { apiUrls } from '../../apiUrls.js';
import { useNavigate } from 'react-router-dom';
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const baseURL = apiUrls.baseURL;
const { TabPane } = Tabs;
const { Option } = Select;

const CertificateForm = () => {

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [deadlineDate, setDeadLineDate] = useState(null);
  const [dropDownDiscountTypes, setDropDownDiscountType] = useState([]);
  const [dropDownWorkingGroup, setDropDownWorkingGroup] = useState([]);
  const [dropDownRequirements, setDropDownRequirements] = useState([]);
  const [dropDownLevel, setDropDownLevel] = useState([]);
  const [dropDownType, setDropDownType] = useState([]);
  const [dropDownLeader, setDropDownLeaders] = useState([]);
  const permissions = useSelector((state) => state.permissions.permissions);
  const accessShowInSite = permissions.includes('createblog');
  const [renderedTabs, setRenderedTabs] = useState([0]);
  const navigate = useNavigate();
  //eslint-disable-next-line react-hooks/rules-of-hooks  
  const { id } = useParams();

  useEffect(() => {
    if (id !== undefined)
      fetchData(id)
    fetchRequirements();
  }, [])

  const fetchData = async (id) => {
    setLoading(true);
    const token = localStorage.getItem('damavandclub-token')
    try {
      const response = await axios.get(`${baseURL}/certificate/getbyid?id=${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });

      var result = response.data.data;

      setData(result);
      setLoading(false);
    } catch (error) {
      if (error.response.status == 401)
        navigate('/');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const fetchRequirements = async () => {
    const token = localStorage.getItem('damavandclub-token')
    try {
      const response = await axios.get(`${baseURL}/program/getRequirementsDropdownItems`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      })

      var result = response.data.data;
      setDropDownRequirements(result);
    } catch (error) {
    } finally {
    }
  };







  const onSelect = useCallback((index) => {
    setRenderedTabs((renderedTabs) =>
      renderedTabs.includes(index)
        ? renderedTabs
        : renderedTabs.concat(index)
    )
  }, []);

  const save = async () => {




    setLoadingSave(true);
    try {
      const token = localStorage.getItem('damavandclub-token')

      if (id !== undefined) {
        await axios.post(`${baseURL}/certificate/update`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        })
      } else {
        await axios.post(`${baseURL}/certificate/create`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        })
      }

      toast.success("تغییرات با موفقیت ذخیره شد", {
        position: 'bottom-right',
        autoClose: 3000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setLoadingSave(false);
    } catch (error) {
      toast.error(error.response.data.Msg, {
        position: 'bottom-right',
        autoClose: 6000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        className: 'custom-toast',
        pauseOnHover: true,
        draggable: true,
      });
      setLoadingSave(false);
    } finally {
      setLoadingSave(false);
    }
  }

  if (loading) {
    return <Spin size="large" />
  }

  return (
    <div className='row'>
      <HideSidebarInMobileMode />
      <div style={{
        backgroundColor: 'white',
        flexDirection: 'column',
        display: 'flex',
        flex: 1,
        height: '100%',
        padding: 10
      }}>
        {id !== undefined ? <Button onClick={() => save()} loading={loadingSave} style={{ backgroundColor: 'rgb(0 151 46)', marginTop: 10, width: '100%', marginBottom: 10 }}
          type="primary"><label>ویرایش مدرک</label></Button> : <Button onClick={() => save()} loading={loadingSave} style={{ backgroundColor: 'rgb(0 151 46)', marginTop: 10, width: '100%', marginBottom: 10 }}
            type="primary"><label>ثبت مدرک</label></Button>}


        <Tabs onSelect={onSelect}>
          <TabList>
            <Tab>فارسی</Tab>
            <Tab>انگلیسی</Tab>
          </TabList>

          <TabPanel forceRender={renderedTabs.includes(0)}>
            <label>عنوان</label>
            <Input value={data.nameFa} onChange={(event) => setData({ ...data, nameFa: event.target.value })} style={{ marginTop: 5 }} size="large" />







            <label className='margintop-18'>انتخاب پیش نیاز ها(اختیاری)</label>
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              showSearch
              placeholder="انتخاب پیش نیازها"
              optionFilterProp="label"
              onChange={(value) => setData({ ...data, requirementIds: value })}
              value={data.requirementIds}
            >
              {dropDownRequirements?.map((item) => (
                <Option key={item.id} label={item.displayName} value={item.id}>
                  {item.displayName}
                </Option>
              ))}
            </Select>



            <label className='margintop-18'>توضیحات</label>
            <textarea value={data.descriptionFa} onChange={(event) => setData({ ...data, descriptionFa: event.target.value })}
              style={{
                marginTop: 5,
                resize: 'none',
                width: '100%',
                minHeight: 300
              }} />

           <Checkbox checked={data.isActive} onChange={() => setData({ ...data, isActive: !data.isActive })}>فعال</Checkbox>
            <br />
          </TabPanel>

          <TabPanel forceRender={renderedTabs.includes(1)}>
            <label>عنوان</label>
            <Input value={data.nameEn} onChange={(event) => setData({ ...data, nameEn: event.target.value })} style={{ marginTop: 5 }} size="large" />
            <label className='margintop-18'>توضیحات</label>
            <textarea value={data.descriptionEn} onChange={(event) => setData({ ...data, descriptionEn: event.target.value })}
              style={{
                marginTop: 5,
                resize: 'none',
                width: '100%',
                minHeight: 400
              }} />
          </TabPanel>





        </Tabs>

      </div>
    </div >
  )
}

export default CertificateForm
