import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { FilePdfOutlined, PictureOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Tabs, Upload, Select, Spin } from 'antd';
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import { apiUrls } from '../apiUrls.js';
import PrivateImage from "../components/PrivateImage.js";
import jquery from 'jquery';
import { UploadOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

const { Option } = Select;

const MemberCertificate = ({ data, setData, isShowButton, isHideUploadButton }) => {

    const [certificates, setCertificates] = useState([]);
    const [dropDownCertificates, setDropDownCertificates] = useState([]);
    const [certificateId, setCertificateId] = useState('');
    const [certificateDate, setCertificateDate] = useState(null);

    const baseURL = apiUrls.baseURL;

    const generateKey = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0;
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    };

    const fetchCertificates = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getCertificateDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })

            var result = response.data.data;
            setDropDownCertificates(result);
        } catch (error) {
        } finally {
        }
    }

    const certificatePhotoProps = {
        name: 'file',
        action: `${baseURL}/media/UploadFiles?containerId=0&userId=${data.id}&isPublic=false`, // Replace with your upload endpoint
        headers: {
            authorization: 'Bearer ' + localStorage.getItem('damavandclub-token'),
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                //console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                let mediaResponse = info.file.response.data[0];
                updateCertificatePhoto(mediaResponse.id);
                jquery(".ant-upload-list-text-container").remove();
            } else if (info.file.status === 'error') {
                return toast.warning(info.file.response.Msg, {
                    position: 'bottom-right',
                    autoClose: 3000, // milliseconds
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  });
            }
        },
    };

    const updateCertificatePhoto = (mediaId) => {
        const updatedCertificates = certificates.map(item => {
            if (item.certificateId === certificateId)
                return { ...item, mediaId: mediaId }
            return item;
        })
        setData({ ...data, certificates: updatedCertificates.sort((a, b) => a.createDate > b.createDate ? 1 : -1) })
        setCertificates(updatedCertificates.sort((a, b) => a.createDate > b.createDate ? 1 : -1));
    };

    const setCertificateIdOnNewCertificate = (key, newCertificateId) => {
        const updatedCertificates = certificates.map(item => {
            if (item.key === key)
                return { ...item, certificateId: newCertificateId }
            return item;
        })
        setData({ ...data, certificates: updatedCertificates.sort((a, b) => a.createDate > b.createDate ? 1 : -1) })
        setCertificates(updatedCertificates.sort((a, b) => a.createDate > b.createDate ? 1 : -1));
    }

    const removeCertificate = (certificateId, key) => {
        if (certificateId !== null)
            var updatedCertificates = certificates.filter(item => item.certificateId != certificateId);
        else
            var updatedCertificates = certificates.filter(item => item.key != key);
        setCertificates(updatedCertificates.sort((a, b) => a.createDate > b.createDate ? 1 : -1));
        setData({ ...data, certificates: updatedCertificates.sort((a, b) => a.createDate > b.createDate ? 1 : -1) })
    };

    const setCertificateDateOnNewCertificate = (key, date) => {
        const updatedCertificates = certificates.map(item => {
            if (item.key === key)
                return {
                    ...item, certificateDate: { year: date.year, month: date.month, day: date.day },
                    persianCertificateDate: `${date.year}/${date.month}/${date.day}`
                }
            return item;
        })
        setData({ ...data, certificates: updatedCertificates.sort((a, b) => a.createDate > b.createDate ? 1 : -1) })
        // setCertificateDate({
        //     year: date.year,
        //     month: date.month,
        //     day: date.day
        // });
        setCertificates(updatedCertificates.sort((a, b) => a.createDate > b.createDate ? 1 : -1));
    }

    const popperStyles = {
        zIndex: 1000
    };

    const addCertificate = () => {
        const updateItems = [...certificates.sort((a, b) => a.createDate > b.createDate ? 1 : -1), { createDate: new Date(), persianCertificateDate: undefined, displayName: undefined, mediaId: null, key: generateKey() }]
        //setCertificates(prevItems => [{ displayName: undefined, mediaId: null, key: generateKey() }].concat(prevItems));
        setCertificates(updateItems.sort((a, b) => a.createDate > b.createDate ? 1 : -1));
    }

    useEffect(() => {
        if (data.certificates !== undefined && data.certificates !== null)
            setCertificates(data.certificates)
    }, [])

    return (
        <>
            <Button onClick={() => {
                fetchCertificates()
                addCertificate()
            }} style={{ backgroundColor: 'rgb(0 20 46)', marginTop: 10, width: '100%', marginBottom: 10 }}
                type="primary"><label>افزودن مدرک یا دوره</label></Button>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap'
            }}>
                {certificates?.map((item, index) => {
                    return item.displayName !== undefined ? <div style={{ margin: 5 }} className='center' key={index}>{item.displayName}
                        <br />
                        تاریخ مدرک: {item.persianCertificateDate}
                        <PrivateImage mediaId={item.mediaId} />
                        {isShowButton ? <div style={{ marginTop: 5, display: 'flex' }}>
                            <Button onClick={() => removeCertificate(item.certificateId, null)} type='danger'>حذف</Button>
                            <Upload
                                {...certificatePhotoProps}
                            >
                                <Button icon={<UploadOutlined />} onClick={() => setCertificateId(item.certificateId)} style={{ backgroundColor: 'rgb(45 51 231)', marginRight: 1, marginBottom: 10 }}
                                    type="primary" ><label>انتخاب تصویر</label></Button>
                            </Upload>
                        </div> : <></>}
                    </div> :
                        <div style={{ margin: 5 }} className='center' key={index}>
                            <Select
                                showSearch
                                style={{ width: '100%', marginBottom: 5 }}
                                placeholder="انتخاب مدرک"
                                optionFilterProp="label"
                                notFoundContent="لطفا کمی صبر کنید..."
                                onChange={(value) => {
                                    setCertificateIdOnNewCertificate(item.key, value)
                                }}
                            >
                                {dropDownCertificates?.map((item) => (
                                    <Option key={item.id} label={item.displayName} value={item.id}>
                                        {item.displayName}
                                    </Option>
                                ))}
                            </Select>
                            <br />
                            <div className='margintop-5 marginbottom-5 center-content customDatePickerWidth'>
                                <DatePicker
                                    value={item.certificateDate || null}
                                    onChange={(value) => setCertificateDateOnNewCertificate(item.key, value)}
                                    shouldHighlightWeekends
                                    locale='fa'
                                    inputPlaceholder='انتخاب تاریخ'
                                    popperProps={{
                                        style: popperStyles,
                                    }}
                                    wrapperClassName='custom-calendar-style'
                                />
                            </div>
                            {!isHideUploadButton ? <PrivateImage mediaId={item.mediaId} /> : <></>}

                            {!isHideUploadButton ? <div style={{ marginTop: 5, display: 'flex' }}>
                                <Button onClick={() => removeCertificate(null, item.key)} type='danger'>حذف</Button>
                                <Upload
                                    {...certificatePhotoProps}
                                >
                                    <Button icon={<UploadOutlined />} onClick={() => setCertificateId(item.certificateId)} style={{ backgroundColor: 'rgb(45 51 231)', marginRight: 1, marginBottom: 10 }}
                                        type="primary" ><label>انتخاب تصویر</label></Button>
                                </Upload>
                            </div> : <></>}

                        </div>
                })}
            </div>
        </>
    );
};


MemberCertificate.propTypes = {
    data: PropTypes.any,
    setData: PropTypes.any,
    isShowButton: PropTypes.bool,
    isHideUploadButton: PropTypes.bool
};

export default MemberCertificate;
