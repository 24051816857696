import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { FilePdfOutlined, PictureOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Table, Pagination } from 'antd';
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import { apiUrls } from '../apiUrls.js';
import jquery from 'jquery';
import { UploadOutlined } from '@ant-design/icons';

const { Option } = Select;

const MemberJoinedProgram = React.memo(({ data }) => {

    data.map(item => {
        item.key = item.id
    })

    const columns = [
        {
            title: 'ردیف',
            dataIndex: 'index',
            key: 'index',
            responsive: ['xl', 'lg'],
            width: 50,
            render: (_, record, index) => (
                <>{index + 1}</>
            ),
        },
        {
            title: 'برنامه/تاریخ',
            key: 'programName',
            dataIndex: 'programName',
            width: 100,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                <div>{record.programName} <br /> {record.programDate}</div>
            ),
        },
        {
            title: 'سرپرست',
            key: 'leaderName',
            dataIndex: 'leaderName',
            width: 10,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs']
        },
        {
            title: 'کارگروه',
            key: 'workingGroupCategoryName',
            dataIndex: 'workingGroupCategoryName',
            width: 10,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs']
        },
        {
            title: 'نظر فرد',
            key: 'memberComment',
            dataIndex: 'memberComment',
            width: 10,
            responsive: ['xl', 'lg', 'md']
        },
        {
            title: 'نظر سرپرست',
            key: 'leaderComment',
            dataIndex: 'leaderComment',
            width: 10,
            responsive: ['xl', 'lg', 'md']
        }
    ];
    return (
        <>
            <Table
                size='small'
                style={{
                    textAlign: 'right',
                    marginTop: 10
                }}
                rowClassName={() => 'center'}
                columns={columns}
                bordered={true}
                dataSource={data}
                pagination={false}
                locale={{
                    emptyText: 'موردی یافت نشد',
                }}
                expandable={{
                    expandedRowRender: (record) => <div style={{ textAlign: 'right' }}>
                        <div>نظر فرد: {record.memberComment}</div>
                        <div>نظر سرپرست: {record.leaderComment}</div>
                    </div>
                    //rowExpandable: (record) => record.name !== 'Not Expandable',
                }}
            />
            تعداد کل: {data.length}
        </>
    );
});

MemberJoinedProgram.propTypes = {
    data: PropTypes.any,
};

MemberJoinedProgram.displayName = 'MemberJoinedProgram';

export default MemberJoinedProgram;
