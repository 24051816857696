import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Table, Pagination, Modal } from 'antd';
import { apiUrls } from '../apiUrls.js';
import { toast } from 'react-toastify';
import AmountFormat from "../components/AmountFormat.js";

const { Option } = Select;
const baseURL = apiUrls.baseURL;

const ProgramSetComment = React.memo(({ data }) => {

    const [isShowModalVisible, setIsShowModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [photos, setPhotos] = useState([]);
    const [memberComment, setMemberComment] = useState({});
    const [dropDownFeedback, setDropDownFeedback] = useState([]);
    const [loadingSaveComment, setLoadingSaveComment] = useState(false);

    useEffect(() => {

    }, [])

    const saveComment = async () => {
        setLoadingSaveComment(true);
        try {
            const token = localStorage.getItem('damavandclub-token')
            await axios.post(`${apiUrls.baseURL}/program/setMemberComment?id=${data.id}`, memberComment, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            toast.success("با موفقیت ثبت شد", {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setIsShowModalVisible(false);
            setLoadingSaveComment(false);
        } catch (error) {
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSaveComment(false);
        } finally {
            setLoadingSaveComment(false);
        }
    };


    const getComment = async () => {
        setIsShowModalVisible(true);
        try {
            const token = localStorage.getItem('damavandclub-token')
            let dropDownFeedback = await axios.get(`${apiUrls.baseURL}/program/getMemberFeedbackDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setDropDownFeedback(dropDownFeedback.data.data)
            let response = await axios.get(`${apiUrls.baseURL}/program/getMemberComment?id=${data.id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setMemberComment(response.data.data)
        } catch (error) {
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } finally {
        }
    };

    return (
        <>
            <Button size='small' onClick={() => getComment()} style={{ backgroundColor: 'rgb(197 197 77)', marginTop: 10, width: '100%', marginRight: 10 }}
                type="primary">ثبت نظر</Button>

            <Modal
                title={`ثبت نظر در مورد برنامه:  ${data.nameFa}`}
                open={isShowModalVisible}
                closable={false}
                okButtonProps={null}
                footer={<div className='row-container'>
                    <Button onClick={() => setIsShowModalVisible(false)} style={{ width: '100%' }}>بستن</Button>
                    <Button type="primary" loading={loadingSaveComment}
                        onClick={() => saveComment()}
                        style={{ width: '100%', marginRight: 10, backgroundColor: 'rgb(10, 23, 43)' }}>ذخیره</Button>
                </div>}
            >
                <Select
                    style={{ width: '100%' }}
                    placeholder="ثبت امتیاز"
                    onChange={(value) => setMemberComment({ ...memberComment, memberFeedback: value })}
                    value={memberComment.memberFeedback}
                >
                    {dropDownFeedback?.map((item) => (
                        <Option key={item.id} value={item.id}>
                            {item.displayName}
                        </Option>
                    ))}
                </Select>
                <textarea
                    placeholder='نظر خود را در مورد برنامه بنویسید'
                    value={memberComment.comment}
                    onChange={(event) => setMemberComment({ ...memberComment, comment: event.target.value })}
                    style={{ marginTop: 10, height: 200, width: '100%' }} />
            </Modal>
        </>
    );
});

ProgramSetComment.propTypes = {
    data: PropTypes.any
};

ProgramSetComment.displayName = 'ProgramSetComment';

export default ProgramSetComment;
