import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react'
import {
    CChartBar,
    CChartDoughnut,
    CChartLine,
    CChartPie,
    CChartPolarArea,
    CChartRadar,
} from '@coreui/react-chartjs'
import { DocsCallout } from 'src/components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox, Pagination, Table, Collapse, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { apiUrls } from '../../apiUrls.js';
import Program from "../../components/Program.js";
import { useNavigate } from 'react-router-dom';
import { UnlockOutlined, LockOutlined, EditOutlined, SearchOutlined, LoadingOutlined, DollarOutlined, WalletFilled } from '@ant-design/icons';
import AmountFormat from "../../components/AmountFormat.js";
import InputNumberFormat from "../../components/InputNumberFormat.js";
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import { UploadOutlined } from '@ant-design/icons';
import jquery from 'jquery';
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";

const { Option } = Select;

const baseURL = apiUrls.baseURL;

const MyBills = () => {

    const [data, setData] = useState([]);
    const [billModel, setBillModel] = useState({});
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState(0);
    const [count, setCount] = useState(0);
    const [filter, setFilter] = useState({ skip: 0, limit: 20, data: {} });
    const navigate = useNavigate();
    const [reload, setReload] = useState(false);

    const columns = [
        {
            title: 'ردیف',
            dataIndex: 'index',
            key: 'index',
            responsive: ['xl', 'lg'],
            width: 50,
            render: (_, record, index) => (
                <>{index + 1 + filter?.limit * filter?.skip}</>
            ),
        },
        {
            title: 'مبلغ(ریال)',
            key: 'amount',
            dataIndex: 'amount',
            width: 10,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                <AmountFormat amount={record.amount}></AmountFormat>
            ),
        },
        {
            title: 'نوع قبض',
            key: 'typeTitle',
            dataIndex: 'typeTitle',
            width: 100,
            responsive: ['xl', 'lg', 'md'],
        },
        {
            title: 'پرداخت',
            key: 'id',
            dataIndex: 'id',
            width: 30,
            responsive: ['xl', 'lg', 'md', 'sm', 'xs'],
            render: (text, record) => (
                !record.isPaid ? record.loadingPayment ? <LoadingOutlined style={{ color: 'green', fontSize: 30 }} /> : <DollarOutlined onClick={() => {
                    const updateItem = data.map(item => {
                        if (item.id === record.id)
                            return { ...item, loadingPayment: true }
                        return item;
                    })
                    setData(updateItem);
                    pay(record.id, record.amount)
                }} style={{ color: 'green', fontSize: 30 }} /> : "پرداخت شده"
            ),
        },
    ];

    useEffect(() => {
        setLoading(true)
        fetchData()
    }, [reload])

    const fetchData = async () => {

        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.post(`${baseURL}/bill/getmybills`, filter, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;
            result.items.map(item => {
                item.key = item.id
            })
            setCount(result.count)
            setData(result.items);
            setLoading(false);
            setReload(false)
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
            setReload(false)
        } finally {
            setLoading(false);
            setReload(false)
        }
    };

    const pay = async (id, amount) => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            var response = await axios.post(`${baseURL}/payment/initilize`, { billId: id, amount: amount }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;

            const newWindow = window.open('', '_blank');

            if (newWindow) {
                newWindow.location.href = result;
            } else {
                // در صورتی که پیش باز کردن پنجره مسدود شده باشد
                window.location.href = result;
            }

        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
            fetchData();
        } finally {
            fetchData();
        }
    };

    return (
        <div>
            <HideSidebarInMobileMode />
            <Table
                size='small'
                style={{
                    textAlign: 'right',
                    marginTop: 10
                }}
                rowClassName={(record) => record.isPaid ? 'greenrow' : 'redrow'}
                columns={columns}
                bordered={true}
                dataSource={data}
                loading={loading}
                pagination={false}
                expandable={{
                    expandedRowRender: (record) => <div style={{ textAlign: 'right' }}>
                        <div>نوع قبض: {record.typeTitle}</div>
                        <div>ایجاد کننده: {record.creatorName}</div>
                        {/* <div>پرداخت کننده: {record.creatorName}</div> */}
                        <div>مهلت پرداخت: {record.persianPayDateDeadLine}</div>
                        <div>تاریخ پرداخت: {record.persianPayDate}</div>
                        <div>توضیحات: {record.description}</div>
                        <div>تصویر : {record.photoUrl !== null ?
                            <a href={record.photoUrl} rel="noreferrer" target='_blank'><img width={150} height={150} src={record.photoUrl} /></a> :
                            'تصویر قبض قرار داده نشده است'}</div>
                    </div>
                    //rowExpandable: (record) => record.name !== 'Not Expandable',
                }}
                locale={{
                    emptyText: 'موردی یافت نشد',
                }}
            />
            <Pagination
                onChange={(pageNumber, limit) => {
                    setFilter({ ...filter, skip: pageNumber > 0 ? pageNumber - 1 : pageNumber, limit: limit })
                    setReload(true)
                }}
                style={{ marginTop: 30, marginBottom: 5 }}
                current={filter.skip + 1}
                //current={10}
                responsive={true}
                defaultPageSize={filter.limit}
                locale={{ items_per_page: '' }}
                showTotal={(total) => `تعداد کل: ${total} قبض`}
                showSizeChanger={true}
                total={count} />
        </div>
    )
}

export default MyBills
