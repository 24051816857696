import { toast } from 'react-toastify';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox, Pagination, Table, Collapse, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { apiUrls } from '../../apiUrls.js';
import { useNavigate } from 'react-router-dom';
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";
import AmountFormat from "../../components/AmountFormat.js";
import { UnlockOutlined, LockOutlined, EditOutlined, SearchOutlined, DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;

const baseURL = apiUrls.baseURL;

const RentalList = () => {

    const [data, setData] = useState([]);
    const [StoreModel, setStoreModel] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [count, setCount] = useState(0);
    const [filter, setFilter] = useState({ skip: 0, limit: 400, data: {} });
    const [dropDownUserType, setDropDownUserTypes] = useState([]);
    const [dropDownUser, setDropDownUsers] = useState([]);
    const [isShowCreateUserStoreModalVisible, setIsShowCreateUserStoreModalVisible] = useState(false);
    const [isShowCreateGroupStoreModalVisible, setIsShowCreateGroupStoreModalVisible] = useState(false);
    const permissions = useSelector((state) => state.permissions.permissions);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        fetchData()
    }, [])

    const fetchData = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.post(`${baseURL}/Store/getrental`, filter, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;
            result.items.map(item => {
                item.key = item.id
            })
            setCount(result.count)
            setData(result.items);
            debugger
            setLoading(false);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const addItem = async (id) => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/store/additem?id=`, id, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;
            result.items.map(item => {
                item.key = item.id
            })
            setCount(result.count)
            setData(result.items);
            debugger
            setLoading(false);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };


    if (loading) {
        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 300
        }}><Spin size="large" /> <HideSidebarInMobileMode /></div>
    }
    return (
        <div className='row'>
            <HideSidebarInMobileMode />
            {data?.map((item, index) => {

                return <div className='col-md-2 col-sm-12 container-box' key={index} style={{
                    //backgroundColor: color
                }}>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        {item.nameFa}
                    </div>

                    <div className="horizontal-line-program"></div>

                    <img style={{ objectFit: 'cover', width: '100%', height: 'auto', padding: 5 }} src={item.photoUrl}></img>

                    <div className='row-container'>
                        <button className="red square-button decrement">-</button>
                        <button onClick={() => addItem(item.id)} className="green square-button increment">+</button>
                    </div>

                </div>
            })}
        </div>
    )
}

export default RentalList
