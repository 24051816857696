import { combineReducers } from 'redux';
import permissionsReducer from './permissionsReducer';
import sidebarReducer from './sidebarReducer';

const rootReducer = combineReducers({
  permissions: permissionsReducer,
  sidebar: sidebarReducer
  // other reducers...
});

export default rootReducer;